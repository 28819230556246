// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../../_metronic/_partials/controls";
import { SUBCONTRACTOR } from "../../../../../../_utils/userTypes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../PropertiesManagement/_redux/projects/projectsActions";
import * as budgetActions from "../../../../../Budget/_redux/budgetsActions";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {
  canEdit,
  canReadAllUserDocument,
  isAdmin,
  isOwner,
} from "../../../../../../_utils/authUtils";
import { USER_FILE_TYPES } from "../../../../../../_utils/listUtils";
import { isBudgetServiceAvailable } from "../../../../../../_utils/configUtils";
import { Checkbox } from "../../../../../../_components/Checkbox";
import { isFinanceFile } from "../../../../../../../data/schemas";
import { FileDropzone } from "../../../../../../_components/FileDropzone";
import { Notification } from "../../../../../../_components/Notification";
import { LinkedFiles } from "../../../../../../_components/LinkedFiles";
import { formatDisplayName } from "../../../../../../_utils/userUtils";
import { UserFileFinanceCategories } from "./finance/UserFileFinanceCategories";
import { useUserFilesUIContext } from "../UserFilesUIContext";

export function UserFileEditForm({
  saveFile,
  saveMultipleFiles,
  file,
  actionsLoading,
  onHide,
  isUploadingFile,
  readOnly,
  userTypes,
}) {
  const intl = useIntl();
  const { financeFileDefaultCategory } = useUserFilesUIContext();

  // Validation schema
  const FileEditSchema = Yup.object().shape({
    friendlyName: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_SYMBOLS" })} 2`)
      .max(200, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_SYMBOLS" })} 200`)
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.FILE.NAME.REQUIRED" })),
    fileType: Yup.string().required(intl.formatMessage({ id: "AUTH.VALIDATION.TYPE.REQUIRED" })),
    userType: Yup.string(),
    projectId: Yup.string(),
    categories: Yup.array().of(
      Yup.object().shape({
        budgetLineCategoryId: Yup.string(),
        budgetLineCategoryLabel: Yup.string(),
        lines: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            budgetLineId: Yup.string(),
            budgetLineLabel: Yup.string(),
            vat: Yup.number(),
            amount: Yup.number(),
          })
        ),
      })
    ),
    visible: Yup.bool(),
    notifyUser: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
      })
    ),
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(file?.fileType ?? null);
  const [rectoVerso, setRectoVerso] = useState(false);
  const [project, setProject] = useState();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const isBudgetAvailable = React.useMemo(() => isBudgetServiceAvailable(), []);

  const { projectsListLoading, projects, budget, session, groups, entityForEdit, user } =
    useSelector(
      (state) => ({
        projectsListLoading: state.projects.listLoading,
        projects: state.projects.entities,
        budgetLoading: state.budgets.actionsLoading,
        budget: state.budgets?.budgetForEdit?.saved,
        session: state.auth.session,
        groups: state.auth.groups,
        entityForEdit: state.users.entityForEdit.current
          ? state.users.entityForEdit.current
          : state?.users?.entities?.find((entity) => entity?.id === file.userId),
        user: state.users.entityForEdit?.saved,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (
      session &&
      groups &&
      !project &&
      canEdit(groups, session, "SUBCONTRACTOR") &&
      formRef?.current?.values?.userType === SUBCONTRACTOR
    ) {
      dispatch(actions.fetchProjects()).then((projects) => {
        if (file.projectId) {
          setProject(projects.find((project) => project.id === file.projectId));
        } else {
          setProject(projects[0]);
        }
      });
    }
  }, [session, groups, formRef?.current?.values?.userType]);

  useEffect(() => {
    if (isBudgetAvailable && isFinanceFile(formRef.current.values.fileType)) {
      dispatch(budgetActions.fetchBudget(project?.selectedBudget));
    }
  }, [project]);

  const [fileTypes, setFileTypes] = useState({});

  useEffect(() => {
    setFileTypes(USER_FILE_TYPES[formRef?.current?.values?.userType]);
  }, [formRef?.current?.values?.userType]);

  const onProjectChange = (e, handleChange, setFieldValue) => {
    handleChange(e);
    if (project && e.target.value !== project.id) {
      setFieldValue("categories", [financeFileDefaultCategory()]);
    }
    setProject(projects.find((project) => project.id === e.target.value));
  };

  const onUploadAsChange = (e, handleChange) => {
    handleChange(e);
    setFileTypes(USER_FILE_TYPES[e.target.value]);
  };

  const onFileTypeChange = (e, handleChange, setFieldValue, values) => {
    setSelectedFileType(e.target.value !== "-" ? e.target.value : null);
    handleChange(e);
    if (
      isFinanceFile(e.target.value) &&
      values.userType === SUBCONTRACTOR &&
      canEdit(groups, session, "USER")
    ) {
      if (!values.categories) {
        setFieldValue("categories", [financeFileDefaultCategory()]);
      }
      if (!values.projectId) {
        setFieldValue("projectId", projects[0]?.id);
        setProject(projects[0]);
        if (isBudgetAvailable) {
          dispatch(budgetActions.fetchBudget(projects[0]?.selectedBudget));
        }
      } else {
        if (isBudgetAvailable) {
          dispatch(budgetActions.fetchBudget(project?.selectedBudget));
        }
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={file}
      validationSchema={FileEditSchema}
      onSubmit={(values) => {
        values = { ...values, notifyUser: values?.notifyUser?.map((option) => option.value) ?? [] };
        const filteredValues = {
          ...values,
          categories: values.categories?.map(({ chosen, selected, ...category }) => ({
            ...category,
            lines: category.lines.map(({ chosen, selected, ...line }) => line),
          })),
          relatedEntity: { name: formatDisplayName(user, false) },
        };

        // TODO: merge these files in one PDF
        if (selectedFileType === "ID" && !rectoVerso && !!selectedFile && !!selectedFile2) {
          saveMultipleFiles(
            {
              ...filteredValues,
              friendlyName: "[ID] Recto",
              file: selectedFile,
            },
            {
              ...filteredValues,
              friendlyName: "[ID] Verso",
              file: selectedFile2,
            }
          );
        } else {
          saveFile({
            ...filteredValues,
            file: selectedFile,
          });
        }
      }}
      innerRef={formRef}
    >
      {({ handleSubmit, setFieldValue, values, handleChange, handleBlur }) => (
        <>
          <Modal.Body className="overlay">
            <Form className="form form-label-right" data-cy="form-user-file">
              <div className="form-group row">
                <div className="col-lg-12">
                  <Field
                    data-cy="input-file-name"
                    name="friendlyName"
                    component={Input}
                    placeholder={intl.formatMessage({
                      id: "COMMON.NAME",
                    })}
                    label={intl.formatMessage({
                      id: "COMMON.NAME",
                    })}
                    disabled={
                      readOnly ||
                      (!isAdmin(groups, session) && file?.id
                        ? !isOwner({ id: file.userId }, file)
                        : false)
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                {userTypes.length > 1 && ( // In case we are in profile
                  <div className="col-sm-6">
                    <Select
                      name="userType"
                      data-cy="select-file-upload-as"
                      label={intl.formatMessage({
                        id: "FILE.UPLOAD_AS",
                      })}
                      onChange={(e) => onUploadAsChange(e, handleChange)}
                    >
                      {userTypes.map((value) => (
                        <option key={value} value={value}>
                          {intl.formatMessage({ id: `${value}.TITLE.SINGLE` })}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}
                <div className={userTypes.length > 1 ? "col-sm-6" : "col-12"}>
                  <Select
                    data-cy="select-file-type"
                    name="fileType"
                    label={intl.formatMessage({
                      id: "COMMON.TYPE",
                    })}
                    disabled={
                      file.fileType === "FILLOUT_AML_KYC_FORM" ||
                      readOnly ||
                      (!isAdmin(groups, session) && file?.id
                        ? !isOwner({ id: file.userId }, file)
                        : false)
                    }
                    onChange={(e) => onFileTypeChange(e, handleChange, setFieldValue, values)}
                  >
                    {file.fileType === "FILLOUT_AML_KYC_FORM" ? (
                      <option>
                        {intl.formatMessage({
                          id: USER_FILE_TYPES.CLIENT.FILLOUT_AML_KYC_FORM,
                        })}
                      </option>
                    ) : (
                      fileTypes &&
                      Object.keys(fileTypes)
                        .filter((fileType) => fileType !== "FILLOUT_AML_KYC_FORM")
                        .map((value) => (
                          <option key={value} value={value}>
                            {intl.formatMessage({ id: fileTypes[value] })}
                          </option>
                        ))
                    )}
                  </Select>
                </div>
              </div>
              {canEdit(groups, session, "USER") && // User Access check
                (isUploadingFile || file.id) && // Check if not request document dialog
                values.userType === SUBCONTRACTOR && ( // Check that user is a subcontractor
                  <>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        {projectsListLoading && (
                          <Spinner
                            animation="border"
                            variant="primary"
                            className="position-absolute"
                            style={{ top: "30px", left: "20px" }}
                          />
                        )}
                        <Select
                          data-cy="subcontractor-file-project-form"
                          name="projectId"
                          label="Project"
                          onChange={(e) => onProjectChange(e, handleChange, setFieldValue)}
                          value={values.projectId}
                        >
                          {!isFinanceFile(values.fileType) && <option value={"NONE"}>-</option>}
                          {projects.map((project, index) => (
                            <option key={index} value={project.id}>
                              {project.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    {project && isFinanceFile(values.fileType) && (
                      <>
                        {project.selectedBudget ? (
                          <label>
                            <FormattedMessage id="BUDGET.ACTION.CHOOSE.CATEGORY_OR_LINE" />
                          </label>
                        ) : (
                          isBudgetAvailable && (
                            <label className="text-warning">
                              <FormattedMessage id="BUDGET.NOT.IN.PROJECT" />{" "}
                              <Link to={`/budgets/new?projectId=${project.id}`}>
                                <FormattedMessage id="BUDGET.ACTION.CREATE.BUDGET" />
                              </Link>{" "}
                              <FormattedMessage id="BUDGET.ACTION.OR.FILL.OUT.INFORMATION" />
                            </label>
                          )
                        )}
                        <UserFileFinanceCategories {...{ budget, isBudgetAvailable }} />
                      </>
                    )}
                  </>
                )}
              {isUploadingFile && (selectedFileType || file.status === "REQUESTED") && (
                <div className="form-group row">
                  <div className="col">
                    {actionsLoading && !!selectedFile ? (
                      <div className="upload-file-container upload-file-container--disabled">
                        <div className="d-flex justify-content-center align-middle">
                          <div className="spinner-grow text-primary mr-4" />
                          <div>
                            <FormattedMessage id="FILE.ACTION.UPLOADING" />
                          </div>
                        </div>
                      </div>
                    ) : selectedFileType === "ID" ? (
                      <>
                        <div className="row">
                          <div className="col">
                            <Checkbox
                              name={"rectoVerso"}
                              label={"FILE.FRONT.BACK"}
                              onChange={() => setRectoVerso(!rectoVerso)}
                              checked={rectoVerso}
                              data-cy="checkbox-recto-verso"
                            />
                          </div>
                        </div>
                        {selectedFileType && rectoVerso ? (
                          <FileDropzone
                            values={values}
                            propsSetFieldValue={setFieldValue}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                          />
                        ) : (
                          <div className="row d-flex justify-content-around">
                            <div className="col-md-6 mb-5 mb-md-0">
                              <FileDropzone
                                values={values}
                                propsSetFieldValue={setFieldValue}
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                                textField="FILE.FRONT"
                              />
                            </div>
                            <div className="col-md-6 mb-5 mb-md-0">
                              <FileDropzone
                                values={values}
                                propsSetFieldValue={setFieldValue}
                                selectedFile={selectedFile2}
                                setSelectedFile={setSelectedFile2}
                                textField="FILE.BACK"
                                inputName="file-back"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <FileDropzone
                        values={values}
                        propsSetFieldValue={setFieldValue}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                      />
                    )}
                  </div>
                </div>
              )}
              {!isUploadingFile &&
                !readOnly &&
                !file.id &&
                file.fileType !== "FILLOUT_AML_KYC_FORM" && (
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Notification
                        notifyMethods={values.notifyUser}
                        setNotifyMethods={(options) =>
                          handleChange({
                            target: {
                              name: "notifyUser",
                              value: options,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              {canEdit(groups, session, "USER") &&
                canEdit(groups, session, "BUDGET") &&
                isFinanceFile(values.fileType) && (
                  <Checkbox
                    name={"selectedForFinance"}
                    label={"FILE.ACTION.SELECT_FOR_FINANCE_ANALYSIS"}
                    customClasses={"mb-2"}
                  />
                )}
              {canReadAllUserDocument(groups, session, entityForEdit) && (
                <Checkbox name={"isConfidential"} label={"COMMON.CONFIDENTIAL.DOCUMENT"} />
              )}
              <div className="separator separator-solid separator-border-2 my-4"></div>
              <LinkedFiles activeFile={file} />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              disabled={actionsLoading}
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              <FormattedMessage id="COMMON.ACTION.CANCEL" />
            </button>
            <button
              disabled={
                (isUploadingFile &&
                  (!selectedFile || (values.fileType === "ID" && !rectoVerso && !selectedFile2))) ||
                actionsLoading
              }
              type="submit"
              onClick={handleSubmit}
              className="btn btn-primary btn-elevate"
              data-cy="button-submit"
            >
              {!isUploadingFile && !readOnly && !file.id ? (
                <FormattedMessage id="COMMON.ACTION.REQUEST" />
              ) : (
                <FormattedMessage id="COMMON.ACTION.SAVE" />
              )}
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
}
