// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import * as React from "react";
import { Field, Form, Formik, FormikHandlers, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";
import { shallowEqual } from "react-redux";
import { cloneDeep, isEmpty } from "lodash-es";
import { useQueryState } from "react-router-use-location-state";
import { BudgetEditExpenses } from "./BudgetEditExpenses";
import { EntityEditInstalments } from "../../_components/EntityEditInstalments";
import { FormattedMessage, useIntl } from "react-intl";
import * as projectActions from "../PropertiesManagement/_redux/projects/projectsActions";
import { getProjectById } from "../PropertiesManagement/_redux/projects/projectsCrud";
import { getBudgetById } from "./_redux/budgetsCrud";
import { updateBudgetLocally } from "./_redux/budgetsActions";
import { v4 as uuid } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CustomSelect } from "../../_components/CustomSelect";
import { IBudget, SubcontractorFinanceType } from "../../../data/schemas";
import { OnChangeValue } from "react-select";
import { BudgetInstalmentTable } from "./components/BudgetInstalmentTable";

export interface IBudgetEditCopy {
  projectId: string;
  budgetId: string;
}
export interface BudgetEditFormProps {
  budget: IBudget;
  saveBudgetFields: (key: string | string[], value: any) => void;
  disabled: boolean;
  onSubmitRef: (callback: () => void) => void;
  submitBudget: () => void;
}
export const BudgetEditForm = ({
  budget,
  saveBudgetFields,
  disabled,
  onSubmitRef,
  submitBudget,
}: BudgetEditFormProps) => {
  const intl = useIntl();
  // Validation schema
  const BudgetEditSchema = Yup.object().shape({
    projectId: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(250, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 250`),
    projectName: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(250, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 250`),
    name: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.BUDGET.REQUIRED" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(250, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 250`),
  });

  const [tab, setTab] = useQueryState("t", "expenses");

  const { project, projects } = useAppSelector(
    (state) => ({
      project: state.projects.projectForEdit.current,
      projects: state.projects.entities,
    }),
    shallowEqual
  );

  const dispatch = useAppDispatch();

  const formRef = React.useRef<FormikProps<IBudget>>(null);

  React.useEffect(() => {
    if (onSubmitRef) {
      onSubmitRef(() => formRef.current?.submitForm());
    }
  }, []);

  const [projectsLoading, setProjectsLoading] = React.useState(false);

  const customHandleChange = (e: any) => (handleChange: FormikHandlers["handleChange"]) => {
    if (!!saveBudgetFields) {
      handleChange(e);
      saveBudgetFields(e.target.name, e.target.value);
    }
  };

  const handleProjectChange =
    (e: OnChangeValue<any, any>) =>
    (
      handleChange: FormikHandlers["handleChange"],
      setFieldValue: FormikHelpers<IBudget>["setFieldValue"]
    ) => {
      if (!!saveBudgetFields) {
        handleChange(e);
        const project = projects.find((project) => project.id === e.target.value);
        const projectName = project?.name || "";
        setFieldValue("projectName", projectName);
        saveBudgetFields([e.target.name, "projectName"], [e.target.value, projectName]);
      }
    };

  const [budgetsForCopy, setBudgetsForCopy] = React.useState<IBudget[]>([]);
  const [budgetsListLoading, setBudgetsListLoading] = React.useState<boolean>();
  const selectProjectForCopy = (
    e: any,
    handleChange: FormikHandlers["handleChange"],
    setFieldValue: FormikHelpers<IBudget>["setFieldValue"]
  ) => {
    handleChange(e);
    if (e.target.value) {
      setBudgetsListLoading(true);
      getProjectById(e.target.value).then((project) => {
        setBudgetsForCopy(project.budgets);
        setBudgetsListLoading(false);
        setFieldValue("budgetId", project.budgets?.[0]?.id);
      });
    } else {
      setFieldValue("budgetId", "");
    }
  };

  const [copyLoading, setCopyLoading] = React.useState(false);
  const copyBudget = (values: IBudgetEditCopy) => {
    setCopyLoading(true);
    getBudgetById(values.budgetId).then((b: IBudget) => {
      const { id, createdAt, updatedAt, ...newBudget } = cloneDeep(b);
      newBudget.name = `${newBudget.name} - ${intl.formatMessage({
        id: "COMMON.COPY",
      })}`;
      newBudget.projectId = budget.projectId;
      newBudget.projectName = budget.projectName;
      newBudget.budgetInstalments = newBudget.budgetInstalments?.map(
        ({ budgetId, createdAt, updatedAt, ...rest }) => ({ ...rest, id: uuid() })
      );
      newBudget.sortedLines = newBudget.sortedLines.map(
        ({ budgetId, createdAt, updatedAt, lines, ...rest }) => ({
          ...rest,
          id: uuid(),
          lines: lines.map(({ budgetId, categoryId, createdAt, updatedAt, ...rest }) => ({
            ...rest,
            id: uuid(),
          })),
        })
      );
      dispatch(updateBudgetLocally(newBudget));
      setCopyLoading(false);
    });
  };

  const openProjectSelect = () => {
    if (!projects?.length && !projectsLoading) {
      setProjectsLoading(true);
      dispatch(projectActions.fetchProjects(intl)).then(() => setProjectsLoading(false));
    }
  };

  const projectHasFinanceFiles = React.useMemo(
    () =>
      Object.values(SubcontractorFinanceType).some(
        (sffType) => !isEmpty(project?.subcontractorsFinanceFiles?.[sffType]?.data)
      ),
    [project]
  );

  const budgetsForCopyOptions = React.useMemo(
    () =>
      budgetsForCopy.map((budget) => ({
        label: budget.name,
        value: budget.id!,
      })),
    [budgetsForCopy]
  );

  return (
    <>
      {!budget.id && (
        <>
          <div>
            <div className={"font-size-lg font-weight-bold mb-3"}>
              <FormattedMessage id="BUDGET.CREATE_FROM_EXISTING" />
            </div>
            <Formik
              initialValues={{ projectId: "", budgetId: "" }}
              onSubmit={(values) => copyBudget(values)}
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form>
                  <div className={"form-row"}>
                    <div className={"col-3"}>
                      <label>
                        <FormattedMessage id={"BUDGET.ACTION.SELECT_PROJECT"} />
                      </label>
                      <CustomSelect
                        name={"projectId"}
                        onFocus={openProjectSelect}
                        onChange={(selected) =>
                          selectProjectForCopy(
                            {
                              target: { name: "projectId", value: selected?.value },
                            },
                            handleChange,
                            setFieldValue
                          )
                        }
                        selected={
                          values.projectId
                            ? {
                                label: projects?.find((p) => p.id === values.projectId)?.name ?? "",
                                value: values.projectId,
                              }
                            : null
                        }
                        isLoading={projectsLoading}
                        loadingMessage={() => intl.formatMessage({ id: "COMMON.LOADING" })}
                        getOptionValue={(o: any) => o.value.id}
                        options={projects?.map((project) => ({
                          label: project.name!,
                          value: project.id!,
                        }))}
                        styles={{ control: { width: "auto" } }}
                      />
                    </div>
                    <div className={"col-3"}>
                      <label>
                        <FormattedMessage id={"BUDGET.ACTION.SELECT_BUDGET"} />
                      </label>
                      <CustomSelect
                        name={"budgetId"}
                        onChange={(selected) => {
                          handleChange({
                            target: { name: "budgetId", value: selected?.value },
                          });
                        }}
                        selected={
                          values.budgetId
                            ? {
                                label:
                                  budgetsForCopy?.find((b) => b.id === values.budgetId)?.name ?? "",
                                value: values.budgetId,
                              }
                            : null
                        }
                        isLoading={budgetsListLoading}
                        loadingMessage={() => intl.formatMessage({ id: "COMMON.LOADING" })}
                        // getOptionValue={(o) => o.value ?? ""}
                        options={budgetsForCopyOptions}
                        styles={{ control: { width: "auto" } }}
                      />
                    </div>
                    <div className={"col-6 d-flex align-items-end"}>
                      <button
                        type="submit"
                        className={`btn btn-primary ${copyLoading && "spinner spinner-right"}`}
                        disabled={!values.budgetId || copyLoading}
                      >
                        <FormattedMessage id="BUDGET.ACTION.COPY" />
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <hr className={"my-8"} />
        </>
      )}
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={budget}
        validationSchema={BudgetEditSchema}
        onSubmit={() => {
          submitBudget();
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group form-row">
                <div className="col-sm-6">
                  <Field
                    name="name"
                    data-cy="name-budget-form"
                    component={Input}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      customHandleChange(e)(handleChange)
                    }
                    placeholder={
                      disabled
                        ? ""
                        : intl.formatMessage({
                            id: "BUDGET.NAME",
                          })
                    }
                    label={intl.formatMessage({
                      id: "BUDGET.NAME",
                    })}
                    disabled={disabled}
                  />
                </div>
                <div className="col-sm-6">
                  <label>
                    <FormattedMessage id={"PROJECT.TITLE.SINGLE"} />
                  </label>
                  <CustomSelect
                    name="projectId"
                    data-cy="project-budget-form"
                    onChange={(selected) =>
                      handleProjectChange({
                        target: { name: "projectId", value: selected?.value },
                      })(handleChange, setFieldValue)
                    }
                    onFocus={openProjectSelect}
                    disabled={
                      disabled || (projectHasFinanceFiles && project?.selectedBudget === values.id)
                    }
                    selected={
                      values.projectId
                        ? {
                            label: values.projectName,
                            value: values.projectId,
                          }
                        : null
                    }
                    isLoading={projectsLoading}
                    loadingMessage={() => intl.formatMessage({ id: "COMMON.LOADING" })}
                    options={
                      projects?.length
                        ? projects.map((p) => ({ label: p.name!, value: p.id! }))
                        : [{ label: values.projectName, value: values.projectId }]
                    }
                    styles={{ control: { width: "auto", maxWidth: "none" } }}
                  />
                </div>
              </div>
              <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                <li className="nav-item" onClick={() => setTab("expenses")}>
                  <span
                    className={`nav-link ${tab === "expenses" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={tab === "expenses"}
                  >
                    <FormattedMessage id="BUDGET.EXPENSES" />
                  </span>
                </li>
                <li className="nav-item" onClick={() => setTab("instalments")}>
                  <span
                    className={`nav-link ${tab === "instalments" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={tab === "instalments"}
                  >
                    <FormattedMessage id="BUDGET.INSTALMENT" />
                  </span>
                </li>
              </ul>
              <div className="mt-5">
                {tab === "expenses" && (
                  <BudgetEditExpenses
                    budget={budget}
                    disabled={disabled}
                    saveBudgetFields={saveBudgetFields}
                  />
                )}
                {tab === "instalments" && (
                  <EntityEditInstalments
                    budgetInstalments={values.budgetInstalments}
                    componentTable={BudgetInstalmentTable}
                    disabled={disabled}
                    saveBudgetFields={saveBudgetFields}
                    entities={project?.products ?? []}
                  />
                )}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
