/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import { initialFilter } from "./ProductFilesUIHelper";
import { useFilesContext } from "../../../../File/FilesProvider";
import { useSelector } from "react-redux";
import { canReadAllDocument } from "../../../../../_utils/authUtils";

const ProductFilesUIContext = createContext();

export function useProductFilesUIContext() {
  return useContext(ProductFilesUIContext);
}

export const ProductFilesUIConsumer = ProductFilesUIContext.Consumer;

export function ProductFilesUIProvider({ currentProductId, children, readOnly, context }) {
  const filesContext = useFilesContext();

  const [productId, setProductId] = useState(currentProductId);
  const [filters, setFilters] = useState(initialFilter);

  const { groups, session } = useSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
  }));

  const initFile = {
    id: undefined,
    friendlyName: "",
    fileType: "OTHER",
    productId,
    isConfidential: canReadAllDocument(groups, session, "PRODUCT"),
  };

  useEffect(() => {
    initFile.productId = currentProductId;
    setProductId(currentProductId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId]);

  const value = {
    productId,
    setProductId,
    filters,
    setFilters,
    initFile,
    readOnly,
    context,
    ...filesContext,
  };

  return <ProductFilesUIContext.Provider value={value}>{children}</ProductFilesUIContext.Provider>;
}
