export enum DataObjectTypes {
  ACTION = "ACTION",
  AVATAR = "AVATAR",
  BUDGET = "BUDGET",
  BUDGETINSTALMENT = "BUDGETINSTALMENT",
  BUDGETLINE = "BUDGETLINE",
  BUDGETLINECATEGORY = "BUDGETLINECATEGORY",
  CHAT = "CHAT",
  CHAT_MESSAGE = "CHAT_MESSAGE",
  CHAT_USER = "CHAT_USER",
  COMMENT = "COMMENT",
  COUNTER = "COUNTER",
  EMAIL_NOTIFICATION = "EMAIL_NOTIFICATION",
  FILE = "FILE",
  IN_APP_NOTIFICATION = "IN_APP_NOTIFICATION",
  LEAD = "LEAD",
  LEADINVOICE = "LEADINVOICE",
  LEADUSER = "LEADUSER",
  PHOTO = "PHOTO",
  PRODUCT_PHOTO_SHARING = "PRODUCT_PHOTO_SHARING",
  PRODUCT = "PRODUCT",
  PROJECT = "PROJECT",
  PROJECTSHARING = "PROJECTSHARING",
  PROJECT_OWNER = "PROJECT_OWNER",
  PROJECT_PHOTO_SHARING = "PROJECT_PHOTO_SHARING",
  PROJECT_SUBCONTRACTOR = "PROJECT_SUBCONTRACTOR",
  PROPCO = "PROPCO",
  REMINDER = "REMINDER",
  SETTINGS = "SETTINGS",
  SIGNATURE = "SIGNATURE",
  SMS_NOTIFICATION = "SMS_NOTIFICATION",
  TASK = "TASK",
  TASKCOMMENT = "TASKCOMMENT",
  USER = "USER",
  USER_EVENT = "USER_EVENT",
  USER_SESSION = "USER_SESSION",
  WEBSOCKET_CONNECTION = "WEBSOCKET_CONNECTION",
  PUBLIC_REQUEST = "PUBLIC_REQUEST",
}

export const ACTION = DataObjectTypes.ACTION;
export const AVATAR = DataObjectTypes.AVATAR;
export const BUDGET = DataObjectTypes.BUDGET;
export const BUDGETINSTALMENT = DataObjectTypes.BUDGETINSTALMENT;
export const BUDGETLINE = DataObjectTypes.BUDGETLINE;
export const BUDGETLINECATEGORY = DataObjectTypes.BUDGETLINECATEGORY;
export const CHAT = DataObjectTypes.CHAT;
export const CHAT_MESSAGE = DataObjectTypes.CHAT_MESSAGE;
export const CHAT_USER = DataObjectTypes.CHAT_USER;
export const COMMENT = DataObjectTypes.COMMENT;
export const COUNTER = DataObjectTypes.COUNTER;
export const EMAIL_NOTIFICATION = DataObjectTypes.EMAIL_NOTIFICATION;
export const FILE = DataObjectTypes.FILE;
export const IN_APP_NOTIFICATION = DataObjectTypes.IN_APP_NOTIFICATION;
export const LEAD = DataObjectTypes.LEAD;
export const LEADINVOICE = DataObjectTypes.LEADINVOICE;
export const LEADUSER = DataObjectTypes.LEADUSER;
export const PHOTO = DataObjectTypes.PHOTO;
export const PRODUCT_PHOTO_SHARING = DataObjectTypes.PRODUCT_PHOTO_SHARING;
export const PRODUCT = DataObjectTypes.PRODUCT;
export const PROJECT = DataObjectTypes.PROJECT;
export const PROJECTSHARING = DataObjectTypes.PROJECTSHARING;
export const PROJECT_OWNER = DataObjectTypes.PROJECT_OWNER;
export const PROJECT_PHOTO_SHARING = DataObjectTypes.PROJECT_PHOTO_SHARING;
export const PROJECT_SUBCONTRACTOR = DataObjectTypes.PROJECT_SUBCONTRACTOR;
export const PROPCO = DataObjectTypes.PROPCO;
export const REMINDER = DataObjectTypes.REMINDER;
export const SETTINGS = DataObjectTypes.SETTINGS;
export const SIGNATURE = DataObjectTypes.SIGNATURE;
export const SMS_NOTIFICATION = DataObjectTypes.SMS_NOTIFICATION;
export const TASK = DataObjectTypes.TASK;
export const TASKCOMMENT = DataObjectTypes.TASKCOMMENT;
export const USER = DataObjectTypes.USER;
export const USER_EVENT = DataObjectTypes.USER_EVENT;
export const USER_SESSION = DataObjectTypes.USER_SESSION;
export const WEBSOCKET_CONNECTION = DataObjectTypes.WEBSOCKET_CONNECTION;
export const PUBLIC_REQUEST = DataObjectTypes.PUBLIC_REQUEST;
