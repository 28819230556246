import * as React from "react";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { cloneDeep, toNumber } from "lodash-es";
import cn from "clsx";

import { useAppSelector } from "redux/hooks";

import { Card } from "_metronic/_partials/controls";

import { DateUtils } from "app/_utils/DateUtils";
import { canReadAll } from "app/_utils/authUtils";
import { SUFFIX_EURO_CURRENCY } from "app/_utils/suffixUtils";

import { WidgetUserList } from "app/_components/WidgetUserList";
import { ProgressBarFormatterComponent } from "app/_components/column-formatters";

import {
  AvatarPlaceholderClassName,
  TSaveAvatarFn,
  EditAvatarImage,
  TRemoveAvatarFn,
} from "app/_components/AvatarImage";

import * as actions from "app/modules/PropertiesManagement/_redux/projects/projectsActions";
import * as tasksActions from "app/modules/PropertiesManagement/_redux/tasks/tasksActions";

import { ProjectWidget } from "../project-widget/ProjectWidget";

import ProjectOwnerSwitch from "./ProjectOwnerSwitch";

export interface ProjectDashboardProps {
  readOnly?: boolean;
}

export const ProjectDashboard: React.FunctionComponent<ProjectDashboardProps> = ({
  readOnly = false,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { projectForEdit, tasks, isLoading, isLoadingTasks, groups, session, budget } =
    useAppSelector((state) => {
      const { projects, tasks, auth } = state;
      const { actionsLoading, projectForEdit } = projects;

      const currentProject = projectForEdit?.current;
      const selectedBudget = currentProject?.selectedBudget;

      const { groups, session } = auth;
      const { listLoading: isLoadingTasks, entities: tasksList } = tasks;

      return {
        isLoading: actionsLoading,
        projectForEdit: currentProject,
        tasks: tasksList,
        isLoadingTasks,
        groups,
        session,
        budget: currentProject?.budgets?.find((budget) => budget.id === selectedBudget),
      };
    }, shallowEqual);

  const [totalTasks, setTotalTasks] = useState<
    { relatedTo: string; status: string }[] | undefined
  >();

  const [totalDelay, setTotalDelay] = useState<number>(0);

  useEffect(() => {
    dispatch(tasksActions.fetchTasks({ relatedTo: `PROJECT#${projectForEdit?.id}` }));
  }, [projectForEdit?.tasks]);

  useEffect(() => {
    if (!isLoadingTasks && tasks) {
      setTotalTasks(
        tasks?.filter(
          (task: { relatedTo: string }) => task?.relatedTo === `PROJECT#${projectForEdit?.id}`
        )
      );
    }
  }, [tasks]);

  useEffect(() => {
    if (!isLoading && tasks !== undefined) {
      setTotalTasks(cloneDeep(tasks));
    }
  }, [isLoading, tasks]);

  useEffect(() => {
    setTotalDelay(0);
    !!tasks &&
      tasks
        .filter((task) =>
          !!task["dueDate"] && !!task["doneDate"]
            ? new Date(task["dueDate"]) < new Date(task["doneDate"])
            : new Date(task["dueDate"]) < new Date()
        )
        .forEach((task) => {
          const diff = DateUtils.diffBetweenDates(new Date(), new Date(task["dueDate"]));
          setTotalDelay((totalDelay) => totalDelay + toNumber(diff));
        });
  }, [projectForEdit?.tasks, tasks]);

  const saveAvatarImage: TSaveAvatarFn = React.useCallback(
    (fileName, image, onAfterSave) => {
      if (!projectForEdit) return;

      const avatar = {
        projectId: projectForEdit.id,
        fileName,
        image,
      };

      actions
        .setProjectAvatar(avatar)(dispatch)
        .then((response: { key: string }) => {
          onAfterSave();

          actions.updateProject({
            ...projectForEdit,
            pictureSetup: true,
            pictureKey: response.key,
          })(dispatch);
        })
        .catch(console.error);
    },
    [projectForEdit]
  );

  const removeAvatarImage: TRemoveAvatarFn = (onAfterSave) => {
    dispatch(actions.removeProjectAvatar(cloneDeep(projectForEdit)));
    onAfterSave();
  };

  const badgeStyle =
    "btn btn-sm btn-text text-uppercase font-weight-bold d-flex justify-content-center";

  return (
    <Card>
      <div className="card-body">
        <div className="d-flex">
          <EditAvatarImage
            containerClassName="mr-7 mt-lg-0 mt-3"
            imageAltText="project avatar"
            isLoading={isLoading}
            isEditable={!readOnly}
            placeholderClassName={AvatarPlaceholderClassName.PROJECT}
            url={projectForEdit?.pictureUrl}
            onSaveImage={saveAvatarImage}
            showAllowedFileTypesLabel={false}
            onRemoveImage={removeAvatarImage}
          />

          <div className="flex-grow-1">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="mr-3">
                <div className="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3">
                  {!!projectForEdit?.name ? projectForEdit?.name : "-"}
                </div>

                {projectForEdit?.address?.city && (
                  <div className="d-flex flex-wrap my-2">
                    <div className="text-muted font-weight-bold">
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <i className={"fas fa-map-marker-alt icon-md"} />
                      </span>
                      {projectForEdit?.address?.city}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="d-sm-flex flex-wrap py-2 align-items-start justify-content-between">
              <div className="d-sm-flex flex-wrap align-items-center">
                <div className="d-sm-flex align-items-center justify-content-between mr-8 mb-4">
                  <div className="m-2">
                    <div className="font-weight-bold mb-4">
                      <FormattedMessage id={"COMMON.START.DATE"} />
                    </div>
                    <span className={`${badgeStyle} btn-light-primary`}>
                      {!!projectForEdit?.startDate
                        ? DateUtils.format(new Date(projectForEdit?.startDate), intl, false)
                        : "-"}
                    </span>
                  </div>

                  <div className="m-2">
                    <div className="font-weight-bold mb-4">
                      <FormattedMessage id={"PROJECT.INFO.AVAILABILITY_DATE"} />
                    </div>
                    <span className={`${badgeStyle} btn-light-facebook`}>
                      {!!projectForEdit?.availability
                        ? DateUtils.format(projectForEdit?.availability, intl, false)
                        : "-"}
                    </span>
                  </div>

                  {!!projectForEdit?.startDate && !!projectForEdit?.availability && (
                    <div className="m-2">
                      <div className="font-weight-bold mb-4">
                        <FormattedMessage id={"PROJECT.INFO.DURATION"} />
                      </div>
                      <span className={`${badgeStyle} btn-light-primary`}>
                        {DateUtils.diffBetweenDates(
                          new Date(projectForEdit?.startDate),
                          new Date(projectForEdit?.availability),
                          intl
                        )}
                      </span>
                    </div>
                  )}

                  {!!projectForEdit?.availability &&
                    new Date(projectForEdit?.availability) > new Date(Date.now()) && (
                      <div className="m-2">
                        <div className="font-weight-bold mb-4">
                          <FormattedMessage id={"PROJECT.INFO.TIME_LEFT"} />
                        </div>
                        <span className={`${badgeStyle} btn-light-primary`}>
                          {DateUtils.diffBetweenDates(
                            new Date(new Date().setHours(0, 0, 0, 0)),
                            new Date(projectForEdit?.availability),
                            intl
                          )}
                        </span>
                      </div>
                    )}

                  <div className="m-2">
                    <div className="font-weight-bold mb-4">
                      <FormattedMessage id={"PROJECT.INFO.DELAY"} />
                    </div>
                    <span
                      className={cn(
                        badgeStyle,
                        totalDelay > 0 ? "btn-light-danger" : "btn-light-success"
                      )}
                    >
                      {totalDelay > 0
                        ? intl.formatMessage({ id: "TIMEAGO.LEFT.DAY" }, { time: totalDelay })
                        : intl.formatMessage({ id: "COMMON.ON_TIME" })}
                    </span>
                  </div>
                </div>
              </div>

              {!!totalTasks && totalTasks.length > 0 && (
                <div className="flex-grow-1 flex-shrink-0 min-w-200px max-w-250px mb-4">
                  <div className="font-weight-bold m-2 mb-4">
                    <FormattedMessage id="COMMON.PROGRESS" />
                  </div>
                  <div className="m-2" style={{ width: "80%" }}>
                    <ProgressBarFormatterComponent
                      formatExtraData={{
                        numerator: totalTasks.filter((task) => task.status === "DONE").length,
                        denominator: totalTasks.length,
                      }}
                    />
                  </div>
                </div>
              )}

              {!!projectForEdit && (
                <div className="flex-grow-1 flex-shrink-0 min-w-200px max-w-250px">
                  <ProjectOwnerSwitch readonly={readOnly} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={"separator separator-solid my-7"} />

        <div className="d-flex align-items-center flex-wrap mr-5 my-1">
          {budget?.plannedTotal !== undefined &&
            canReadAll(groups, session, "BUDGET") &&
            ProjectWidget({
              data: budget?.plannedTotal,
              icon: "flaticon-piggy-bank",
              label: "COMMON.PLANNED.EXPENSES",
              suffix: SUFFIX_EURO_CURRENCY,
            })}

          {projectForEdit?.subcontractorsFinanceFiles &&
            canReadAll(groups, session, "BUDGET") &&
            ProjectWidget({
              data:
                (projectForEdit?.subcontractorsFinanceFiles?.orders?.total?.general ?? 0) +
                (projectForEdit?.subcontractorsFinanceFiles?.supplementary_agreements?.total
                  ?.general ?? 0),
              icon: "flaticon-statistics",
              label: "COMMON.REAL.EXPENSES",
              suffix: SUFFIX_EURO_CURRENCY,
            })}

          {totalTasks !== undefined &&
            ProjectWidget({
              data: totalTasks.length,
              icon: "flaticon-file-2",
              label: totalTasks.length > 1 ? "TASKS.COUNT" : "TASK.COUNT",
            })}

          {projectForEdit?.products &&
            ProjectWidget({
              data: projectForEdit?.products?.length,
              icon: "flaticon-home-2",
              label: "COMMON.PROPERTIES",
            })}

          {((projectForEdit && projectForEdit.usersCanAccess?.length) ?? 0) > 0 && (
            <WidgetUserList
              modalTitle={intl.formatMessage(
                { id: "COMMON.USERS.ACCESS.ENTITY" },
                { entityName: projectForEdit?.name ?? "" }
              )}
              users={(cloneDeep(projectForEdit?.usersCanAccess) as []).sort(
                (a: { profilePictureUrl: string }, b: { profilePictureUrl: string }) => {
                  return (b.profilePictureUrl ?? "").localeCompare(a.profilePictureUrl ?? "");
                }
              )}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProjectDashboard;
