import * as React from "react";
import { useFormikContext } from "formik";
import { SUFFIX_PERCENTAGE } from "../../_utils/suffixUtils";
import { useIntl } from "react-intl";
import { sum } from "lodash-es";
import cn from "clsx";
import {
  InformationalMessage,
  MessageType,
} from "app/_components/InformationalMessage/InformationalMessage";
import { ILeadFileInvoiceForm } from "../../modules/PropertiesManagement/pages/leads/lead-files/lead-file-edit-dialog/invoice/LeadFileInvoiceForm";
import { formatCurrency } from "../../_utils/mathUtils";

interface InvoicePriceDetailsLineProps {
  label: string;
  value: string;
  suffix?: string;
  style?: any;
  valueColor?: string;
}

export const InvoicePriceDetailsLine: React.FunctionComponent<InvoicePriceDetailsLineProps> = ({
  label,
  value,
  style,
  valueColor = "",
}) => {
  return (
    <div className="row">
      <div className="col-6" style={style}>
        {label}
      </div>
      <div className={cn("col-6", valueColor)} style={style}>
        {value}
      </div>
    </div>
  );
};

interface IVatTotals {
  [key: number]: number;
}

interface IInvoicePriceDetails {
  basePriceIndex?: any;
  priceIndex?: any;
  subtotal: number;
  vatTotals?: IVatTotals;
  totalIndex: number;
  priceIndexChangePercentage: number;
  total: number;
  totalVat: number;
}

export const InvoicePriceDetails: React.FunctionComponent = () => {
  const { values } = useFormikContext<ILeadFileInvoiceForm>();
  const intl = useIntl();

  const [details, setDetails] = React.useState<IInvoicePriceDetails>({
    basePriceIndex: undefined,
    priceIndex: undefined,
    subtotal: 0,
    vatTotals: {},
    totalIndex: 0,
    priceIndexChangePercentage: 0,
    total: 0,
    totalVat: 0,
  });

  React.useEffect(() => {
    calcContent();
  }, [values]);

  const calcContent = (): void => {
    const invoiceDetailData = values?.content;

    if (!values || !invoiceDetailData) return;
    let subtotal = 0;
    let vatTotals: { [key: number]: number } = {};

    const basePriceIndex = values?.basePriceIndex?.value;
    const priceIndex = values?.priceIndex?.value;

    let totalIndex = 0;
    let priceIndexChangePercentage = 0;
    if (basePriceIndex && priceIndex) {
      priceIndexChangePercentage = ((priceIndex - basePriceIndex) / basePriceIndex) * 100;
    }

    for (const invoiceLine of invoiceDetailData) {
      if (invoiceLine.amount) {
        subtotal += invoiceLine.amount;
        const invoiceLinePriceIndexAmount = priceIndexChangePercentage
          ? (priceIndexChangePercentage * invoiceLine.amount) / 100
          : 0;
        totalIndex += invoiceLinePriceIndexAmount;

        const vat = invoiceLine.vat * 100;
        if (vat) {
          vatTotals[vat] =
            (vatTotals[vat] ?? 0) +
            (invoiceLine.amount + invoiceLinePriceIndexAmount) * invoiceLine.vat;
        }
      }
    }

    let totalVat = sum(Object.values(vatTotals)) ?? 0;
    if (totalVat > 0) {
      totalVat = totalVat > 0 ? totalVat / 100 : 0;
    }

    const total = subtotal + totalIndex + totalVat;

    setDetails({
      basePriceIndex,
      priceIndex,
      subtotal,
      vatTotals,
      totalIndex,
      priceIndexChangePercentage,
      totalVat,
      total,
    });
  };

  const getPriceIndexValueLabel = () => {
    let label = details?.priceIndex?.toFixed(2);

    let changePercentage = "";
    let changeAmount;
    if (details?.priceIndexChangePercentage && details?.priceIndexChangePercentage !== 0) {
      const sign = details.priceIndexChangePercentage > 0 ? "+" : "";
      changePercentage = `${sign}${
        intl.formatNumber(details?.priceIndexChangePercentage, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + SUFFIX_PERCENTAGE
      }`;
      changeAmount = ` ${formatCurrency(details?.totalIndex, 2, intl)}`;
    }
    label += changePercentage && changeAmount && ` (${changePercentage}, ${changeAmount})`;

    return label;
  };

  return (
    <>
      <InvoicePriceDetailsLine
        label={intl.formatMessage({ id: "COMMON.SUBTOTAL" })}
        value={formatCurrency(details?.subtotal, 2, intl)}
      />
      {details.priceIndex && (
        <InvoicePriceDetailsLine
          label={intl.formatMessage({ id: "PRICE_INDEX.TITLE.APPLIED" })}
          value={getPriceIndexValueLabel()}
          valueColor={
            details?.totalIndex < 0 ? "text-danger" : details.totalIndex > 0 ? "text-success" : ""
          }
        />
      )}

      {values?.basePriceIndex?.value !== values?.priceIndex?.value && (
        <div className="row">
          <div className={cn("col-6")}>
            <InformationalMessage
              messageType={MessageType.WARNING}
              message={{
                id: "PRICE_INDEX.INFORMATIONAL_MESSAGE.INDEX_DIFFERS",
              }}
            />
          </div>
          <div className="col-6" />
        </div>
      )}
      <InvoicePriceDetailsLine
        label={intl.formatMessage({ id: "COMMON.VAT" })}
        value={formatCurrency(details?.totalVat, 2, intl)}
      />
      <InvoicePriceDetailsLine
        label={intl.formatMessage({ id: "COMMON.TOTAL" })}
        style={{ fontWeight: "bold", fontSize: "1.2em" }}
        value={formatCurrency(details?.total, 2, intl)}
      />
    </>
  );
};
