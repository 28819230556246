import { cloneDeep } from "lodash-es";
import {
  processSackbarNotification,
  SNACKBAR_MESSAGE,
} from "../../../Common/SnackbarNotificationsHandler";
import { save, saved } from "../../../../../redux/global/globalActions";
import { requestFileSignature } from "../../../../_utils/signatureUtils";

import * as requestFromServer from "./projectsCrud";
import { callTypes, projectsSlice } from "./projectsSlice";

const { actions } = projectsSlice;

export const fetchProjects =
  (queryParams = {}) =>
  (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list, queryParams }));
    return requestFromServer
      .findProjects(queryParams)
      .then((response) => {
        const entities = response.items;

        dispatch(actions.projectsFetched({ entities }));

        return entities;
      })
      .then((entities) => {
        new Promise((resolve) => {
          // ensure execution is done after rendering, to display initially loaded projects
          setTimeout(async () => {
            let entitiesSet = [];

            const len = entities.length;

            const entitiesLoadedReduce = (statusesPromise) =>
              statusesPromise.reduce((acc, item) => {
                if (item.status === "fulfilled") {
                  acc.push(item.value);
                }
                return acc;
              }, []);

            for (let i = 0; i < len; i++) {
              const entity = entities[i];

              entitiesSet.push(requestFromServer.getProjectById(entity.id));

              // updated the current list on each 5 elements loaded from the server
              if (i % 10 === 0) {
                const entitiesLoaded = entitiesLoadedReduce(await Promise.allSettled(entitiesSet));
                dispatch(actions.projectsDetailsFetched({ entities: entitiesLoaded }));

                entitiesSet = [];
              }
            }

            if (entitiesSet.length > 0) {
              const entitiesLoaded = entitiesLoadedReduce(await Promise.allSettled(entitiesSet));
              dispatch(actions.projectsDetailsFetched({ entities: entitiesLoaded }));

              entitiesSet = [];
            }

            resolve("done");
          }, 0);
        });

        return entities;
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_PROJECTS, dispatch);
      });
  };

export const fetchProjectsForReseller = (username) => (dispatch) => {
  const filter = {
    resellers: { contains: username },
  };
  return fetchProjects(filter)(dispatch);
};

export const fetchProjectRelatedIds = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getProjectRelatedObjectIds(id)
    .then((response) => {
      dispatch(actions.projectRelatedIdsFetched({ relatedToObjects: response }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_PROJECT, dispatch);
    });
};

export const fetchProjectOwnerAssignees = (dispatch) =>
  requestFromServer
    .getProjectOwnerAssignees()
    .then((projectOwnerAssignees) => {
      dispatch(actions.projectOwnerAssigneesFetched({ projectOwnerAssignees }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });

export const fetchProject = (id) => (dispatch) => {
  if (!id) {
    dispatch(actions.projectFetched({ projectForEdit: undefined }));
    return Promise.resolve(undefined);
  }

  dispatch(actions.projectPreFetched({ projectId: id }));

  return requestFromServer
    .getProjectById(id)
    .then((response) => {
      const projectForEdit = response;
      dispatch(actions.projectFetched({ projectForEdit }));
      return projectForEdit;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_PROJECT, dispatch);
    });
};

export const deleteProject = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProject(id)
    .then(() => {
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_PROJECT, dispatch);

      dispatch(actions.projectDeleted({ id }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_PROJECT, dispatch);
    });
};

export const createProject = (projectForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProject(projectForCreation)
    .then((response) => {
      const project = response;
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_PROJECT, dispatch);
      dispatch(actions.projectCreated({ project }));
      return project;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_PROJECT, dispatch);
    });
};

export const updateProjectFieldLocally = (key, value) => (dispatch) => {
  dispatch(actions.projectFieldUpdatedLocally({ key, value }));
};

export const updateProject = (project) => (dispatch) => {
  dispatch(save());
  dispatch(actions.startCall({ callType: callTypes.action }));

  const projectCopy = cloneDeep(project);
  return requestFromServer
    .updateProject(project)
    .then((response) => {
      const project = { ...projectCopy, ...response };
      dispatch(actions.projectUpdated({ project }));
      dispatch(saved());
    })
    .catch((error) => {
      dispatch(saved());
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_PROJECT, dispatch);
    });
};

export const uploadFile = (fileForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createFile(fileForCreation)
    .then((response) => {
      const file = { ...response };
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPLOAD_FILE, dispatch);

      dispatch(actions.fileCreated({ file }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.ADD_FILE, dispatch);
    });
};

export const updateFile = (fileForEdit) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateFile(fileForEdit)
    .then((response) => {
      const file = { ...response };
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_FILE, dispatch);

      dispatch(actions.fileUpdated({ file }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_FILE, dispatch);
    });
};

export const openFile = (file) => (dispatch) => {
  // Open it before the async call because iOS is blocking it
  const newWindow = window.open("", "_blank");

  return requestFromServer
    .getFile(file)
    .then((response) => {
      newWindow.location = response.url;
    })
    .catch((error) => {
      newWindow.close();
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.OPEN_FILE, dispatch);
    });
};

export const addLinkedFiles = (props) => (dispatch) => dispatch(actions.linkedFilesAdded(props));
export const removeLinkedFile = (props) => (dispatch) => dispatch(actions.linkedFileRemoved(props));

export const deleteFile = (file) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteFile(file)
    .then(() => {
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_FILE, dispatch);

      dispatch(actions.fileDeleted({ file }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_FILE, dispatch);
    });
};

export const createProjectSharing = (projectSharing) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProjectSharing(projectSharing)
    .then((response) => {
      const { projectSharing, user } = response;
      dispatch(actions.projectSharingCreated({ projectSharing, user }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_PROJECT_SHARING, dispatch);
    });
};

export const removeProjectSharing = (projectSharing) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .removeProjectSharing(projectSharing)
    .then(() => {
      dispatch(actions.projectSharingRemoved({ projectSharing }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_PROJECT_SHARING, dispatch);
    });
};

export const createProjectSubcontractor = (projectSubcontractor) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProjectSubcontractor(projectSubcontractor)
    .then((response) => {
      dispatch(actions.projectSubcontractorCreated({ projectSubcontractor: response }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_PROJECT_SHARING, dispatch);
    });
};

export const removeProjectSubcontractor = (projectSubcontractor) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .removeProjectSubcontractor(projectSubcontractor)
    .then(() => {
      dispatch(actions.projectSubcontractorRemoved({ projectSubcontractor }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_PROJECT_SHARING, dispatch);
    });
};

export const addBudget = (budget) => (dispatch) => {
  dispatch(actions.addBudget({ budget }));
};

export const deleteBudget = (id) => (dispatch) => {
  dispatch(actions.deleteBudget({ id }));
};

export const requestProjectFileSignature = (signatureRequest) => (dispatch) => {
  return requestFileSignature(actions, signatureRequest, dispatch, callTypes);
};

export const fetchPhotos = (projectId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPhotosByProjectId(projectId)
    .then((response) => {
      dispatch(actions.photosFetched(response));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FETCH_PHOTOS, dispatch);
    });
};

export const photosOrderUpdate = (projectId, photosOrder) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  return requestFromServer
    .updatePhotosOrder(projectId, photosOrder)
    .then((response) => {
      dispatch(actions.photosOrderUpdate(response));
    })
    .catch((error) => {
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.ORDER_PHOTO, dispatch);
      console.log("ProjectActionsError: ", error);
    });
};

export const createPhoto =
  ({ parentId: projectId, typePhoto, file }) =>
  (dispatch) => {
    const photo = { projectId, typePhoto, file };
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .createPhoto(photo)
      .then((response) => {
        const { id, updatedAt, createdByUserId, photoSharings, isPrivate } = response;
        const newPhoto = {
          id,
          photo: `projects/${projectId}/${id}/photo.${typePhoto}`,
          localPhoto: URL.createObjectURL(file),
          updatedAt,
          projectId,
          createdByUserId,
          isPrivate,
          photoSharings,
          typePhoto,
        };
        dispatch(actions.photoCreated({ newPhoto }));
        processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_PHOTO, dispatch);
        return newPhoto;
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        processSackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_PHOTO, dispatch);
      });
  };

export const updatePhoto = (photo) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePhoto(photo)
    .then((response) => {
      dispatch(actions.photoUpdated({ photo: response }));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_PHOTO, dispatch);
    });
};

export const deletePhoto =
  ({ parentId: projectId, photoId }) =>
  (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .deletePhoto({ projectId, photoId })
      .then(() => {
        processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_PHOTO, dispatch);
        dispatch(actions.photoDeleted({ photoId }));
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        processSackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_PHOTO, dispatch);
      });
  };

export const createPhotoSharing = (photoSharing) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createPhotoSharing(photoSharing)
    .then(() => {
      dispatch(actions.photoSharingCreated({ photoSharing }));
    })
    .catch(() => {
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_PHOTO_SHARING, dispatch);
    });
};

export const removePhotoSharing = (photoSharing) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePhotoSharing(photoSharing)
    .then(() => {
      dispatch(actions.photoSharingRemoved({ photoSharing }));
    })
    .catch(() => {
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_PHOTO_SHARING, dispatch);
    });
};

export const setProjectAvatar = (avatar) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setProjectAvatar(avatar)
    .then((response) => {
      dispatch(actions.avatarCreated({}));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_FILE, dispatch);
    });
};

export const removeProjectAvatar = (project) => (dispatch) => {
  const projectUpdated = {
    ...project,
    pictureUrl: undefined,
    pictureSetup: false,
    pictureUrlExpires: undefined,
  };
  dispatch(updateProject(projectUpdated));
};
