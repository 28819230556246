import { Filter } from "../../../_components/Filter";
import * as React from "react";
import { useSubheader } from "../../../../_metronic/layout";
import { useCustomLocationState } from "../../../_utils/useCustomLocationState";
import {
  LeadsUIProvider,
  useLeadsUIContext,
} from "../../PropertiesManagement/pages/leads/LeadsUIContext";
import { LeadsTable } from "../../PropertiesManagement/pages/leads/leads-table/LeadsTable";

interface LeadTabProps {
  name: string;
  queryParam: Record<string, any>;
  context: string;
  readOnly: boolean;
}
export const LeadTab: React.FC<LeadTabProps> = ({ name, queryParam, context, readOnly = true }) => {
  const { setFromUrlTo } = useCustomLocationState();
  const subheader = useSubheader();

  const leadsUIEvents = {
    openLeadPage: (lead: any) => {
      subheader.setTitle(lead.name);
      setFromUrlTo({
        url: `/leads/${lead.id}`,
        name: name,
      });
    },
  };

  return (
    <LeadsUIProvider
      queryParamsInit={queryParam}
      leadsUIEvents={leadsUIEvents}
      readOnly={readOnly}
      context={context}
    >
      <div className="form-filtration">
        <Filter useEntityUIContext={useLeadsUIContext} />
        <LeadsTable />
      </div>
    </LeadsUIProvider>
  );
};
