/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import { initialFilter } from "./UserFilesUIHelper";
import { useFilesContext } from "../../../../File/FilesProvider";
import { canEdit, canReadAllDocument } from "../../../../../_utils/authUtils";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../../../../Settings/_redux/settingsActions";
import logout from "../../../../Auth/pages/Logout";

const UserFilesUIContext = createContext();

export function useUserFilesUIContext() {
  return useContext(UserFilesUIContext);
}

export const UserFilesUIConsumer = UserFilesUIContext.Consumer;

export function UserFilesUIProvider({ currentUserId, children, readOnly, canUpload, userTypes }) {
  const dispatch = useDispatch();
  const filesContext = useFilesContext();
  const { setShowEditFileDialog, setSelectedId, setUploadingFile } = filesContext;

  const [userId, setUserId] = useState(currentUserId);
  const [filters, setFilters] = useState(initialFilter);

  const { groups, session, settings } = useSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
    settings: state.settings.settingsForEdit.saved,
  }));

  useEffect(() => {
    if (!settings) {
      fetchSettings(dispatch).catch(() => {
        logout();
      });
    }
  }, [settings]);

  const initFile = {
    id: undefined,
    friendlyName: "",
    fileType: "OTHER",
    userId,
    userType: userTypes?.[0],
    visible: true,
    isConfidential: canReadAllDocument(groups, session, "USER"),
    selectedForFinance: canEdit(groups, session, "BUDGET"),
  };

  const financeFileDefaultLine = () => ({
    id: uuid(),
    vat: settings?.defaultVat ?? 0,
    grossAmount: 0,
    amount: 0,
    discount: 0,
    discountType: "absolute",
  });

  const financeFileDefaultCategory = () => ({
    lines: [financeFileDefaultLine()],
  });

  useEffect(() => {
    initFile.userId = currentUserId;
    setUserId(currentUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  const openRequestFileDialog = () => {
    setSelectedId(undefined);
    setUploadingFile(false);
    setShowEditFileDialog(true);
  };
  const openUploadFileDialog = (id) => {
    setSelectedId(id);
    setUploadingFile(true);
    setShowEditFileDialog(true);
  };
  const value = {
    userId,
    setUserId,
    filters,
    setFilters,
    initFile,
    openRequestFileDialog,
    openUploadFileDialog,
    readOnly,
    canUpload,
    userTypes,
    financeFileDefaultLine,
    financeFileDefaultCategory,
    ...filesContext,
  };

  return <UserFilesUIContext.Provider value={value}>{children}</UserFilesUIContext.Provider>;
}
