import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserInformation, getUserTokens } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserSessionLoaded: "[Load User] Auth API",
  UserSessionUpdateProfilePicture: "[Update User] Profile Picture",
  RemoveSession: "[Remove Session] Action",
  AcceptGDPR: "[Accept GDPR] Action",
};

const initialAuthState = {
  user: undefined,
  challengeName: undefined,
  session: undefined,
  tokens: undefined,
  groups: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: `v${process.env.REACT_APP_VERSION}-immotool-auth`,
    whitelist: ["user", "session", "tokens", "groups"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const tokens = action.payload.tokens;
        delete tokens.refreshToken;
        return {
          user: action.payload.user,
          challengeName: action.payload.challengeName,
          session: action.payload.session,
          tokens,
        };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserSessionLoaded: {
        const { tokens, session, groups } = action.payload;
        return { ...state, tokens, session, groups };
      }

      case actionTypes.UserSessionUpdateProfilePicture: {
        const { user } = action.payload;

        const previousSession = state.session;
        const profilePictureUrl = user.profilePictureUrl || previousSession.profilePictureUrl;

        return {
          ...state,
          session: {
            ...previousSession,
            profilePictureUrl,
          },
        };
      }

      case actionTypes.AcceptGDPR: {
        return {
          ...state,
          session: { ...state.session, gdprApprovedAt: action.payload.gdprApprovedAt },
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (user, session, tokens, groups) => ({
    type: actionTypes.Login,
    payload: {
      user,
      challengeName: user?.challengeName,
      tokens: tokens ?? {},
      groups: groups ?? [],
      session: session,
    },
  }),
  changePassword: (user, tokens) => ({
    type: actionTypes.Login,
    payload: { user, challengeName: user?.challengeName, tokens },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUserSession: (user) => ({ type: actionTypes.UserRequested, payload: { user } }),
  updadateUserSessionProfilePicture: (user) => ({
    type: actionTypes.UserSessionUpdateProfilePicture,
    payload: { user },
  }),
  fulfillUserInformation: (info) => ({
    type: actionTypes.UserSessionLoaded,
    payload: { tokens: info.tokens, session: info.session, groups: info.groups },
  }),
  acceptGDPR: (gdprApprovedAt) => ({ type: actionTypes.AcceptGDPR, payload: { gdprApprovedAt } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUserSession());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try {
      const tokens = yield getUserTokens().catch((err) => {
        // DO NOTHING
      });
      if (!!tokens) {
        delete tokens.refreshToken;
        const groups = tokens.accessToken.payload["cognito:groups"] || [];
        console.log("actions.fulfillUserInformation: {tokens, groups}", { tokens, groups });
        const session = yield getUserInformation(
          tokens.accessToken.payload.username,
          tokens.idToken.payload.email
        );
        console.log("actions.fulfillUserInformation: ", { tokens, session, groups });
        yield put(actions.fulfillUserInformation({ tokens, session, groups }));
      }
    } catch (e) {
      console.log("actionTypes.UserRequested err:", e);
      yield put(actions.logout());
    }
  });
}
