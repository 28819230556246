import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { LoadingDialog } from "_metronic/_partials/controls";

import { canAddOrRequestClientFilesOnLeadView } from "../../../../../../_utils/authUtils";
import { CLIENT } from "../../../../../../_utils/userTypes";
import { formatDisplayNameIntl } from "../../../../../../_utils/userUtils";

import { UserFilesUIProvider } from "../../../../../UsersManagement/pages/profile/user-files/UserFilesUIContext";
import { UserFileEditDialog } from "../../../../../UsersManagement/pages/profile/user-files/user-file-edit-dialog/UserFileEditDialog";
import { UserFilesFilter } from "../../../../../UsersManagement/pages/profile/user-files/UserFilesFilter";
import { UserFilesTable } from "../../../../../UsersManagement/pages/profile/user-files/UserFilesTable";

import { CustomSelect } from "app/_components/CustomSelect";

export function ClientsFiles() {
  const intl = useIntl();
  const [selectedOption, setSelectedOption] = useState({
    label: intl.formatMessage({ id: "COMMON.NONE" }),
    value: "NONE",
  });
  const [selectableClientsOptions, setSelectableClientsOptions] = useState({
    label: intl.formatMessage({ id: "COMMON.NONE" }),
    value: "NONE",
  });

  const { groups, session, clients, isLoading } = useSelector(
    (state) => ({
      clients: state.leadClients.entities,
      isLoading: state.leadClients.listLoading,
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (selectableClientsOptions?.length > 0) {
      setSelectedOption(selectableClientsOptions[0]);
    }
  }, [selectableClientsOptions]);

  useEffect(() => {
    setSelectableClientsOptions(
      clients.map((client) => ({
        label: formatDisplayNameIntl(intl, client, false),
        value: client,
      }))
    );
  }, [clients]);

  return (
    <>
      {isLoading ? (
        <>
          <LoadingDialog isLoading={isLoading} />
        </>
      ) : (
        <div>
          <UserFilesUIProvider
            readOnly
            userTypes={[CLIENT]}
            currentClientId={selectedOption.value.id}
          >
            <UserFileEditDialog />
            <div className="row">
              {canAddOrRequestClientFilesOnLeadView(groups, session) && (
                <div className="col-lg-6 mt-lg-0 mt-5 order-lg-1 order-2">
                  <UserFilesFilter />
                </div>
              )}
              <div className="col-lg-6 order-lg-2 order-1">
                <CustomSelect
                  clearable={false}
                  name="clientId"
                  label="Client"
                  selected={selectedOption}
                  onChange={(selected) => {
                    setSelectedOption(selected);
                  }}
                  options={selectableClientsOptions}
                />
              </div>
            </div>
            <UserFilesTable files={selectedOption.value.files} />
          </UserFilesUIProvider>
        </div>
      )}
    </>
  );
}
