import * as React from "react";
import { NumberInput } from "../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../_utils/suffixUtils";
import cn from "clsx";
import { NumberFormatValues } from "react-number-format";
import { useState } from "react";

export interface DiscountInputProps {
  onValueChange: (values: NumberFormatValues) => void;
  value: number;
  onDiscountTypeChange: (discountType: TDiscountType) => void;
}
type TDiscountType = "absolute" | "percentage";
export const DiscountInput: React.FC<DiscountInputProps> = ({
  onValueChange,
  value,
  onDiscountTypeChange,
}) => {
  const [discountType, setDiscountType] = useState<TDiscountType>("absolute");

  const toggleDiscount = (discountType: TDiscountType) => () => {
    if (discountType === "absolute") {
      setDiscountType("absolute");
    } else {
      setDiscountType("percentage");
    }
    onDiscountTypeChange(discountType);
  };
  return (
    <div className="input-group">
      <NumberInput
        className="form-control form-control-sm text-right"
        decimalScale={2}
        suffix={discountType === "absolute" ? SUFFIX_EURO_CURRENCY : SUFFIX_PERCENTAGE}
        value={value}
        onValueChange={onValueChange}
      />
      <div className="input-group-append btn-group">
        <button
          type="button"
          className={cn(
            "btn btn-sm btn-outline-secondary",
            discountType === "absolute" && "active"
          )}
          onClick={toggleDiscount("absolute")}
        >
          {SUFFIX_EURO_CURRENCY}
        </button>
        <button
          type="button"
          className={cn(
            "btn btn-sm btn-outline-secondary",
            discountType === "percentage" && "active"
          )}
          onClick={toggleDiscount("percentage")}
        >
          {SUFFIX_PERCENTAGE}
        </button>
      </div>
    </div>
  );
};
