import React, { useState } from "react";
import { SendUserCredentialsModal } from "./actions-modals/SendUserCredentialsModal";
import { RemoveUserAccessModal } from "./actions-modals/RemoveUserAccessModal";

import { useSelector } from "react-redux";
import { SendAmlKycRequestModal } from "./actions-modals/SendAmlKycRequestModal";
import { RequestUserInformationModal } from "./actions-modals/RequestUserInformationModal";
import { FormattedMessage } from "react-intl";
import { useEntityDeleteDialogContext } from "../../../../PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";
import * as actions from "../../../_redux/usersActions";
import { canCreate, canDeleteUser } from "../../../../../_utils/authUtils";
import { useHistory } from "react-router-dom";

export function ActionsTab({ afterDeleteAction, user, actionsLoading }) {
  const history = useHistory();

  const [showSendUserCredentialsModal, setShowSendUserCredentialsModal] = useState(false);
  const [showRemoveUserAccessModal, setShowRemoveUserAccessModal] = useState(false);
  const [showRequestUserInfoModal, setShowRequestUserInfoModal] = useState(false);
  const [showSendAmlKycRequestModal, setShowSendAmlKycRequestModal] = useState(false);

  const { groups, session } = useSelector((state) => ({
    groups: state.auth.groups,
    session: state.auth.session,
  }));
  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const openDeleteUserDialog = () => {
    setDeleteEntityDialog({
      action: { fn: actions.deleteUserById, props: { id: user.id } },
      entityType: user.userTypes[0],
      onDelete: afterDeleteAction,
      extraContent: user.userTypes.includes("CLIENT") && (
        <div>
          <i>
            <small className="text-danger">
              <FormattedMessage id="COMMON.ACTION.DELETE.EVERYTHING" />
            </small>
          </i>
        </div>
      ),
    });
  };

  const redirectToClientsList = (params) => {
    history.push({
      pathname: `/subcontractors/${user.id}/action/contact/clients`,
      state: { ...params },
    });
  };

  return (
    <>
      <SendUserCredentialsModal
        user={user}
        actionsLoading={actionsLoading}
        showSendUserCredentialsModal={showSendUserCredentialsModal}
        setShowSendUserCredentialsModal={setShowSendUserCredentialsModal}
      />
      <RemoveUserAccessModal
        user={user}
        actionsLoading={actionsLoading}
        showRemoveUserAccessModal={showRemoveUserAccessModal}
        setShowRemoveUserAccessModal={setShowRemoveUserAccessModal}
      />

      {["CLIENT", "SUBCONTRACTOR"].some((value) => user?.userTypes.includes(value)) && user?.id && (
        <>
          {!user?.isDisabled && (
            <RequestUserInformationModal
              user={user}
              actionsLoading={actionsLoading}
              showModal={showRequestUserInfoModal}
              setShowModal={setShowRequestUserInfoModal}
            />
          )}

          <SendAmlKycRequestModal
            user={user}
            actionsLoading={actionsLoading}
            showModal={showSendAmlKycRequestModal}
            setShowModal={setShowSendAmlKycRequestModal}
          />
        </>
      )}

      {user && (
        <div className="my-5">
          <h3>
            <FormattedMessage id="ACTION.TITLE" />
          </h3>

          <div className="tab-content pt-3 d-md-flex">
            <div className="row">
              {canCreate(groups, session, "USER_ACTION") && (
                <div className="col-12 pb-7 mr-5">
                  {user?.isDisabled ? (
                    <>
                      <button
                        id="request-user-info"
                        type="button"
                        className={`btn btn-success font-weight-bold w-sm-100`}
                        onClick={() => setShowSendUserCredentialsModal(true)}
                        data-cy="button-user-create-access"
                        disabled={!user.mobile}
                      >
                        <span>
                          <FormattedMessage id="USER.ACTION.ACCESS.CREATE" />
                        </span>
                      </button>
                      {!user.mobile && (
                        <div className={"text-warning mt-1"}>
                          <FormattedMessage id={"USER.ACCESS.MOBILE_REQUIRED"} />
                        </div>
                      )}
                    </>
                  ) : (
                    <button
                      id="request-user-info"
                      type="button"
                      className={`btn btn-primary font-weight-bold w-sm-100`}
                      onClick={() => setShowSendUserCredentialsModal(true)}
                      data-cy="button-user-reset-access"
                    >
                      <span>
                        <FormattedMessage id="USER.ACTION.ACCESS.RESET" />
                      </span>
                    </button>
                  )}
                </div>
              )}
            </div>

            {!!user?.id && !user?.isDisabled && canCreate(groups, session, "USER_ACTION") && (
              <div className="row">
                <div className="col-12 pb-7">
                  <button
                    id="remove-user-access"
                    type="button"
                    className={`btn btn-danger font-weight-bold w-sm-100`}
                    onClick={() => setShowRemoveUserAccessModal(true)}
                    data-cy="button-user-remove-access"
                  >
                    <span>
                      <FormattedMessage id="USER.ACTION.ACCESS.REMOVE" />
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>

          {!!user?.id && user?.userTypes?.includes("SUBCONTRACTOR") && !user?.isDisabled && (
            <div className="tab-content">
              <div className="row">
                <div className="col-12 pb-7">
                  <button
                    id="create-task-contact-people"
                    type="button"
                    className={`btn btn-primary font-weight-bold w-sm-100`}
                    onClick={() => redirectToClientsList({ assignee: user })}
                    data-cy="button-action-contact-people"
                  >
                    <span>
                      <FormattedMessage id="USER.ACTION.TASK.CONTACT_PEOPLE" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}

          {["CLIENT", "SUBCONTRACTOR"].some((value) => user?.userTypes.includes(value)) &&
            user?.id &&
            canCreate(groups, session, "USER_ACTION") && (
              <div className="tab-content d-md-flex">
                {!user?.isDisabled && (
                  <div className="row">
                    <div className="col-12 pb-7 mr-5">
                      <button
                        id="request-user-info"
                        type="button"
                        className={`btn btn-primary font-weight-bold w-sm-100`}
                        onClick={() => setShowRequestUserInfoModal(true)}
                        data-cy="button-user-request-info"
                      >
                        <span>
                          <FormattedMessage id="USER.ACTION.REQUEST.FILLOUT.PERSONAL.INFORMATION" />
                        </span>
                      </button>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-12 pb-7">
                    <button
                      id="request-user-info"
                      type="button"
                      className={`btn btn-primary font-weight-bold w-sm-100`}
                      onClick={() => setShowSendAmlKycRequestModal(true)}
                      data-cy="button-user-request-kyc-form"
                    >
                      <span>
                        <FormattedMessage id="USER.ACTION.REQUEST.FILLOUT.KYC_AML" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          {canDeleteUser(groups, session, user) && (
            <div className="tab-content pb-5">
              <div className="row">
                <div className="col-12">
                  <button
                    id="delete-user"
                    type="button"
                    className={`btn btn-danger font-weight-bold w-sm-100`}
                    onClick={() => openDeleteUserDialog()}
                    data-cy="button-user-delete"
                  >
                    <span>
                      <FormattedMessage
                        id={
                          "USER.ACTION.DELETE." +
                          (user?.userTypes?.includes("CLIENT") ? "CLIENT" : "USER")
                        }
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
