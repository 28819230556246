/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import "./TasksWidget.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatDisplayNameMeIntl } from "../../../_utils/userUtils";
import TimeAgo from "react-timeago/lib";
import PerfectScrollbar from "react-perfect-scrollbar";
// import * as dashboardActions from "../_redux/dashboardActions";
import * as tasksActions from "../../PropertiesManagement/_redux/tasks/tasksActions";
import * as actions from "../../PropertiesManagement/_redux/tasks/tasksActions";
import { TaskEditDialog } from "../../PropertiesManagement/pages/tasks/task-edit-dialog/TaskEditDialog";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getColorForTimeAgo,
  getColorForTimeAgoIcon,
  getDesiredDate,
  getTaskText,
  getTimeAgoCaption,
  sortedTasksUtils,
} from "app/_utils/tasks";
import { useEntityDeleteDialogContext } from "../../PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";
import { useHtmlClassService } from "../../../../_metronic/layout";
import objectPath from "object-path";
import { DropdownCustomToggler } from "../../../../_metronic/_partials/dropdowns";
import { Dropdown } from "react-bootstrap";
import { TASK_SORTER_OPTION } from "../../../_utils/listUtils";
import { Sorter } from "app/_components/Sorter";
import { canDelete, canEdit } from "../../../_utils/authUtils";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function TasksWidget({ className, relatedTo }) {
  const intl = useIntl();

  const [selectedSort, setSelectedSort] = useState();

  const [showTaskEditModal, setShowTaskEditModal] = useState(false);
  const [taskId, setTaskId] = useState(false);
  const history = useHistory();

  const { isLoading, tasks, session, groups } = useSelector((state) => ({
    isLoading: state.dashboard.tasksLoading,
    // tasks: state.dashboard.tasks,
    tasks: state.tasks.entities,
    session: state.auth.session,
    groups: state.auth.groups,
  }));

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
      colorsThemeBaseWarning: objectPath.get(uiService.config, "js.colors.theme.base.warning"),
      colorsThemeBaseSuccess: objectPath.get(uiService.config, "js.colors.theme.base.success"),
      colorsThemeBaseDanger: objectPath.get(uiService.config, "js.colors.theme.base.danger"),
      colorsThemeBaseInfo: objectPath.get(uiService.config, "js.colors.theme.base.info"),
    };
  }, [uiService]);
  let tasksToUse =
    isLoading && tasks.length === 0
      ? []
      : tasks
          // .filter((t) => (relatedTo ? t.assignee.id === relatedTo.split("#")[1] : true))
          .filter(
            (t) =>
              ((t.status === "IN_PROGRESS" || t.status === "PLANNED" || t.status === "STUCK") &&
                session &&
                session.id &&
                t.assignee?.id === session.id) ||
              (t.taskType !== "CUSTOM" &&
                t.status === "IN_PROGRESS" &&
                t.createdByUserId === session?.id)
          );
  tasksToUse = sortedTasksUtils(tasksToUse, selectedSort);

  const dispatch = useDispatch();
  const markTaskAsDone = (e, task) => {
    e.stopPropagation();
    dispatch(
      tasksActions.updateTask({
        ...task,
        status: "DONE",
        doneDate: new Date().toISOString(),
      })
    );
  };

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const deleteTask = (e, task) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteEntityDialog({
      action: { fn: actions.deleteTask, props: task.id },
      entityType: "TASK",
    });
  };

  const openTaskDetails = (task) => {
    if (task.taskType === "CUSTOM") {
      setTaskId(task.id);
      setShowTaskEditModal(true);
      history.push({
        search: [`mode="edit"`, `id=${taskId}`].join("&"),
      });
    }
  };

  const resetQueryParams = () => {
    history.push({
      search: "",
    });
  };

  return (
    <>
      <TaskEditDialog
        show={showTaskEditModal}
        mode={"edit"}
        id={taskId}
        relatedTo={relatedTo}
        onHide={() => {
          setShowTaskEditModal(false);
          resetQueryParams();
        }}
      />

      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0 align-items-center">
          <h3 className="card-title font-weight-bolder text-dark mt-10 mb-4">
            <FormattedMessage id="TASK.TODO" />
          </h3>
          {tasksToUse.length > 1 && (
            <Sorter
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              option={TASK_SORTER_OPTION}
            />
          )}
          {/*<div className="card-toolbar">*/}
          {/*  <Dropdown className="dropdown-inline" alignRight>*/}
          {/*    <Dropdown.Toggle*/}
          {/*        id="dropdown-toggle-top"*/}
          {/*        as={DropdownCustomToggler}*/}
          {/*    >*/}
          {/*      <i className="ki ki-bold-more-ver"/>*/}
          {/*    </Dropdown.Toggle>*/}
          {/*    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">*/}
          {/*      <DropdownMenu3/>*/}
          {/*    </Dropdown.Menu>*/}
          {/*  </Dropdown>*/}
          {/*</div>*/}
        </div>

        {/* Body */}
        <div className="card-body pt-0 px-0 mt-4">
          {tasksToUse.length === 0 && !isLoading && (
            <div className="mb-6 pl-8 pr-4">
              <i>
                <FormattedMessage id="TASK.NOTHING_TO_DO" />
              </i>
            </div>
          )}
          {tasksToUse.length > 0 && (
            <PerfectScrollbar
              options={perfectScrollbarOptions}
              style={{ minHeight: "428px", maxHeight: "600px", position: "relative" }}
            >
              {tasksToUse.map((item, index) => (
                <div className="mb-6 pl-8 pr-4" key={index} onClick={() => openTaskDetails(item)}>
                  <div className="d-flex align-items-center flex-grow-1">
                    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                      <div className="d-flex flex-column align-items-left py-2 w-100">
                        {isLoading && Number.isInteger(item) ? (
                          <>
                            <div className={"mb-1 task-loading"}>
                              <span className="task-loading-anim" />
                            </div>

                            <span style={{ minHeight: "19px" }} />
                          </>
                        ) : (
                          <>
                            <div className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">
                              {getTaskText(intl, item?.name, item?.attachments)}
                            </div>
                            {!!item.assignee && (
                              <span
                                className="text-muted font-weight-bold"
                                style={{ minHeight: "19px" }}
                              >
                                <FormattedMessage id="TASK.ASSIGNEE" />:{" "}
                                {formatDisplayNameMeIntl(intl, item.assignee, session?.id)}
                              </span>
                            )}
                          </>
                        )}
                        {item.dueDate && (
                          <div className={"text-left"}>
                            <span
                              className="label label-lg label-inline font-weight-bold mt-2"
                              style={getColorForTimeAgo(item, layoutProps)}
                            >
                              <i
                                style={{
                                  color: getColorForTimeAgoIcon(item, layoutProps),
                                  marginRight: 5,
                                }}
                                className={` fas fa-clock icon-md`}
                              />
                              <TimeAgo
                                className="ml-1"
                                date={getDesiredDate(item)}
                                formatter={(value, unit, suffix) => {
                                  return getTimeAgoCaption(item, value, unit, intl);
                                }}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {(canEdit(groups, session, "TASK") || canDelete(groups, session, "TASK")) && (
                      <div className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 ml-4">
                        <Dropdown className="dropdown-inline" alignRight>
                          <Dropdown.Toggle id="dropdown-toggle-top" as={DropdownCustomToggler}>
                            <i className="ki ki-bold-more-ver" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <ul className="navi">
                              {canEdit(groups, session, "TASK") && (
                                <li className="navi-item">
                                  <button
                                    className="text-left btn btn-block navi-link"
                                    onClick={(e) => markTaskAsDone(e, item)}
                                  >
                                    <span>
                                      <i className="text-success flaticon2-checkmark mr-1" />
                                    </span>
                                    <span>
                                      <FormattedMessage id="TASK.MARK_AS_DONE" />
                                    </span>
                                  </button>
                                </li>
                              )}
                              {canDelete(groups, session, "TASK") && (
                                <li className="navi-item">
                                  <button
                                    className="text-left btn btn-block navi-link"
                                    onClick={(e) => deleteTask(e, item)}
                                  >
                                    <span>
                                      <i className="text-danger flaticon2-trash mr-1" />
                                    </span>
                                    <span>
                                      <FormattedMessage id="COMMON.DELETE" />
                                    </span>
                                  </button>
                                </li>
                              )}
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </PerfectScrollbar>
          )}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
