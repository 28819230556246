import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash-es";
import { initialFilters } from "./ProjectsUIHelpers";

const ProjectsUIContext = createContext();

export function useProjectsUIContext() {
  return useContext(ProjectsUIContext);
}

export const ProjectsUIConsumer = ProjectsUIContext.Consumer;

export function ProjectsUIProvider({ projectsUIEvents, children, queryParamsInit, readOnly }) {
  const [queryParams, setQueryParams] = useState(queryParamsInit);
  const [filters, setFiltersBase] = useState(initialFilters);
  const filterList = [
    { value: "name", label: "COMMON.NAME" },
    { value: "address.city", label: "ADDRESS.CITY" },
    { value: "createdAt", label: "COMMON.CREATED.AT" },
    { value: "availability", label: "PROJECT.INFO.AVAILABILITY_DATE" },
    { value: "startDate", label: "COMMON.START.DATE" },
  ];
  const [ids, setIds] = useState([]);
  const setFilters = useCallback((nextFilters) => {
    setFiltersBase((prevFilters) => {
      if (isFunction(nextFilters)) {
        nextFilters = nextFilters(prevFilters);
      }

      if (isEqual(prevFilters, nextFilters)) {
        return prevFilters;
      }

      return nextFilters;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParams,
    ids,
    setIds,
    filters,
    setFilters,
    newProjectButtonClick: projectsUIEvents?.newProjectButtonClick,
    openProjectPage: projectsUIEvents?.openProjectPage,
    openDeleteProjectDialog: projectsUIEvents?.openDeleteProjectDialog,
    readOnly,
    filterList,
  };

  return <ProjectsUIContext.Provider value={value}>{children}</ProjectsUIContext.Provider>;
}
