import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useUserFilesUIContext } from "../UserFilesUIContext";
import { fetchUsersByUserType, requestUserFileSignature } from "../../../../_redux/usersActions";
import { RequestSignatureDialog } from "../../../../../../_components/RequestSignatureDialog";
import { useEffect } from "react";
import { CLIENT, SUBCONTRACTOR, USER } from "../../../../../../_utils/userTypes";

export function UserRequestSignature() {
  useEffect(() => {
    dispatch(fetchUsersByUserType({ userTypes: [USER, CLIENT, SUBCONTRACTOR] }));
  }, []);
  const dispatch = useDispatch();
  const { users, userForEdit } = useSelector(
    (state: any) => ({
      users: state.users.entities,
      userForEdit: state.users.entityForEdit?.saved,
    }),
    shallowEqual
  );

  return (
    <RequestSignatureDialog
      useFilesUIContext={useUserFilesUIContext}
      userList={[...users]}
      fileList={userForEdit?.files}
      requestFileSignature={requestUserFileSignature}
    />
  );
}
