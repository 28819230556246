import { Icon, IconType } from "../Icon";
import { IGNORE_FIELDS, PROPERTY_TO_MESSAGE_ID } from "./utilsAuditTrailTranslations";
import { isEmpty } from "lodash-es";
import { FormattedMessage, IntlShape } from "react-intl";
import { IDiffEntry, IEvent } from "./models";
import { DateUtils } from "../../_utils/DateUtils";

export const eventTypes: any = {
  INSERT: {
    labelId: "HISTORY.ACTION.CREATED",
    iconId: IconType.AUDIT_TRAIL_CREATED,
  },
  READ: {
    labelId: "HISTORY.ACTION.VIEWED",
    iconId: IconType.AUDIT_TRAIL_VIEWED,
    hideDuplicated: true,
  },
  LIST: {
    labelId: "HISTORY.ACTION.VIEWED",
    iconId: IconType.AUDIT_TRAIL_VIEWED,
    hideDuplicated: true,
  },
  MODIFY: {
    labelId: "HISTORY.ACTION.UPDATED",
    iconId: IconType.AUDIT_TRAIL_UPDATED,
  },
  REMOVE: {
    labelId: "HISTORY.ACTION.DELETED",
    iconId: IconType.AUDIT_TRAIL_DELETED,
  },
  FILE_OPEN_DOCUMENT: {
    labelId: "HISTORY.ACTION.OPEN_FILE_DOCUMENT",
    iconId: IconType.AUDIT_TRAIL_OPEN_FILE_DOCUMENT,
  },
  FILE_UPLOADED_REQUESTED_FILE: {
    labelId: "HISTORY.ACTION.FILE_UPLOADED_REQUESTED_FILE",
    iconId: IconType.AUDIT_TRAIL_FILE_UPLOADED,
  },
  SIGNATURE_REQUEST_CREATED: {
    labelId: "HISTORY.ACTION.SIGNATURE_REQUEST_CREATED",
    iconId: IconType.AUDIT_TRAIL_SIGNATURE,
  },
  SIGNATURE_DOCUMENT_SIGNED: {
    labelId: "HISTORY.ACTION.SIGNATURE_DOCUMENT_SIGNED",
    iconId: IconType.AUDIT_TRAIL_SIGNATURE,
  },
};

const isValidDate = function (date: string) {
  // @ts-ignore
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};

const getLabelForProperty = (intl: IntlShape, value?: string): string => {
  let valueIn = value?.toString() || "-";

  if (isValidDate(valueIn)) {
    return DateUtils.formatDateString(valueIn, intl);
  }
  if (
    value === null ||
    value?.toString()?.replaceAll('"', "") === "" ||
    value === undefined ||
    value.toString() === "[]"
  ) {
    return "-";
  }

  const messageId: string = PROPERTY_TO_MESSAGE_ID?.[valueIn];

  let label = valueIn;
  if (messageId) {
    label = intl.formatMessage({ id: messageId });
  }

  return label ?? "-";
};

const getFormattedModifyRow = (
  intl: IntlShape,
  eventName: EventNames,
  entry: IDiffEntry,
  propertyKey: string,
  eventId: string,
  index: number
) => {
  return (
    <tr key={eventId + "-" + index}>
      {entry.op === "add" && (
        <>
          <td>{getLabelForProperty(intl, entry.path)}</td>
          {eventName === "MODIFY" && <td>-</td>}
          <td />
          <td>{getLabelForProperty(intl, entry.value)}</td>
        </>
      )}
      {entry.op === "replace" && (
        <>
          <td>{getLabelForProperty(intl, propertyKey)}</td>
          <td> {getLabelForProperty(intl, entry.oldValue)} </td>
          <td>
            <Icon name={IconType.AUDIT_TRAIL_MODIFIED} />
          </td>
          <td>{getLabelForProperty(intl, entry.newValue)}</td>
        </>
      )}
    </tr>
  );
};

export const getEntries = (intl: IntlShape, event: IEvent) => {
  const entries: any[] = [];

  if (["MODIFY", "INSERT", "SIGNATURE_REQUEST_CREATED"].includes(event.eventName)) {
    event?.diff?.forEach((entry: IDiffEntry, index) => {
      const propertyKey = entry.path[entry.path.length - 1];
      return (
        propertyKey &&
        !IGNORE_FIELDS.includes(propertyKey) &&
        entries.push(
          getFormattedModifyRow(intl, event.eventName, entry, propertyKey, event.id, index)
        )
      );
    });

    return (
      <>
        {!isEmpty(entries) && (
          <table>
            {["INSERT", "SIGNATURE_REQUEST_CREATED"].includes(event.eventName) && (
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="AUDIT.HEADER_NAME.PROPERTY_INSERTED_NAME" />
                  </th>
                  <th />
                  <th>
                    <FormattedMessage id="AUDIT.HEADER_NAME.PROPERTY_INSERTED_VALUE" />
                  </th>
                </tr>
              </thead>
            )}
            {event.eventName === "MODIFY" && (
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="AUDIT.HEADER_NAME.PROPERTY_MODIFIED_NAME" />
                  </th>
                  <th>
                    <FormattedMessage id="AUDIT.HEADER_NAME.PROPERTY_OLD_VALUE" />
                  </th>
                  <th></th>
                  <th>
                    <FormattedMessage id="AUDIT.HEADER_NAME.PROPERTY_NEW_VALUE" />
                  </th>
                </tr>
              </thead>
            )}
            <tbody>{entries ?? ""}</tbody>
          </table>
        )}
      </>
    );
  }
};

export enum EventNames {
  INSERT = "INSERT",
  READ = "READ",
  LIST = "LIST",
  MODIFY = "MODIFY",
  REMOVE = "REMOVE",
  AUDIT_TRAIL = "AUDIT_TRAIL",
  SHARE = "SHARE",
  ANALYSIS = "ANALYSIS",
  UPLOAD_PHOTO = "UPLOAD_PHOTO",
  UPDATE_PHOTO = "UPDATE_PHOTO",
  DELETE_PHOTO = "DELETE_PHOTO",
  HIDDEN_LINE = "HIDDEN_LINE",
  CONFIDENTIAL_DOCUMENT = "CONFIDENTIAL_DOCUMENT",
  FILE_OPEN_DOCUMENT = "FILE_OPEN_DOCUMENT",
  FILE_UPLOADED_REQUESTED_FILE = "FILE_UPLOADED_REQUESTED_FILE",
  SIGNATURE_REQUEST_CREATED = "SIGNATURE_REQUEST_CREATED",
  SIGNATURE_DOCUMENT_SIGNED = "SIGNATURE_DOCUMENT_SIGNED",
}
