import * as React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ModalProgressBar } from "_metronic/_partials/controls";
import { IConfirmationDialogProps } from "./ConfirmationDialogContext";

interface ConfirmationDialogProps {
  confirmationDialog: IConfirmationDialogProps;
  unSetConfirmationDialog: any;
}

export enum FunctionType {
  DEFAULT,
  ASYNC,
  ACTION_DISPATCH,
}
export const ConfirmationDialog: React.FunctionComponent<ConfirmationDialogProps> = ({
  confirmationDialog,
  unSetConfirmationDialog,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>();

  const {
    action,
    title,
    body,
    extraContent = null,
    loading,
    actionButtonLabel,
  } = confirmationDialog;

  const executeAction = () => {
    const { fnType = FunctionType.DEFAULT, fn, props } = action;

    switch (fnType) {
      case FunctionType.ACTION_DISPATCH:
        setIsLoading(true);
        fn(props)(dispatch).then(() => {
          setIsLoading(false);
          unSetConfirmationDialog();
        });
        break;

      case FunctionType.ASYNC:
        setIsLoading(true);
        fn(props).then(() => {
          setIsLoading(false);
          unSetConfirmationDialog();
        });
        break;

      case FunctionType.DEFAULT:
      default: {
        setIsLoading(true);
        fn(props);
        setIsLoading(false);
        unSetConfirmationDialog();
      }
    }
  };

  /*
    https://zellwk.com/blog/css-values-in-js/
    https://attacomsian.com/blog/javascript-get-css-styles
  */
  const adjustBackdropZIndex = () => {
    const modalElement = document.querySelector(".modal.entity-delete-dialog");
    const modalBackdropElement: any = document.querySelector(
      ".modal-backdrop.entity-delete-dialog-backdrop"
    );

    if (modalElement) {
      const modalElementStyles = getComputedStyle(modalElement);
      const modalElementZIndex = modalElementStyles.getPropertyValue("z-index");

      if (modalBackdropElement) {
        modalBackdropElement.style["z-index"] = Number(modalElementZIndex);
      }
    }
  };

  const hideHandler = () => unSetConfirmationDialog();

  // https://react-bootstrap.github.io/components/modal/
  return (
    <Modal
      show={!!confirmationDialog}
      onShow={adjustBackdropZIndex}
      onHide={hideHandler}
      centered
      backdrop="static"
      className="entity-delete-dialog"
      backdropClassName="entity-delete-dialog-backdrop"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading ? (
          <>
            <span>{body}</span>
            {extraContent}
          </>
        ) : (
          <span>{loading || <FormattedMessage id="COMMON.LOADING" />}</span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={hideHandler} className="btn btn-light btn-elevate">
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <button
            type="button"
            onClick={executeAction}
            data-cy="button-delete-item-confirm"
            className="btn btn-delete btn-danger btn-elevate ml-1"
            disabled={isLoading}
          >
            {actionButtonLabel || <FormattedMessage id="COMMON.ACTION.YES" />}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
