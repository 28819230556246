import { IImmotoolEntity } from "./ImmotoolEntity";
import { IAddress } from "./Address";
import { IFile } from "./File";
import { IUser } from "./User";
import { IProduct } from "./Product";
import { IProject } from "./Project";

export enum LeadStatus {
  "-" = "-",
  LOST = "LOST",
  OPEN = "OPEN",
  QUALIFIED = "QUALIFIED",
  WON = "WON",
}

export interface ILead extends IImmotoolEntity {
  productId: string;
  projectId: string;
  name: string;
  status: LeadStatus;
  tasksDone: string[];
  notes: string;
  invoiceAddressType: string;
  invoiceAddress: { name: string; address: IAddress };
  sellingPrice: number;
  shareOfLandSellingPrice: number;
  architectEngineeringFees: number;
  eligibleReducedVat: boolean;
  authorisationCode?: string;
  authorisationDate?: Date;
  constructionPriceWithReducedVat?: number;
  usedConstructionPriceWithReducedVat?: number;
  files?: ILeadFile[];
  users: IUser[];
  product: IProduct;
  project: IProject;
}

export interface ILeadFile extends IFile {
  leadId: string;
  leadInvoiceId?: string;
  invoiceStatus?: string;
  dueDate?: Date | null;
  amount?: number;
  amountPaidToDate?: number;
  authorisationCode?: string;
}

export interface ILeadInvoice {
  leadId: string;
  projectId: string;
  title: string;
  dueDate: Date | null;
  status: string;
  isConfidential: boolean;
  from: { name: string; address: IAddress; email: string; mobile: string };
  to: { name: string; address: IAddress };
  product: { projectName: string; productName: string; address: IAddress };
  tax: number;
  content: ILeadInvoiceLine[];
  bankAccounts: string[];
  authorisationCode: string;
  usedConstructionPriceWithReducedVat?: number;
}

export interface ILeadInvoiceLine {
  id: string;
  budgetInstalmentId: string;
  label: string;
  amount: number;
  vat: number;
}
