import { masterEmailsRestriction } from "../../../_utils/authUtils";
import { Auth } from "aws-amplify";
import { API } from "@aws-amplify/api";
import Axios from "axios";
import { encode } from "base64-arraybuffer";
import { CLIENT, SUBCONTRACTOR, USER } from "../../../_utils/userTypes";
import { formatDataSelect } from "../../../_utils/formatDataUtils";

export async function updateSignature(signatureId, code) {
  return await API.post("API", `/signatures/${signatureId}`, { body: { code: code } });
}

export async function downloadPdfFile(userId, fileId) {
  const file = await API.get("API", `/users/${userId}/files/${fileId}`, {});

  try {
    const response = await Axios({
      url: file.url,
      method: "GET",
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "*",
      },
    });

    const fileBase64 = encode(response.data);
    return "data:application/pdf;base64," + fileBase64;
  } catch (err) {
    console.error("Failed to download file", err);
  }
}

export async function downloadFileBase64(signedUrl) {
  try {
    const response = await Axios({
      url: signedUrl,
      method: "GET",
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "*",
      },
    });

    const fileBase64 = encode(response.data);
    return "data:application/pdf;base64," + fileBase64;
  } catch (err) {
    console.error("Failed to download file", err);
  }
}

// USER (CLIENT)
export async function create(user) {
  if (masterEmailsRestriction.includes(user.email)) {
    const error = { code: "MasterActionNotAllowed" };
    throw error;
  }
  user.profilePictureSetup = false;
  return await API.post("API", "/users", { body: user });
}

export async function getByUserType(queryParams, intl) {
  const response = {
    items: [],
  };
  if (!!queryParams?.leadId && !!intl) {
    response.items = await API.get("API", `/leads/${queryParams.leadId}/users`, {});
  } else {
    response.items = await API.get("API", "/users", {
      queryStringParameters: {
        userTypes: queryParams?.userTypes,
      },
    });
  }

  if (queryParams.userTypes && !!intl) {
    const respClients = [];
    const respUsers = [];
    const respSubcontractors = [];

    for (const user of response.items) {
      if (user.userTypes.includes(USER)) {
        respUsers.push(user);
      } else if (user.userTypes.includes(CLIENT)) {
        respClients.push(user);
      } else if (user.userTypes.includes(SUBCONTRACTOR)) {
        respSubcontractors.push(user);
      }
    }

    return formatDataSelect(
      {
        users: respUsers,
        clients: respClients,
        subcontractors: respSubcontractors,
      },
      intl
    );
  }
  return response;
}

export async function checkEmailValidity(id, email) {
  return await API.get("API", `/users/${id}/checkEmailValidity`, {
    queryStringParameters: {
      email,
    },
  });
}

export async function getById(id, userTypes) {
  return await API.get("API", `/users/${id}`, {
    queryStringParameters: {
      userTypes,
    },
  });
}

export async function getAll() {
  return await API.get("API", "/users", {});
}

//returns user data associated to the the current session (token)
export async function getMeByToken() {
  return await API.get("API", "/users/me", {});
}

export async function createOrResetAccess(id, notifyMethods) {
  return await API.post("API", `/users/${id}/access`, { body: { notifyMethods } });
}

export async function removeAccess(id) {
  return await API.del("API", `/users/${id}/access`, {});
}

export async function findById(id) {
  console.log("find by user Id, id:", id);
  return await API.get("API", `/users/${id}`, {});
}

export async function update(newUser) {
  if (masterEmailsRestriction.includes(newUser.email)) {
    const err = { code: "MasterActionNotAllowed" };
    throw err;
  }
  const user = { ...newUser };

  user.updatedAt = new Date();
  user.updatedBy = (await Auth.currentSession()).accessToken.payload.username;
  return await API.post("API", `/users/${user.id}`, { body: user });
}

export async function updateUserRights(userId, userRights) {
  return await API.post("API", `/users/${userId}/rights`, { body: userRights });
}

export async function deleteById(id) {
  return await API.del("API", `/users/${id}`, {});
}

// FILES

export const createFile = async (file) => {
  const fileForCreation = { ...file };
  await addInformationFields(fileForCreation);
  console.log(fileForCreation);

  const response = await API.post("API", `/users/${fileForCreation.userId}/files`, {
    body: fileForCreation,
  });
  console.log(response);
  console.log(`${response.file.url}`);

  try {
    const fetchResponse = await fetch(response.file.url, {
      method: "PUT",
      body: fileForCreation.file,
      headers: {
        "Content-Type": "",
      },
    });
    console.log("fetchResponse:", fetchResponse);
  } catch (e) {
    console.log("axios error:");
    console.log(e);
  }
  return response;
};

async function addInformationFields(fileForEdit) {
  const { name, type: mimeType } = fileForEdit.file;
  const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(name);
  fileForEdit.fileExtension = extension;
  fileForEdit.mimeType = mimeType;
  fileForEdit.uploadedAt = new Date().toISOString();
  fileForEdit.uploadedByUserId = (await Auth.currentSession()).accessToken.payload.username;
}

export const getFile = async (file) => {
  return await API.get("API", `/users/${file.userId}/files/${file.id}`, {});
};

export const deleteFile = async (file, userType) => {
  return await API.del("API", `/users/${file.userId}/files/${file.id}`, {
    body: userType,
  });
};

export const getFileById = async (userId, fileId) => {
  return await API.get("API", `/users/${userId}/files/${fileId}`, {});
};

export async function createRequestFile(file) {
  const fileRequest = { ...file, uploadedByUserId: "NONE", status: "REQUESTED" };
  delete fileRequest.file;
  return await API.post("API", `/users/${fileRequest.userId}/files/`, {
    body: fileRequest,
  });
}

export async function uploadRequestedFile(file) {
  const requestedFileItem = { ...file };

  await addInformationFields(requestedFileItem);
  const responseUpdate = await API.post(
    "API",
    `/users/${requestedFileItem.userId}/files/${requestedFileItem.id}/meta`,
    {
      body: requestedFileItem,
    }
  );

  try {
    await fetch(responseUpdate.file.url, {
      method: "PUT",
      body: requestedFileItem.file,
      headers: {
        "Content-Type": "",
      },
    });

    return { ...responseUpdate, file: { ...responseUpdate.file, status: "UPLOADED" } };
  } catch (err) {
    console.log("failed to upload file to S3", err);
  }
}

export const updateFile = async (file, userType) => {
  return await API.post("API", `/users/${file.userId}/files/${file.id}`, {
    body: { ...file, userType },
  });
};

export async function createLeadClient(leadClient) {
  const body = { ...leadClient };
  body.createdAt = new Date().toISOString();
  body.createdByUserId = (await Auth.currentSession()).accessToken.payload.username;
  body.jti = (await Auth.currentSession()).accessToken.payload.jti;
  return await API.post("API", `/leads/${leadClient.leadId}/users`, { body });
}

export async function removeLeadClient({ id, leadId }) {
  return await API.del("API", `/leads/${leadId}/users/${id}`, {});
}

export async function createUserAction(actionRequestParams) {
  const body = { ...actionRequestParams };
  return await API.post("API", `/users/${actionRequestParams.userId}/actions`, { body: body });
}

export const setUserAvatar = async (avatar) => {
  const response = await API.post("API", `/users/${avatar.userId}/avatar`, {
    body: { userId: avatar.userId, fileName: avatar.fileName },
  });
  console.log(response);
  try {
    const fetchResponse = await fetch(response.url, {
      method: "PUT",
      body: avatar.image,
      headers: {
        "Content-Type": "",
      },
    });
    console.log("fetchResponse:", fetchResponse);
  } catch (e) {
    console.error("axios error:", e);
  }
  return response;
};

export async function createSignatureRequest(signatureRequest) {
  return await API.post("API", `/signatures`, { body: signatureRequest });
}
