import { useIntl } from "react-intl";
import { shallowEqual } from "react-redux";

import { useSubheader } from "_metronic/layout";
import { LoadingDialog } from "_metronic/_partials/controls";

import { useAppSelector } from "redux/hooks";
import { formatDisplayNameIntl } from "app/_utils/userUtils";

import { UsersUIProvider } from "./UsersUIContext";
import { UsersCard } from "./users-table/UsersCard";
import { ENTITY_TYPE_PATH } from "../../../../_utils/listUtils";

export function UsersPage({ history, action, userType }) {
  const userTypePath = ENTITY_TYPE_PATH[userType];
  const { isLoading } = useAppSelector(
    (state) => ({ isLoading: state.users.listLoading }),
    shallowEqual
  );
  const subheader = useSubheader();
  const intl = useIntl();

  const uiEvents = {
    newEntityButtonClick: () => {
      history.push(`/${userTypePath}/new`);
    },
    openEntityDetailsPage: (user) => {
      subheader.setTitle(formatDisplayNameIntl(intl, user));
      history.push(`/${userTypePath}/${user.id}`);
    },
    openCreateLeadPage: (ids) => {
      history.push(`/leads/new?c=${ids}${history.location.search.replace("?", "&")}`);
    },
    openTasksPage: (ids) => {
      history.push(`/tasks`);
    },
  };

  return (
    <UsersUIProvider uiEvents={uiEvents} action={action} userType={userType}>
      <LoadingDialog isLoading={isLoading} />
      <UsersCard />
    </UsersUIProvider>
  );
}
