import React, { useState } from "react";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import * as actions from "../../../../_redux/usersActions";
import { FormattedMessage } from "react-intl";
import { IUser } from "data/schemas";
import { useAppSelector } from "redux/hooks";
import { IOption } from "../../../../../../_utils/interfacesUtils";
import { Notification } from "../../../../../../_components/Notification";
import { isEmpty } from "lodash-es";

interface RequestUserInformationModalProps {
  user: IUser;
  actionsLoading: boolean;
  showModal: boolean;
  setShowModal: Function;
}

export const RequestUserInformationModal: React.FC<RequestUserInformationModalProps> = ({
  user,
  actionsLoading,
  showModal,
  setShowModal,
}) => {
  const [notifyMethods, setNotifyMethods] = useState<IOption[]>([]);
  const dispatch = useDispatch();

  const { session } = useAppSelector(
    (state) => ({
      session: state.auth.session,
    }),
    shallowEqual
  );

  const sendRequest = () => {
    const { protocol, host, port } = window.location;

    const userActionParams = {
      createdByUserId: session.id,
      userId: user.id,
      actionType: "FILLOUT_PERSONAL_INFORMATION",
      messageDestinationTypes: notifyMethods.map((option) => option.value),
      url: [`${protocol}//${host}`].join(`${port}`),
    };
    dispatch(actions.createUserAction(userActionParams));
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="user-request-info-modal"
      centered
      backdrop="static"
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="user-request-info-modal-title">
          <FormattedMessage id="USER.ACTION.REQUEST.INFO" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!actionsLoading && (
          <div className="form-group row">
            <div className={"col-lg-12"}>
              <Notification setNotifyMethods={setNotifyMethods} notifyMethods={notifyMethods} />
            </div>
          </div>
        )}
        {actionsLoading && (
          <span>
            <FormattedMessage id="USER.ACTION.REQUEST.SENT" />
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setShowModal(false)}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <button
            type="button"
            data-cy="button-user-action-confirm"
            onClick={sendRequest}
            className="btn btn-primary font-weight-bold ml-1"
            disabled={actionsLoading || isEmpty(notifyMethods)}
          >
            <FormattedMessage id="COMMON.ACTION.REQUEST" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
