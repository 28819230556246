import * as React from "react";
import { useIntl } from "react-intl";
import { Select } from "../../../_metronic/_partials/controls";
import { getActivePriceIndexIdForDate, getPriceIndexById } from "app/_utils/priceIndexUtils";
import { DateUtils } from "../../_utils/DateUtils";
import { IPriceIndex } from "../../../data/schemas";
import { useFormikContext } from "formik";
import { cloneDeep } from "lodash-es";
import { ILeadFileInvoiceForm } from "../../modules/PropertiesManagement/pages/leads/lead-files/lead-file-edit-dialog/invoice/LeadFileInvoiceForm";

interface PriceIndexSelectProps {
  priceIndices: IPriceIndex[];
  basePriceIndex?: IPriceIndex;
}

export const PriceIndexSelect: React.FunctionComponent<PriceIndexSelectProps> = ({
  priceIndices,
  basePriceIndex,
}) => {
  const intl = useIntl();

  const [activePriceIndexId, setActivePriceIndexId] = React.useState<string>();
  const { values, setFieldValue, setValues } = useFormikContext<ILeadFileInvoiceForm>();

  const [disabled, setDisabled] = React.useState<boolean>(false);

  const [priceIndexOptions, setPriceIndexOptions] = React.useState<any>([]);

  React.useEffect(() => {
    if (priceIndices) {
      setActivePriceIndexId(getActivePriceIndexIdForDate(priceIndices));
    }
  }, [priceIndices]);

  React.useEffect(() => {
    if (activePriceIndexId) {
      handleValueChangePriceIndex(getPriceIndexById(priceIndices, activePriceIndexId));
    }
  }, [activePriceIndexId]);

  const handleValueChangePriceIndex = (value: any) => {
    const res: any = cloneDeep(values);
    res.priceIndex = value;
    res.priceIndexId = activePriceIndexId;
    setValues(res);
  };

  React.useEffect(() => {
    setFieldValue("priceIndex", getPriceIndexById(priceIndices, values?.priceIndexId));
  }, [values?.priceIndexId]);

  React.useEffect(() => {
    setPriceIndexOptions(getPriceIndexOptions(priceIndices));
  }, [priceIndices]);

  const getPriceIndexOptions = (priceIndices: IPriceIndex[]) => {
    const getPriceIndexOptionLabel = (priceIndex: IPriceIndex): any => {
      let optionContent: string = "";

      if (getActivePriceIndexIdForDate(priceIndices) === priceIndex.id) {
        optionContent +=
          intl.formatMessage({ id: "PRICE_INDEX.OPTION.CURRENT" })?.toUpperCase() + " | ";
      } else if (
        priceIndex.startAt &&
        new Date(priceIndex.startAt).getTime() > new Date().getTime()
      ) {
        optionContent +=
          intl.formatMessage({ id: "PRICE_INDEX.OPTION.FUTURE" })?.toUpperCase() + " | ";
      } else if (basePriceIndex?.id === priceIndex.id) {
        optionContent +=
          intl.formatMessage({ id: "PRICE_INDEX.OPTION.BASE" })?.toUpperCase() + " | ";
      }

      optionContent += `${priceIndex?.value?.toFixed(2)} | ${priceIndex?.source} | ${
        priceIndex.startAt &&
        DateUtils.format(priceIndex.startAt, intl, false, {
          year: "numeric",
          month: "numeric",
        })
      }`;

      return optionContent;
    };

    const options: any[] = priceIndices?.map(
      (priceIndex: IPriceIndex) =>
        priceIndex && (
          <option key={priceIndex.id} value={priceIndex.id}>
            {getPriceIndexOptionLabel(priceIndex)}
          </option>
        )
    );
    setDisabled(options?.length < 2);
    return options;
  };

  return (
    <div style={{ marginLeft: "-14px" }}>
      <Select
        name="priceIndexId"
        label={""}
        customFeedbackLabel={" "}
        className="mt-3 mb-3 text-wrap"
        disabled={disabled}
      >
        {priceIndexOptions}
      </Select>
    </div>
  );
};
