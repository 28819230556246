import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ILeadInvoice, IPriceIndex, IProject } from "../../../data/schemas";
import { PriceIndexSelect } from "./PriceIndexSelect";
import { getBasePriceIndex } from "../../_utils/priceIndexUtils";
import { DateUtils } from "app/_utils/DateUtils";
import {
  InformationalMessage,
  MessageType,
} from "app/_components/InformationalMessage/InformationalMessage";
import { useFormikContext } from "formik";
import { cloneDeep } from "lodash-es";

interface PriceIndexInvoiceDetailsProps {
  priceIndices: IPriceIndex[];
  project: IProject;
}

export const PriceIndexInvoiceDetails: React.FunctionComponent<PriceIndexInvoiceDetailsProps> = ({
  priceIndices,
  project,
}) => {
  const [basePriceIndex, setBasePriceIndex] = React.useState<IPriceIndex>();
  const [projectDate, setProjectDate] = React.useState<Date>();

  const intl = useIntl();
  const { values, setValues } = useFormikContext<ILeadInvoice>();

  const handleValueChangePriceIndex = (value: any) => {
    const res: any = cloneDeep(values);
    res.basePriceIndex = value;
    res.basePriceIndexId = value?.id;
    setValues(res);
  };

  React.useEffect(() => {
    const bpi = getBasePriceIndex(project);
    handleValueChangePriceIndex(bpi);
    setBasePriceIndex(bpi);
    setProjectDate(project.startDate || project?.createdAt);
  }, [project?.projectOwner]);

  return (
    <>
      <div className="row pt-1">
        <div className="col-lg-6">
          <FormattedMessage id={"PRICE_INDEX.BASE_INDEX.TITLE"} />
        </div>
        <div className={"col-lg-6"}>
          {!basePriceIndex ? (
            <InformationalMessage
              messageType={MessageType.WARNING}
              message={{
                id: "PRICE_INDEX.INFORMATIONAL_MESSAGE.NO_BASE_INDEX",
                params: {
                  projectOwnerCompany: project.projectOwner?.name,
                  projectName: project.name,
                  projectStartDate: DateUtils.format(projectDate, intl, true),
                },
              }}
            />
          ) : (
            <span>{basePriceIndex?.value?.toFixed(2)}</span>
          )}
        </div>
      </div>

      {basePriceIndex && (
        <div className="row">
          <div className="col-lg-6">
            <FormattedMessage id={"PRICE_INDEX.USED_INDEX.TITLE"} />
          </div>
          <div className="col-lg-6">
            <PriceIndexSelect priceIndices={priceIndices} basePriceIndex={basePriceIndex} />
          </div>
        </div>
      )}
    </>
  );
};
