/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";

import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";

import { ErrorBoundary } from "./app/_components/ErrorBoundary";
import App from "./app/App";

// Amplify configure
import { Amplify, Analytics, Auth } from "aws-amplify";
import { outputConfig } from "./configuration";
import { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-GB";
import fr from "date-fns/locale/fr";
import de from "date-fns/locale/de";

registerLocale("en-GB", en);
registerLocale("fr-FR", fr);
registerLocale("de-DE", de);

Amplify.configure({
  Auth: {
    identityPoolId: outputConfig.IdentityPoolId,
    region: outputConfig.Region,
    userPoolId: outputConfig.UserPoolId,
    userPoolWebClientId: outputConfig.UserPoolClientId,
  },
  API: {
    endpoints: [
      {
        name: "API",
        endpoint: outputConfig.ApiEndpoint,
        custom_header: async () => {
          try {
            return {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            };
          } catch (e) {
            return {};
          }
        },
      },
    ],
  },
  Analytics: {
    disabled: false,
    autoSessionRecord: true,
    AWSPinpoint: {
      appId: outputConfig.PinpointAppId,
      region: outputConfig.Region,
      bufferSize: 1000,
      flushInterval: 5000, // 5s
      flushSize: 100,
      resendLimit: 5,
    },
  },
});

Analytics.autoTrack("pageView", {
  enable: true,
  eventName: "pageView",
  type: "SPA",
  provider: "AWSPinpoint",
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  },
});

Analytics.autoTrack("event", {
  enable: true,
  events: ["click"],
  selectorPrefix: "data-aws-analytics-",
  provider: "AWSPinpoint",
});

const dotenv = require("dotenv");
const dotenvExpand = require("dotenv-expand");
const myEnv = dotenv.config();
dotenvExpand(myEnv);

const hostname = window?.location?.hostname || "";
if (!hostname.includes("dev") && !hostname.includes("test") && !hostname.includes("localhost")) {
  console.log = () => {};
}

if (
  !hostname.includes(".d.kodehyve.com") &&
  !hostname.includes("localhost") &&
  !hostname.includes("testpr")
) {
  ((h, o, t, j, a, r) => {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 3066196, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
}

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

ReactDOM.render(
  <ErrorBoundary>
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <App store={store} persistor={persistor} basename={PUBLIC_URL} />
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
