import { useDidMount, useWillUnmount } from "rooks";

const canvas = document.createElement("canvas");
canvas.style.width = "0px";
canvas.style.height = "0px";
canvas.style.visibility = "hidden";

/**
 * Use canvas to get displayed text width using the same font props used.
 * Example for font: "13px Roboto", "500 1rem Roboto"
 * This will not work if the em unit is used, as the canvas is added to the body, not the container where the measured text is located.
 */
export const useGetTextWidth = () => {
  useDidMount(() => document.body.appendChild(canvas));

  useWillUnmount(() => canvas.remove());

  const getTextWidth = (text: string, font?: string) => {
    const context = canvas.getContext("2d");
    if (context) {
      context.font = font || getComputedStyle(document.body).font;
      return context.measureText(text).width;
    }
    return 0;
  };
  return getTextWidth;
};
