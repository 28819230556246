import { useMediaMatch } from "rooks";
import { KTUtil } from "../../_metronic/_assets/js/components/util";

export enum Breakpoints {
  "SM" = "sm",
  "MD" = "md",
  "LG" = "lg",
  "XL" = "xl",
}

type TBreakpoints = `${Breakpoints}`;

export const useBreakpointChange = () => {
  const breakpoints: Record<TBreakpoints, boolean> = {} as Record<TBreakpoints, boolean>;
  breakpoints[Breakpoints.SM] = useMediaMatch(
    `(min-width:${KTUtil.getBreakpoint(Breakpoints.SM)}px) and (max-width:${
      KTUtil.getBreakpoint(Breakpoints.MD) - 1
    }px)`
  );
  breakpoints[Breakpoints.MD] = useMediaMatch(
    `(min-width:${KTUtil.getBreakpoint(Breakpoints.MD)}px) and (max-width:${
      KTUtil.getBreakpoint(Breakpoints.LG) - 1
    }px)`
  );
  breakpoints[Breakpoints.LG] = useMediaMatch(
    `(min-width:${KTUtil.getBreakpoint(Breakpoints.LG)}px) and (max-width:${
      KTUtil.getBreakpoint(Breakpoints.XL) - 1
    }px)`
  );
  breakpoints[Breakpoints.XL] = useMediaMatch(
    `(min-width:${KTUtil.getBreakpoint(Breakpoints.XL)}px)`
  );
  return Object.keys(breakpoints).find((key) => breakpoints[key as TBreakpoints]);
};
