import React from "react";
import Dropzone from "react-dropzone";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { getIconFilePath } from "../_utils/fileUtils";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export function FileDropzone({
  values,
  propsSetFieldValue,
  selectedFile,
  setSelectedFile,
  textField = "",
  inputName = "file",
}) {
  const fileChanged = (e) => (values, setFieldValue) => {
    const file =
      e.dataTransfer && e.dataTransfer.files
        ? e.dataTransfer.files[0]
        : e.target && e.target.files
        ? e.target.files[0]
        : null;
    if (file) {
      if (!values.friendlyName && file.name && values.friendlyName !== file.name) {
        setFieldValue(
          "friendlyName",
          file.name.substring(0, file.name.lastIndexOf(".") || file.name.length)
        );
      }
      setSelectedFile(file);
    }
  };

  return (
    <Dropzone>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({ onDrop: (e) => fileChanged(e)(values, propsSetFieldValue) })}
          className={"upload-file-container"}
          data-cy="drag-n-drop-zone"
        >
          <input
            name={inputName}
            data-cy={`${inputName}-input`}
            {...getInputProps({ onChange: (e) => fileChanged(e)(values, propsSetFieldValue) })}
          />
          {selectedFile ? (
            <>
              <div className={"d-flex align-items-center"}>
                <span className="svg-icon svg-icon-3x mr-4">
                  <SVG src={getIconFilePath(selectedFile.type)} />
                </span>
                <div className={"font-weight-bold"}>{selectedFile.name}</div>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedFile();
                  }}
                  className="btn btn-icon btn-text-danger btn-light-danger btn-hover-danger btn-sm ml-10"
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}></SVG>
                  </span>
                </button>
              </div>
              <div>
                <button type="button" className="btn btn-outline-primary">
                  <FormattedMessage id="FILE.ACTION.BROWSE" />
                </button>
              </div>
            </>
          ) : (
            <>
              {!!textField && (
                <div className="font-weight-bold">
                  <FormattedMessage id={textField} />
                </div>
              )}{" "}
              <div>
                <FormattedMessage id="FILE.ACTION.DRAG_AND_DROP" />
              </div>
              <div>
                <i>
                  <FormattedMessage id="COMMON.OR" />
                </i>
              </div>
              <div>
                <button type="button" className="btn btn-outline-primary">
                  <FormattedMessage id="FILE.ACTION.BROWSE" />
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Dropzone>
  );
}
