/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import { initialFilter } from "./ProjectFilesUIHelper";
import { useFilesContext } from "../../../../File/FilesProvider";
import { useSelector } from "react-redux";
import { canReadAllDocument } from "../../../../../_utils/authUtils";

const ProjectFilesUIContext = createContext();

export function useProjectFilesUIContext() {
  return useContext(ProjectFilesUIContext);
}

export const ProjectFilesUIConsumer = ProjectFilesUIContext.Consumer;

export function ProjectFilesUIProvider({ currentProjectId, children, readOnly, context }) {
  const filesContext = useFilesContext();

  const [projectId, setProjectId] = useState(currentProjectId);
  const [filters, setFilters] = useState(initialFilter);

  const { groups, session } = useSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
  }));

  const initFile = {
    id: undefined,
    friendlyName: "",
    fileType: "OTHER",
    projectId,
    isConfidential: canReadAllDocument(groups, session, "PROJECT"),
  };

  useEffect(() => {
    initFile.projectId = currentProjectId;
    setProjectId(currentProjectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectId]);

  const value = {
    projectId,
    setProjectId,
    filters,
    setFilters,
    initFile,
    readOnly,
    context,
    ...filesContext,
  };

  return <ProjectFilesUIContext.Provider value={value}>{children}</ProjectFilesUIContext.Provider>;
}
