import { IImmotoolEntity } from "./ImmotoolEntity";
import { ICompanyBasicInformation } from "./Company";
import { IAddress } from "./Address";
import { roles } from "../../app/_utils/rolesUtils";
import { IFile } from "./File";
import { FinanceFileTypes } from "./Project";

export enum UserType {
  CLIENT = "CLIENT",
  USER = "USER",
  SUBCONTRACTOR = "SUBCONTRACTOR",
}

export type TUserTypes = `${UserType}`;

// roles that will always be present
export enum DefaultUserRoleType {
  ADMIN = "ADMIN",
  MASTER = "MASTER",
  NONE = "NONE",
  CUSTOM = "CUSTOM",
}

export type TUserRoleTypes = keyof typeof roles;

export enum UserEntityType {
  ALL = "*",

  PROJECT = "PROJECT",
  BUDGET = "BUDGET",
  PRODUCT = "PRODUCT",
  USER = "USER",
  CLIENT = "CLIENT",
  SUBCONTRACTOR = "SUBCONTRACTOR",
  LEAD = "LEAD",
  TASK = "TASK",
  CHAT = "CHAT",
  USER_ACTION = "USER_ACTION",
  USER_RIGHTS = "USER_RIGHTS",
  PROPCO = "PROPCO",
}

export type TUserEntityTypes = `${UserEntityType}`;

export enum UserRightType {
  ALL = "*",

  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  AUDIT_TRAIL = "AUDIT_TRAIL",
  SHARE = "SHARE",
  ANALYSIS = "ANALYSIS",
  HIDDEN_LINE = "HIDDEN_LINE",
  NOTE = "NOTE",
  UPLOAD_PHOTO = "UPLOAD_PHOTO",
  UPDATE_PHOTO = "UPDATE_PHOTO",
  DELETE_PHOTO = "DELETE_PHOTO",
  CONFIDENTIAL_DOCUMENT = "CONFIDENTIAL_DOCUMENT",
}

export type TUserRightTypes = `${UserRightType}`;

export interface IUserRight {
  entityType: TUserEntityTypes;
  rightType: TUserRightTypes;
}

// represents the User entity properties sent to the frontend
// cdk/immootool-layers/src/commonLayer/nodejs/entities/users.ts
export interface IUser extends IImmotoolEntity {
  lastSeenAt: Date;
  legalEntityType: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  profilePictureSetup: boolean;
  profilePictureUrl: string;
  profilePictureUrlExpires: Date;
  profilePictureKey: string;
  isDisabled: boolean;
  address: IAddress;
  notes: string;
  userTypes: UserType[];
  language: string;
  gdprApprovedAt: Date;
  referrer: string;
  company: ICompanyBasicInformation;
  rights: IUserRight[];
}

export interface IUserFile extends IFile {
  userId: string;
  userType: UserType;
}

export interface ISubcontractorFile extends IUserFile {
  projectId: string;
  categories: ISubcontractorFileCategory[];
  visible: boolean;
  fileType: FinanceFileTypes | string;
  selectedForFinance: boolean;
}

export interface ISubcontractorFileCategory {
  budgetLineCategoryId: string;
  budgetLineCategoryLabel: string;
  lines: ISubcontractorFileLine[];
}

export interface ISubcontractorFileLine {
  id: string;
  budgetLineId: string;
  budgetLineLabel: string;
  vat: number;
  grossAmount: number;
  amount: number;
  discount?: number;
  discountType?: "absolute" | "percentage";
}
