import { FormattedMessage, IntlShape } from "react-intl";

import { DateUtils } from "app/_utils/DateUtils";
import { ColumnFormatter } from "react-bootstrap-table-next";

interface FormatExtraDataProps {
  openUploadFileDialog: Function;
  readOnly: boolean;
  userId: string;
  intl: IntlShape;
}

interface RowProps {
  userId: string;
  status: string;
  signatures: any;
}

export const UploadedDateTimeColumnFormatter: ColumnFormatter<
  RowProps,
  FormatExtraDataProps,
  string
> = (cell, row, rowIndex, formatExtraData) => {
  if (formatExtraData) {
    const { openUploadFileDialog, readOnly, userId, intl } = formatExtraData;
    switch (row?.status) {
      case "REQUESTED_BY_CLIENT":
        return (
          <span>
            <FormattedMessage id="FILE.TITLE.UPLOAD_COMPLETION" />
          </span>
        );
      case "REQUESTED":
        return (
          <span onClick={(e) => e.stopPropagation()}>
            {row.userId !== userId && readOnly ? (
              <span>
                <FormattedMessage id="FILE.ACTION.UPLOAD_AWAITING" />
              </span>
            ) : (
              <button
                data-cy="button-file-upload-now"
                onClick={() => openUploadFileDialog(row)}
                type={"button"}
                className={"btn btn-sm btn-primary"}
              >
                <FormattedMessage id="FILE.ACTION.UPLOAD_NOW" />
              </button>
            )}
          </span>
        );
      default:
        let signature: any;
        let signatureSigned: any;
        if (row.signatures) {
          signature = row.signatures
            .filter(
              (s: { assigneeId: string; status: string }) =>
                s.assigneeId === userId && s.status !== "SIGNED"
            )
            .sort((s1: { createdAt: any }, s2: { createdAt: any }) => s2.createdAt - s1.createdAt);
          signatureSigned = row.signatures
            .filter((s: { signedByUserId: string; status: string }) => s.status === "SIGNED")
            .sort((s1: any, s2: any) => s2.createdAt - s1.createdAt);
        }
        return (
          <span>
            {cell ? DateUtils.format(new Date(cell), intl) : "-"}
            {signature && signature.length > 0 && (
              <span className={"ml-4"} onClick={(e) => e.stopPropagation()}>
                <button
                  onClick={() => window.open(`/sign/${signature[0].id}`, "_blank")}
                  type={"button"}
                  className={"btn btn-sm btn-primary"}
                >
                  Sign now
                </button>
              </span>
            )}
            {signature && signatureSigned.length > 0 && (
              <>
                {signatureSigned.map((sig: any) => (
                  <div className={"italic"}>
                    {sig.signedByUserFullName ? `${sig.signedByUserFullName} - ` : ""}
                    {intl
                      .formatMessage({ id: "COMMON.SIGNED.AT" })
                      .replace("{date}", DateUtils.format(new Date(sig.signedDate), intl))}
                  </div>
                ))}
              </>
            )}
          </span>
        );
    }
  }
};
