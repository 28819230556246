/* eslint-disable no-restricted-imports */
import * as React from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import {
  DatePickerField,
  Input,
  Select,
} from "../../../../../../../../_metronic/_partials/controls";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import * as budgetActions from "../../../../../../Budget/_redux/budgetsActions";
import { NumberInput } from "../../../../../../../_utils/formUtils";
import { cloneDeep, isEqual, mergeWith, sumBy } from "lodash-es";
import * as actions from "../../../../../_redux/leads/leadsActions";
import { useLeadFilesUIContext } from "../../LeadFilesUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { formatDisplayNameIntl } from "../../../../../../../_utils/userUtils";
import { INVOICE_STATUS } from "../../../../../../../_utils/listUtils";
import { DateUtils } from "../../../../../../../_utils/DateUtils";
import { Highlighter, Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { fetchSettings } from "../../../../../../Settings/_redux/settingsActions";
import { useAppDispatch, useAppSelector } from "../../../../../../../../redux/hooks";
import { canReadAll, canReadAllDocument } from "../../../../../../../_utils/authUtils";
import { Checkbox } from "../../../../../../../_components/Checkbox";
import { v4 as uuid } from "uuid";
import { SUFFIX_EURO_CURRENCY } from "../../../../../../../_utils/suffixUtils";
import { LinkedFiles } from "../../../../../../../_components/LinkedFiles";
import { FileDropzone } from "../../../../../../../_components/FileDropzone";
import { useDidMount, useDidUpdate, useWillUnmount } from "rooks";
import { LeadInvoiceLines } from "./LeadInvoiceLines";
import { LeadInvoiceReducedVatInfo } from "./LeadInvoiceReducedVatInfo";
import {
  IBankAccount,
  ILeadFile,
  ILeadInvoice,
  ILeadInvoiceLine,
  IPriceIndex,
} from "../../../../../../../../data/schemas";
import { NumberFormatValues } from "react-number-format";
import { PickPartial } from "../../../../../../../_utils/typesUtils";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { LeadInvoiceFormObserver } from "./LeadInvoiceFormObserver";
import { PriceIndexInvoiceDetails } from "../../../../../../../_components/PriceIndex/PriceIndexInvoiceDetails";
import { InvoicePriceDetails } from "../../../../../../../_components/InvoicePriceDetails/InvoicePriceDetails";
import { getCompanyAvailablePricesIndices } from "../../../../../../../_utils/priceIndexUtils";

export interface ILeadInvoiceLineForm extends ILeadInvoiceLine {
  hasMixVat?: boolean;
}

export interface ILeadFileInvoiceForm extends ILeadFile {
  content: ILeadInvoiceLineForm[];
  bankAccounts: IBankAccount[];
  fillInvoiceInformation: boolean;
  priceIndex: IPriceIndex;
  basePriceIndex: IPriceIndex;
  priceIndexId: string;
  basePriceIndexId: string;
}

export type ILeadFileInvoiceFormRes = Omit<ILeadInvoice, "from" | "title" | "tax"> &
  Pick<ILeadFile, "amount" | "amountPaidToDate"> &
  PickPartial<ILeadFile, "relatedEntity">;

interface LeadFileInvoiceFormProps {
  file: ILeadFile;
}

export const LeadFileInvoiceForm: React.FC<LeadFileInvoiceFormProps> = ({ file }) => {
  const intl = useIntl();
  const {
    showEditFileDialog,
    closeEditFileDialog,
    newFile,
    setFormSubmitRef,
    resetFormSubmitRef,
    setIsSubmitDisabled,
    setIsInvoice,
    setSubmitButtonLabel,
    setHeaderLabel,
  } = useLeadFilesUIContext();
  const dispatch = useAppDispatch();

  const { actionsLoading, lead, project, budget, session, groups, settings } = useAppSelector(
    (state) => ({
      lead: state.leads?.leadForEdit?.saved!,
      actionsLoading: state.leads?.actionsLoading,
      project:
        state.projects?.projectForEdit?.saved?.id === state.leads?.leadForEdit?.saved?.projectId
          ? state.projects?.projectForEdit?.saved
          : state?.projects?.entities?.find(
              (project) => project.id === state.leads?.leadForEdit?.saved?.projectId
            ),
      budget: state.budgets?.budgetForEdit?.saved,
      session: state.auth.session,
      groups: state.auth.groups,
      settings: state.settings?.settingsForEdit?.saved,
    }),
    shallowEqual
  );

  const [priceIndices, setPriceIndices] = useState([]);

  useEffect(() => {
    setPriceIndices(getCompanyAvailablePricesIndices(project?.projectOwner, true));
  }, [project?.projectOwner]);

  const invoiceInit = {
    friendlyName: undefined,
    invoiceStatus: "TO_BE_PAID",
    dueDate: undefined,
    amountPaidToDate: 0,
    isConfidential: canReadAllDocument(groups, session, "LEAD"),
    content: [
      {
        id: uuid(),
        budgetInstalmentId: undefined,
        label: "",
        amount: 0,
        vat: 0,
      },
    ],
    bankAccounts: [],
    fillInvoiceInformation: true,
    priceIndex: undefined,
    basePriceIndex: undefined,
    priceIndexId: undefined,
    basePriceIndexId: undefined,
  };

  const mergeWithInitInvoice = (obj: any) => {
    return mergeWith(cloneDeep(invoiceInit), obj, (dest, src) => (src === null ? dest : undefined));
  };

  const [initialValues, setInitialValues] = React.useState<ILeadFileInvoiceForm>(
    file as ILeadFileInvoiceForm
  );
  const [selectedFile, setSelectedFile] = React.useState(null);

  // Validation schema
  const baseSchema = {
    friendlyName: Yup.string().min(2).max(250).required().label("INVOICE.TITLE.TITLE"),
    invoiceStatus: Yup.string(),
    amount: Yup.number(),
    amountPaidToDate: Yup.number().nullable(true),
  };
  let newFileSchema = {};
  if (newFile) {
    newFileSchema = {
      fillInvoiceInformation: Yup.boolean(),
      content: Yup.array().of(
        Yup.object().shape({
          budgetInstalmentId: Yup.string(),
          label: Yup.string(),
          amount: Yup.number(),
          vat: Yup.number(),
        })
      ),
    };
  }
  let InvoiceSchema = Yup.object().shape({ ...baseSchema, ...newFileSchema });

  const formRef = React.useRef<FormikProps<ILeadFileInvoiceForm>>(null);

  useDidMount(() => {
    if (!setFormSubmitRef) return;
    setFormSubmitRef(() => {
      const form = formRef.current;
      if (form) {
        form.submitForm();
      }
    });
    if (newFile) {
      setHeaderLabel("INVOICE.ACTION.CREATE.NEW");
      setSubmitButtonLabel("INVOICE.ACTION.CREATE.NEW");
    }
  });

  useWillUnmount(() => {
    resetFormSubmitRef();
    setIsInvoice(false);
    setSubmitButtonLabel();
    setHeaderLabel();
  });

  useDidUpdate(() => {
    if (isEqual(initialValues, file)) {
      setInitialValues(
        mergeWithInitInvoice({
          ...file,
          friendlyName: "INVOICE #" + DateUtils.format(new Date(), intl),
        })
      );
    }
  }, [showEditFileDialog, file]);

  const [currentConstructionPriceWithReducedVat, setCurrentConstructionPriceWithReducedVat] =
    React.useState<number>();

  React.useEffect(() => {
    setCurrentConstructionPriceWithReducedVat(lead.usedConstructionPriceWithReducedVat);
  }, [lead.usedConstructionPriceWithReducedVat]);

  React.useEffect(() => {
    if (!settings) {
      fetchSettings(dispatch);
    }
  }, [settings]);

  React.useEffect(() => {
    if (
      project?.selectedBudget &&
      (!budget || budget.projectId !== project?.id) &&
      canReadAll(groups, session, "BUDGET")
    ) {
      dispatch(budgetActions.fetchBudget(project?.selectedBudget));
    }
  }, [project]);

  const handleValueChange = <T extends keyof ILeadFileInvoiceForm>(
    field: T,
    values: ILeadFileInvoiceForm,
    value: ILeadFileInvoiceForm[T],
    setFieldValue: FormikHelpers<ILeadFileInvoiceForm>["setFieldValue"]
  ) => {
    const res = cloneDeep(values);
    res[field] = value;
    setFieldValue(field, res[field]);
  };

  const formatContent = (values: ILeadFileInvoiceForm) => {
    let additionalLine: { line: ILeadInvoiceLineForm; index: number } | undefined;
    const content = values.content.map((line, index: number) => {
      if (line.hasMixVat) {
        const defaultVatPart = Math.abs(
          lead.constructionPriceWithReducedVat! - currentConstructionPriceWithReducedVat!
        );

        additionalLine = {
          line: {
            ...line,
            vat: settings?.defaultVat ?? 0,
            amount: defaultVatPart,
          },
          index: index + 1,
        };
        return {
          ...line,
          vat: settings?.reducedVat ?? 0,
          amount: line.amount - defaultVatPart,
        };
      }
      return {
        ...line,
        vat: line.vat / 100,
      };
    });
    if (additionalLine) {
      content.splice(additionalLine.index, 0, additionalLine.line);
    }
    return content;
  };

  const formatInvoice = (values: ILeadFileInvoiceForm) => {
    const res: Partial<ILeadFileInvoiceFormRes> = {
      ...values,
      leadId: lead.id!,
      relatedEntity: { name: lead.name } as ILeadFile["relatedEntity"],
      projectId: project?.id!,
      content: formatContent(values),
      product: {
        projectName: project?.name!,
        productName: lead.product?.name,
        address: lead.product?.address,
      },
      bankAccounts: selectedBankAccounts.map((account) => account.id),
      amount: sumBy(values.content, "amount"),
      amountPaidToDate:
        values.invoiceStatus === "PARTIALLY_PAID" ? values.amountPaidToDate : undefined,
      authorisationCode: lead.eligibleReducedVat ? lead.authorisationCode! : "",
    };
    const clients = lead.users?.map((user) => formatDisplayNameIntl(intl, user));
    if (lead.invoiceAddressType === "CUSTOM") {
      res.to = {
        name: lead.invoiceAddress?.name + "\n" + clients?.join("\n"),
        address: lead.invoiceAddress?.address ?? {},
      };
    } else {
      const clientAddress =
        lead.users?.find((user) => user.id === lead.invoiceAddressType)?.address ?? {};
      res.to = {
        name: clients?.join(",\n") ?? "",
        address: clientAddress,
      };
    }
    return res;
  };

  const saveInvoice = (values: ILeadFileInvoiceForm) => {
    if (!newFile) {
      dispatch(actions.updateFile(values)).then(() => {
        closeEditFileDialog();
      });
    } else {
      if (!values.fillInvoiceInformation) {
        const { leadId, isConfidential, friendlyName } = values;
        const leadFile = {
          leadId,
          isConfidential,
          friendlyName,
          fileType: "INVOICE",
          file: selectedFile,
          relatedEntity: { name: lead.name },
        };
        dispatch(actions.uploadFile(leadFile)).then(() => {
          closeEditFileDialog();
        });
      } else {
        dispatch(
          actions.createInvoice({
            ...formatInvoice(values),
            file: selectedFile,
            language: intl.locale,
          })
        ).then(async () => {
          await dispatch(
            actions.updateLeadField(
              "usedConstructionPriceWithReducedVat",
              currentConstructionPriceWithReducedVat! > lead.constructionPriceWithReducedVat!
                ? lead.constructionPriceWithReducedVat
                : currentConstructionPriceWithReducedVat
            )
          );
          closeEditFileDialog();
        });
      }
    }
  };

  const [selectedBankAccounts, setSelectedBankAccounts] = React.useState<IBankAccount[]>([]);
  const updateSelectedBankAccounts = (bankAccounts: IBankAccount[]) => {
    setSelectedBankAccounts(bankAccounts);
  };

  const reducedVatAvailable = React.useMemo(
    () =>
      !!(
        lead?.eligibleReducedVat &&
        lead?.authorisationCode &&
        lead?.authorisationDate &&
        new Date(lead.authorisationDate) < new Date()
      ),
    [lead]
  );

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={InvoiceSchema}
      onSubmit={saveInvoice}
    >
      {({ setFieldValue, values }) => (
        <Modal.Body>
          <Form className="form form-label-right">
            <LeadInvoiceFormObserver
              {...{ selectedFile, newFile, actionsLoading, setIsSubmitDisabled }}
            />
            {newFile && (
              <div className="row mb-2">
                <div className="col-12">
                  <Checkbox
                    name={"fillInvoiceInformation"}
                    label={"INVOICE.FILL_IN_INVOICE_INFORMATION"}
                  />
                </div>
              </div>
            )}
            <div className="row mb-4">
              <div className="col-12">
                <Field
                  name="friendlyName"
                  data-cy="title-invoice-form"
                  component={Input}
                  placeholder={intl.formatMessage({
                    id: "INVOICE.TITLE.TITLE",
                  })}
                  label={intl.formatMessage({
                    id: "INVOICE.TITLE.TITLE",
                  })}
                />
              </div>
            </div>
            {((!newFile && values.invoiceStatus) || values.fillInvoiceInformation) && (
              <div className="row mb-4">
                <div className="col-sm-4">
                  <Select
                    name="invoiceStatus"
                    label={intl.formatMessage({
                      id: "COMMON.STATUS",
                    })}
                    customFeedbackLabel={""}
                  >
                    {Object.entries(INVOICE_STATUS).map(([key, val]) => (
                      <option key={key} value={key}>
                        {intl.formatMessage({ id: val })}
                      </option>
                    ))}
                  </Select>
                </div>
                {values?.invoiceStatus !== "PAID" && (
                  <div className="col-sm-4">
                    <label>
                      <FormattedMessage id="TASK.LABEL.DATE.DUE" />
                    </label>
                    <div className={"position-relative"}>
                      <Field
                        name="dueDate"
                        showTimeSelect={false}
                        placeholderText={DateUtils.format(new Date(), intl, false)}
                        component={DatePickerField}
                        withFeedbackLabel={false}
                        dateFormat={"dd/MM/yyyy"}
                        onChange={(e: Date) => {
                          const x = new Date().getTimezoneOffset() * 60000;
                          const localISOTime = new Date(e.getTime() - x).toISOString().slice(0, -1);
                          return setFieldValue("dueDate", localISOTime);
                        }}
                      />
                      {values.dueDate && (
                        <span
                          className="position-absolute right-0 mr-4 cursor-pointer"
                          style={{ top: "50%", transform: "translateY(-50%)" }}
                          onClick={() => {
                            setFieldValue("dueDate", "");
                          }}
                        >
                          <i className="fas fa-times" />
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {values?.invoiceStatus === "PARTIALLY_PAID" && (
                  <div className="col-sm-4">
                    <label>
                      <FormattedMessage id="TASK.LABEL.PAID_TO_DATE" />
                    </label>
                    <NumberInput
                      suffix={SUFFIX_EURO_CURRENCY}
                      name="amountPaidToDate"
                      className="form-control text-right"
                      value={values.amountPaidToDate}
                      onValueChange={(e: NumberFormatValues) => {
                        handleValueChange(
                          "amountPaidToDate",
                          values,
                          e.floatValue ?? 0,
                          setFieldValue
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            {values.fillInvoiceInformation && newFile && (
              <div className={"form-group form-row"}>
                <div className={"col-12"}>
                  <label>
                    <FormattedMessage id={"SETTING.BANK.ACCOUNT"} />
                  </label>
                  <Typeahead
                    id="invoice-bank-accounts-typeahead-input"
                    paginate={false}
                    multiple
                    positionFixed
                    labelKey={"name"}
                    filterBy={["name", "IBAN", "BIC"]}
                    selected={selectedBankAccounts}
                    onChange={(selected) => {
                      updateSelectedBankAccounts(selected);
                    }}
                    options={settings?.bankAccounts ?? []}
                    placeholder={intl.formatMessage({ id: "COMMON.SELECT_BANK_ACCOUNT" })}
                    disabled={actionsLoading}
                    className={"flex-grow-1"}
                    renderMenu={(accounts, menuProps, state) => (
                      <Menu {...menuProps}>
                        {accounts.map((account, index) => (
                          <MenuItem option={account} position={index} key={index}>
                            <span className={"font-weight-bold"}>
                              <Highlighter
                                search={state?.text ?? ""}
                                highlightClassName={"bg-light-primary p-0"}
                              >
                                {account.name}
                              </Highlighter>
                            </span>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  />
                </div>
              </div>
            )}
            {values.fillInvoiceInformation &&
              newFile &&
              (!settings ? (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
                </div>
              ) : (
                <>
                  {reducedVatAvailable && (
                    <LeadInvoiceReducedVatInfo
                      {...{
                        settings,
                        lead,
                        currentConstructionPriceWithReducedVat,
                      }}
                    />
                  )}
                  {project && (
                    <PriceIndexInvoiceDetails priceIndices={priceIndices} project={project} />
                  )}
                  <hr />
                  <div className="mb-4">
                    <InvoicePriceDetails />
                  </div>
                  <LeadInvoiceLines
                    {...{
                      settings,
                      lead,
                      budget,
                      project,
                      currentConstructionPriceWithReducedVat,
                      setCurrentConstructionPriceWithReducedVat,
                      reducedVatAvailable,
                    }}
                  />
                </>
              ))}
            {values.fillInvoiceInformation && newFile && (
              <div className={"mb-4 mt-4"}>
                <span className="svg-icon svg-icon-lg svg-icon-info mr-2">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
                </span>
                <FormattedMessage id={"INVOICE.CUSTOM_FILE_OR_GENERATED"} />
              </div>
            )}

            {newFile && (
              <div className="form-group row">
                <div className="col-lg-12">
                  {actionsLoading && !!selectedFile ? (
                    <div className="upload-file-container upload-file-container--disabled">
                      <div className="d-flex justify-content-center align-middle">
                        <div className="spinner-grow text-primary mr-4" />
                        <div>
                          <FormattedMessage id="FILE.ACTION.UPLOADING" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <FileDropzone
                      values={values}
                      propsSetFieldValue={setFieldValue}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                    />
                  )}
                </div>
              </div>
            )}

            {canReadAllDocument(groups, session, "LEAD") && (
              <Checkbox name={"isConfidential"} label={"COMMON.CONFIDENTIAL.DOCUMENT"} />
            )}
            <div className="separator separator-solid separator-border-2 my-4" />
            <LinkedFiles activeFile={values} />
          </Form>
        </Modal.Body>
      )}
    </Formik>
  );
};
