import * as React from "react";
import { ChangeEvent } from "react";
import { Field, FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import CreatableSelect from "react-select/creatable";
import { NumberInput } from "../../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../../_utils/suffixUtils";
import { NumberFormatValues } from "react-number-format";
import { CustomCardLine } from "../../../../../../Common/CustomCard";
import { Input } from "../../../../../../../../_metronic/_partials/controls";
import {
  IBudget,
  ISubcontractorFile,
  ISubcontractorFileCategory,
  ISubcontractorFileLine,
} from "../../../../../../../../data/schemas";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactSortable } from "react-sortablejs";
import { cloneDeep } from "lodash-es";
import { useUserFilesUIContext } from "../../UserFilesUIContext";
import { DiscountInput } from "../../../../../../../_components/DiscountInput";

export interface UserFileFinanceLineProps {
  categoryIndex: number;
  category: ISubcontractorFileCategory;
  isBudgetAvailable: boolean;
  budget: IBudget;
}

export const UserFileFinanceLines: React.FC<UserFileFinanceLineProps> = ({
  categoryIndex,
  category,
  isBudgetAvailable,
  budget,
}) => {
  const intl = useIntl();
  const { setFieldValue } = useFormikContext<ISubcontractorFile>();
  const { financeFileDefaultLine } = useUserFilesUIContext();

  const addLine =
    (lineArrayHelpers: FieldArrayRenderProps) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      lineArrayHelpers.push(financeFileDefaultLine());
    };

  const changeDiscount =
    (line: ISubcontractorFileLine, categoryIndex: number, lineIndex: number) =>
    ({ floatValue }: NumberFormatValues) => {
      const discount = floatValue || 0;
      setFieldValue(`categories.${categoryIndex}.lines.${lineIndex}.discount`, floatValue || 0);

      const discountedValue =
        line.grossAmount -
        (line.discountType === "absolute" ? discount : line.grossAmount * (discount / 100));
      setFieldValue(`categories.${categoryIndex}.lines.${lineIndex}.amount`, discountedValue);
    };

  const changeGrossAmount =
    (line: ISubcontractorFileLine, categoryIndex: number, lineIndex: number) =>
    ({ floatValue }: NumberFormatValues) => {
      const grossAmount = floatValue || 0;
      setFieldValue(`categories.${categoryIndex}.lines.${lineIndex}.grossAmount`, grossAmount);

      const discountedValue =
        grossAmount -
        (line.discountType === "absolute"
          ? line.discount ?? 0
          : grossAmount * ((line.discount ?? 0) / 100));
      setFieldValue(`categories.${categoryIndex}.lines.${lineIndex}.amount`, discountedValue);
    };

  return (
    <FieldArray
      name={`categories.${categoryIndex}.lines`}
      render={(lineArrayHelpers) => (
        <>
          <ReactSortable
            list={cloneDeep(category?.lines || [])}
            setList={(sortedLines) =>
              setFieldValue(`categories.${categoryIndex}.lines`, sortedLines)
            }
            swapThreshold={0.65}
            animation={150}
            fallbackOnBody={true}
            group="nested"
            handle=".line-handle"
            scroll={true}
            bubbleScroll={true}
          >
            {category?.lines?.map((line, lineIndex) => (
              <CustomCardLine
                draggable={true}
                key={lineIndex}
                remove={() => lineArrayHelpers.remove(lineIndex)}
                removable={category.lines.length !== 1}
              >
                <div className="form-row flex-grow-1">
                  <div className="col-5 col-sm-5 d-flex align-items-center">
                    <div className="flex-grow-1">
                      {!isBudgetAvailable ? (
                        <Field
                          className="form-control form-control-sm"
                          name="line.budgetLineLabel"
                          component={Input}
                          value={line.budgetLineLabel}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(
                              `categories.${categoryIndex}.lines.${lineIndex}.budgetLineLabel`,
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <CreatableSelect
                          name="budgetLineId"
                          onChange={(selected) => {
                            setFieldValue(
                              `categories.${categoryIndex}.lines.${lineIndex}.budgetLineId`,
                              selected?.id ?? ""
                            );
                            setFieldValue(
                              `categories.${categoryIndex}.lines.${lineIndex}.budgetLineLabel`,
                              selected?.label
                            );
                          }}
                          isSearchable
                          isClearable
                          options={
                            budget?.sortedLines?.find(
                              (cat) => cat.id === category.budgetLineCategoryId
                            )?.lines || []
                          }
                          value={
                            line.budgetLineLabel
                              ? {
                                  id: line.budgetLineId,
                                  label: line.budgetLineLabel,
                                }
                              : null
                          }
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.label}
                          placeholder={intl.formatMessage({
                            id: "BUDGET.SELECT_BUDGET_LINE",
                          })}
                          classNamePrefix="creatable-select-sm"
                          menuPosition="fixed"
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              width: "100%",
                            }),
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-5 col-sm-2 d-flex">
                    <NumberInput
                      suffix={SUFFIX_EURO_CURRENCY}
                      className="form-control form-control-sm text-right"
                      value={line.grossAmount}
                      decimalScale={2}
                      onValueChange={changeGrossAmount(line, categoryIndex, lineIndex)}
                    />
                  </div>
                  <div className="col-2 col-sm-2 d-flex">
                    <DiscountInput
                      value={line.discount ?? 0}
                      onValueChange={changeDiscount(line, categoryIndex, lineIndex)}
                      onDiscountTypeChange={(discountType) =>
                        setFieldValue(
                          `categories.${categoryIndex}.lines.${lineIndex}.discountType`,
                          discountType
                        )
                      }
                    />
                  </div>
                  <div className="col-2 col-sm-2 d-flex align-items-center">
                    <NumberInput
                      displayType={"text"}
                      value={line.amount}
                      suffix={SUFFIX_EURO_CURRENCY}
                      className={"ml-auto"}
                      decimalScale={2}
                    />
                  </div>
                  <div className="col-2 col-sm-1 d-flex">
                    <NumberInput
                      className="form-control form-control-sm text-right"
                      suffix={SUFFIX_PERCENTAGE}
                      decimalScale={0}
                      value={line.vat * 100}
                      onValueChange={(e: NumberFormatValues) =>
                        setFieldValue(
                          `categories.${categoryIndex}.lines.${lineIndex}.vat`,
                          (e.floatValue ?? 0) / 100 || 0
                        )
                      }
                    />
                  </div>
                </div>
              </CustomCardLine>
            ))}
          </ReactSortable>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center  justify-content-center"
              onClick={addLine(lineArrayHelpers)}
            >
              <i className="ki ki-plus icon-nm" />
              <FormattedMessage id="BUDGET.ACTION.ADD.LINE" />
            </button>
          </div>
        </>
      )}
    />
  );
};
