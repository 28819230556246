import { IImmotoolEntity } from "./ImmotoolEntity";
import { UserType } from "./User";

export interface IRelatedEntity {
  id: string;
  type: string;
  name: string;
  userType?: UserType;
}

export enum FileStatus {
  REQUESTED = "REQUESTED",
  UPLOADED = "UPLOADED",
  UPLOADING = "UPLOADING",
  REQUESTED_BY_CLIENT = "REQUESTED_BY_CLIENT",
}

export type TFileStatus = `${FileStatus}`;

export interface IFile extends IImmotoolEntity {
  uploadedAt: Date;
  uploadedByUserId: string;
  uploadedByUser: any;
  friendlyName: string;
  fileType: string;
  isConfidential: boolean;
  fileExtension: string;
  mimeType: string;
  taskId: string;
  status: TFileStatus;
  signatures: any[];
  relatedEntity: IRelatedEntity;
  linkedFiles: ILinkedFile[];
}

export interface ILinkedFile {
  id: string;
  friendlyName: string;
  relatedEntity: IRelatedEntity;
}
