import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import { BudgetLineFileTable } from "./BudgetLineFileTable";
import { useQueryState } from "react-router-use-location-state";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { useSubheader } from "../../../../_metronic/layout";
import {
  ISFFFile,
  ISFFLine,
  SubcontractorFinanceType,
  SubcontractorFinanceTypes,
} from "../../../../data/schemas";

export interface BudgetLineDialogProps {
  show: boolean;
  lineId: string;
  categoryId: string;
  budgetId: string;
  onHide: Function;
  history: any;
}

export const BudgetLineDialog: React.FC<BudgetLineDialogProps> = ({
  show,
  categoryId,
  lineId,
  onHide,
}) => {
  const [tab, setTab] = useQueryState<SubcontractorFinanceTypes>(
    "tf",
    SubcontractorFinanceType.QUOTE
  );
  const subheader = useSubheader();

  const { budget, project } = useAppSelector(
    (state) => ({
      budget: state.budgets?.budgetForEdit?.current,
      project: state.projects?.projectForEdit?.current,
    }),
    shallowEqual
  );

  const [line, setLine] = React.useState<ISFFLine>();

  React.useEffect(() => {
    if (project) {
      for (const sffType of Object.values(SubcontractorFinanceType)) {
        const tmpLine =
          project.subcontractorsFinanceFiles?.[sffType]?.data?.[categoryId]?.lines?.[lineId];
        if (tmpLine) {
          setLine(tmpLine);
          break;
        }
      }
    }
  }, [project]);

  React.useEffect(() => {
    if (budget && line && subheader && subheader.title === budget.name) {
      subheader.setTitle(line.label);
    }
  }, [budget, line, subheader.title]);

  const [files, setFiles] = React.useState<ISFFFile[]>([]);
  React.useEffect(() => {
    setFiles(
      project?.subcontractorsFinanceFiles?.[tab].data?.[categoryId]?.lines?.[lineId]?.files ?? []
    );
  }, [project, tab]);

  return (
    <Modal show={show} onHide={() => onHide()} centered backdrop="static" size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{line?.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab(SubcontractorFinanceType.QUOTE)}>
            <div
              className={`nav-link ${
                tab === SubcontractorFinanceType.QUOTE && "active"
              } cursor-pointer`}
              data-toggle="tab"
              role="tab"
            >
              <FormattedMessage id="FINANCE.QUOTES" />
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => setTab(SubcontractorFinanceType.SUPPLEMENTARY_AGREEMENT)}
          >
            <div
              className={`nav-link ${
                tab === SubcontractorFinanceType.SUPPLEMENTARY_AGREEMENT && "active"
              } cursor-pointer`}
              data-toggle="tab"
              role="tab"
            >
              <FormattedMessage id="BUDGET.SUPPLEMENTARY_AGREEMENTS" />
            </div>
          </li>
          <li className="nav-item" onClick={() => setTab(SubcontractorFinanceType.ORDER)}>
            <div
              className={`nav-link ${
                tab === SubcontractorFinanceType.ORDER && "active"
              } cursor-pointer`}
              data-toggle="tab"
              role="tab"
            >
              <FormattedMessage id="FINANCE.ORDERS" />
            </div>
          </li>
          <li className="nav-item" onClick={() => setTab(SubcontractorFinanceType.INVOICE)}>
            <div
              className={`nav-link ${
                tab === SubcontractorFinanceType.INVOICE && "active"
              } cursor-pointer`}
              data-toggle="tab"
              role="tab"
            >
              <FormattedMessage id="FINANCE.INVOICES" />
            </div>
          </li>
        </ul>
        <div className={"mt-5"}>
          <BudgetLineFileTable files={files} budgetLineLabel={line?.label ?? ""} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={() => onHide()} className="btn btn-primary">
            <FormattedMessage id="COMMON.ACTION.CLOSE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
