import * as React from "react";
import { FormattedMessage } from "react-intl";
import { IPreDefinedRole } from "../definitions";
import { DefaultUserRoleType } from "../../../../../../../../data/schemas";

export interface UserPreSelectedRoleSelectionProps {
  preDefinedRoleSelected: string;
  preDefinedRoles: IPreDefinedRole[];

  updateSelectedRole: (role: string) => void;

  readOnly?: boolean;
  isMaster?: boolean;
}

export const UserPreSelectedRoleSelection: React.FunctionComponent<UserPreSelectedRoleSelectionProps> =
  ({
    preDefinedRoleSelected,
    preDefinedRoles,
    updateSelectedRole,
    readOnly = false,
    isMaster = false,
  }) => {
    const preDefinedRoleChanged = React.useCallback(
      (event: React.SyntheticEvent<HTMLSelectElement>) => {
        if (readOnly) return;

        updateSelectedRole(event.currentTarget.value);
      },
      [readOnly, updateSelectedRole]
    );

    const preDefinedRolesOptions = React.useMemo(
      () =>
        (isMaster || readOnly
          ? preDefinedRoles
          : preDefinedRoles.filter((role) => role.value !== DefaultUserRoleType.MASTER)
        ).map(({ value, label }) => <option {...{ key: value, value }}>{label}</option>),
      [isMaster, preDefinedRoles]
    );

    return (
      <div className="row">
        <div className="col-6 col-sm-3 pb-2">
          <label>
            <FormattedMessage id="RIGHT.USER.PREDEFINED_ROLE" />
          </label>

          <select
            data-cy="select-right-user"
            className="form-control custom-select"
            disabled={readOnly}
            value={preDefinedRoleSelected}
            onChange={preDefinedRoleChanged}
          >
            {preDefinedRolesOptions}
          </select>
        </div>
      </div>
    );
  };

export default UserPreSelectedRoleSelection;
