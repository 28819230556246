/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";

import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_metronic/layout/_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../modules/Notifications/_redux/inAppNotificationsActions";
import { useIntl } from "react-intl";
import { UserNotificationsDropdownTabPane } from "./UserNotificationsDropdownTabPane";
import { UserNotificationsDropdownTabHeaderItem } from "./UserNotificationsDropdownTabHeaderItem";

export function UserNotificationsDropdown() {
  const { session } = useSelector(
    (state) => ({
      session: state.auth.session,
    }),
    shallowEqual
  );

  const [tabPaneKey, setTabPaneKey] = useState("notifications");

  const intl = useIntl();
  let showDropdown = false;

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.notifications.layout") === "offcanvas",
    };
  }, [uiService]);

  const { notifications } = useSelector(
    (state) => ({ notifications: state.inAppNotifications.list }),
    shallowEqual
  );

  function getNotificationsForTab(notifications, tabKey) {
    return notifications.filter((n) => n.tabKey === tabKey);
  }

  function getNotificationsForUser(notifications, session) {
    return notifications.filter((n) => n.userId === session.id);
  }

  function getUnreadNotificationsCountForTab(notifications, tabKey) {
    return notifications ? notifications.filter((n) => n.tabKey === tabKey && !n.readAt).length : 0;
  }

  const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
  };

  const dispatch = useDispatch();
  const onDropdownToggle = (isOpen) => {
    showDropdown = isOpen;
    if (isOpen) {
      setTimeout(() => {
        notifications
          .filter((n) => !n.readAt)
          .forEach((notification) => {
            dispatch(actions.markNotificationAsRead(notification));
          });
      }, 1000);
    }
  };

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring" />
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown
          drop="down"
          alignRight
          onToggle={onDropdownToggle}
          data-cy="link-parent-in-app-notifications"
        >
          <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">
            <div
              className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary"
              id="kt_quick_notifications_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />*/}
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
              </span>
              {getNotificationsForUser(notifications, session)?.filter((n) => !n.readAt).length >
                0 && (
                <div>
                  <span
                    className="pulse-ring position-absolute"
                    style={{ bottom: "4px", right: "1px", top: "auto" }}
                  />
                  <span
                    className="badge badge-danger rounded-circle position-absolute"
                    style={{ fontSize: "70%", bottom: "4px", right: "4px", top: "auto" }}
                  >
                    {
                      getNotificationsForUser(notifications, session)?.filter((n) => !n.readAt)
                        .length
                    }
                  </span>
                </div>
              )}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top bg-primary">
                <Tab.Container defaultActiveKey={"notifications"}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setTabPaneKey(_key)}
                  >
                    <UserNotificationsDropdownTabHeaderItem
                      data-cy="in-app-notifications-tab-notifications"
                      tabPaneKeyActive={tabPaneKey}
                      tabKey={"notifications"}
                      titleLabelId={"IN_APP_NOTIFICATION.HEADER.NOTIFICATIONS"}
                      unreadNotificationsCount={getUnreadNotificationsCountForTab(
                        notifications,
                        "notifications"
                      )}
                    />
                    <UserNotificationsDropdownTabHeaderItem
                      data-cy="in-app-notifications-tab-tasks"
                      tabPaneKeyActive={tabPaneKey}
                      tabKey={"tasks"}
                      titleLabelId={"TASKS.TITLE"}
                      unreadNotificationsCount={getUnreadNotificationsCountForTab(
                        notifications,
                        "tasks"
                      )}
                    />
                    <UserNotificationsDropdownTabHeaderItem
                      data-cy="in-app-notifications-tab-requests"
                      tabPaneKeyActive={tabPaneKey}
                      tabKey={"requests"}
                      titleLabelId={"IN_APP_NOTIFICATION.HEADER.REQUEST"}
                      unreadNotificationsCount={getUnreadNotificationsCountForTab(
                        notifications,
                        "requests"
                      )}
                    />
                  </Nav>
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="navi navi-hover scroll "
                    style={{
                      background: "#fff",
                      maxHeight: "300px",
                      position: "relative",
                    }}
                  >
                    <UserNotificationsDropdownTabPane
                      tabKey={tabPaneKey}
                      notifications={getNotificationsForTab(notifications, tabPaneKey)}
                      intl={intl}
                    />
                  </PerfectScrollbar>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
