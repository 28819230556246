import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { Field, Form, Formik } from "formik";
import { difference } from "lodash-es";
import * as Yup from "yup";
import cn from "clsx";

import { Input, Select } from "_metronic/_partials/controls";
import { PhoneFormInput } from "_metronic/_partials/controls/forms/PhoneFormInput";
import { toAbsoluteUrl } from "_metronic/_helpers";

import { canCreate, canDelete, canEdit } from "app/_utils/authUtils";
import { CLIENT, LEGAL, SUBCONTRACTOR, USER } from "app/_utils/userTypes";
import { LEGAL_ENTITY_TYPES, TITLES } from "app/_utils/listUtils";
import { AVAILABLE_LANGUAGES } from "app/_utils/languages";

import { EditAvatarImage } from "app/_components/AvatarImage";

import { MultiSelect } from "app/_components/MultiSelect";
import { AddressEditForm } from "app/_components/AddressEditForm";

import { checkEmailValidity } from "app/modules/UsersManagement/_redux/usersCrud";
import { updateUserRights } from "app/modules/UsersManagement/_redux/usersActions";
import * as actions from "app/modules/UsersManagement/_redux/usersActions";

import { useProfileUIContext } from "app/modules/UsersManagement/pages/profile/ProfileUIContext";

export const UserEditForm = ({
  user,
  submitUser,
  saveUserFields = () => undefined,
  originalUser,
  disabled,
  hideUserAvatar = false,
  isEmailAlreadyExists,
  onSubmitRef,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const profileUIContext = useProfileUIContext();
  const itsMe = profileUIContext?.pathId?.toLowerCase() === "me";

  const UserEditSchema = Yup.object().shape({
    legalEntityType: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    titles: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    firstName: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    lastName: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    email: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL.REQUIRED" }))
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    mobile: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.PHONE.REQUIRED" }))
      .min(6, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 6`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    referrer: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    company: Yup.object().shape({
      name: Yup.string()
        .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
        .max(50, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 50`),
    }),
  });

  const [availableUserTypes, setAvailableUserTypes] = useState();
  const [activeUserTypes, setActiveUserTypes] = useState();

  const { groups, session } = useSelector(({ auth }) => ({
    groups: auth.groups,
    session: auth.session,
  }));

  useEffect(() => {
    if (groups && session) {
      const res = [];
      if (canCreate(groups, session, "USER")) {
        res.push({ value: USER, label: intl.formatMessage({ id: "USER.TITLE.SINGLE" }) });
      }
      if (canCreate(groups, session, "CLIENT")) {
        res.push({ value: CLIENT, label: intl.formatMessage({ id: "CLIENT.TITLE.SINGLE" }) });
      }
      if (canCreate(groups, session, "SUBCONTRACTOR")) {
        res.push({
          value: SUBCONTRACTOR,
          label: intl.formatMessage({ id: "SUBCONTRACTOR.TITLE.SINGLE" }),
        });
      }
      setAvailableUserTypes(res);
      setActiveUserTypes(
        user.userTypes
          ?.map((userType) => {
            let isFixed;
            if (user.userTypes.length === 1) {
              isFixed = true;
            } else {
              switch (userType) {
                case USER:
                  isFixed = !canDelete(groups, session, "USER");
                  break;
                case CLIENT:
                  isFixed = !canDelete(groups, session, "CLIENT");
                  break;
                case SUBCONTRACTOR:
                  isFixed = !canDelete(groups, session, "SUBCONTRACTOR");
                  break;
                default:
                  break;
              }
            }
            return {
              value: userType,
              label: intl.formatMessage({ id: `${userType}.TITLE.SINGLE` }),
              isFixed,
            };
          })
          .sort((u1, u2) => (u1.isFixed ? -1 : u2.isFixed ? 1 : 0))
      );
    }
  }, [groups, session, user.userTypes]);

  const formRef = useRef();
  useEffect(() => {
    if (onSubmitRef) {
      onSubmitRef(() => formRef.current.submitForm());
    }
  }, []);

  const customHandleChange = (e) => (handleChange) => {
    handleChange(e);
    saveUserFields(e.target.name, e.target.value);

    if (e.target.name === "userTypes") {
      if (!e.target.value.includes(CLIENT)) {
        saveUserFields("referrer", "");
        saveUserFields("legalEntityType", "NATURAL");
      }
      if (!e.target.value.includes(SUBCONTRACTOR) && !e.target.value.includes(CLIENT)) {
        saveUserFields("address", {});
        saveUserFields("company", {});
      }
    }

    if (e.target.name === "legalEntityType") {
      if (e.target.value !== "LEGAL" && !user.userTypes.includes(SUBCONTRACTOR)) {
        saveUserFields("company", {});
      }
    }
  };

  const saveAvatarImage = useCallback(
    (fileName, image, onAfterSave) => {
      if (!originalUser) return;
      const avatar = {
        image,
        fileName,
        userId: originalUser.id,
      };

      actions
        .setUserAvatar(avatar)(dispatch)
        .then((response) => {
          onAfterSave();

          return actions.markUserPictureSetup(user, response.key)(dispatch);
        })
        .then(() => {
          if (!itsMe) return;

          actions.fetchUserMe()(dispatch);
        })
        .catch(console.error);
    },
    [itsMe, originalUser]
  );

  const onChangeUserTypes = (values, handleChange) => {
    const newUserTypes = values.map((value) => value.value);
    if (difference(user.userTypes, newUserTypes)) {
      if (!newUserTypes.includes(USER)) {
        saveUserFields("rights", []);
        dispatch(updateUserRights({ ...user, rights: [] }));
      } else {
        dispatch(updateUserRights({ ...user }));
      }
    }
    customHandleChange({
      target: { name: "userTypes", value: newUserTypes },
    })(handleChange);
  };

  const [editEmail, setEditEmail] = useState();
  const [checkingEmail, setCheckingEmail] = useState();
  const checkEmail = async (values, handleChange, setFieldError) => {
    if (values.email !== user.email) {
      setCheckingEmail(true);
      const response = await checkEmailValidity(user.id, values.email);
      setCheckingEmail(false);
      if (response?.code !== "USER_ALREADY_EXISTS") {
        customHandleChange({ target: { name: "email", value: values.email } })(handleChange);
        setEditEmail(false);
      } else {
        setFieldError(
          "email",
          intl.formatMessage({
            id: "USER.CREATE.ERROR.EMAIL.EXISTS",
          })
        );
      }
    }
  };

  const [editMobile, setEditMobile] = useState();

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={user}
      validationSchema={UserEditSchema}
      onSubmit={() => {
        if (!disabled && !!submitUser) {
          submitUser();
        }
      }}
    >
      {({ values, handleChange, errors, setFieldValue, setFieldError }) => (
        <>
          <Form className="form form-label-right" data-cy="form-user">
            {!hideUserAvatar && (
              <EditAvatarImage
                containerClassName="form-group d-flex flex-column align-items-center"
                isLoading={!originalUser}
                isEditable={!disabled}
                imageAltText="profile avatar"
                editTooltipMessage={<FormattedMessage id="PROFILE.AVATAR.CHANGE" />}
                label={intl.formatMessage({
                  id: "PROFILE.AVATAR",
                })}
                placeholderStyleBackgroundImage="url('/media/users/blank.png')"
                url={originalUser?.profilePictureUrl}
                onSaveImage={saveAvatarImage}
              />
            )}

            <div className="form-group form-row">
              {user.userTypes.includes(CLIENT) && (
                <div className="col-sm-3">
                  <Select
                    name="legalEntityType"
                    data-cy="select-client-legalEntityType"
                    label={intl.formatMessage({
                      id: "USER.INFO.LEGAL.ENTITY.TYPE",
                    })}
                    onChange={(e) => customHandleChange(e)(handleChange)}
                    disabled={disabled}
                  >
                    {LEGAL_ENTITY_TYPES.map((clientType) => (
                      <option key={clientType.value} value={clientType.value}>
                        {intl.formatMessage({ id: clientType.label })}
                      </option>
                    ))}
                  </Select>
                </div>
              )}
              <div className="col-sm-2">
                <Select
                  name="title"
                  data-cy="select-user-title"
                  label={intl.formatMessage({
                    id: "USER.INFO.TITLE",
                  })}
                  onChange={(e) => customHandleChange(e)(handleChange)}
                  customFeedbackLabel={" "}
                  disabled={disabled}
                >
                  {TITLES.map((userTitle) => {
                    const titleTranslated = intl.formatMessage({ id: userTitle.label });
                    return (
                      <option key={userTitle.value} value={userTitle.value}>
                        {titleTranslated}
                      </option>
                    );
                  })}
                </Select>
              </div>
              <div className="col-sm">
                <Field
                  name="firstName"
                  data-cy="input-user-firstname"
                  component={Input}
                  onChange={(e) => customHandleChange(e)(handleChange)}
                  customFeedbackLabel={" "}
                  label={intl.formatMessage({
                    id: "USER.INFO.NAME.FIRST",
                  })}
                  disabled={disabled}
                />
              </div>
              <div className="col-sm">
                <Field
                  name="lastName"
                  data-cy="input-user-lastname"
                  component={Input}
                  onChange={(e) => customHandleChange(e)(handleChange)}
                  customFeedbackLabel={" "}
                  label={intl.formatMessage({
                    id: "USER.INFO.NAME.LAST",
                  })}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form-group form-row">
              <div className="col-sm-6">
                <label>
                  <FormattedMessage id="USER.INFO.EMAIL" />
                </label>
                <div className={"d-flex"}>
                  <Field
                    name="email"
                    data-cy="input-user-email"
                    component={Input}
                    onChange={(e) => {
                      if (!originalUser?.id) {
                        customHandleChange(e)(handleChange);
                      } else {
                        handleChange(e);
                      }
                    }}
                    onFocus={() => setEditEmail(true)}
                    onBlur={() => {
                      if (user.email === values.email) {
                        setEditEmail(false);
                      }
                    }}
                    customFeedbackLabel={" "}
                    withFeedbackLabel={false}
                    disabled={disabled}
                  />
                  {originalUser?.id && editEmail && (
                    <>
                      <button
                        className={cn("btn btn-icon btn-light-success ml-2 mr-2 flex-shrink-0")}
                        type={"button"}
                        onClick={(e) => checkEmail(values, handleChange, setFieldError)}
                        disabled={checkingEmail || errors.email}
                        data-cy="button-user-email-save"
                      >
                        {checkingEmail ? (
                          <span
                            className="spinner spinner-success spinner-md"
                            style={{ width: "18px", height: "18px" }}
                          />
                        ) : (
                          <span className="svg-icon svg-icon-md">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                          </span>
                        )}
                      </button>
                      <button
                        type={"button"}
                        className={"btn btn-icon btn-light-danger flex-shrink-0"}
                        onClick={() => {
                          setEditEmail(false);
                          setFieldValue("email", user.email);
                        }}
                        disabled={checkingEmail}
                        data-cy="button-user-email-cancel"
                      >
                        <span className="svg-icon svg-icon-md">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
                        </span>
                      </button>
                    </>
                  )}
                </div>
                {(errors.email || isEmailAlreadyExists) && (
                  <div className="text-danger invalid-feedback d-block">
                    {isEmailAlreadyExists
                      ? intl.formatMessage({
                          id: "USER.CREATE.ERROR.EMAIL.EXISTS",
                        })
                      : errors.email}
                  </div>
                )}
              </div>
              <div className="col-sm-6">
                <label>
                  <FormattedMessage id="USER.INFO.MOBILE" />
                </label>
                <div className="d-flex">
                  <Field
                    name="mobile"
                    component={PhoneFormInput}
                    disabled={disabled}
                    onChange={(e) => {
                      if (!originalUser?.id) {
                        customHandleChange(e)(handleChange);
                      } else {
                        handleChange(e);
                      }
                    }}
                    onFocus={() => setEditMobile(true)}
                    onBlur={() => {
                      if (user.mobile === values.mobile) {
                        setEditMobile(false);
                      }
                    }}
                    withFeedbackLabel={false}
                  />
                  {originalUser?.id && editMobile && (
                    <>
                      <button
                        className={cn("btn btn-icon btn-light-success ml-2 mr-2 flex-shrink-0")}
                        type={"button"}
                        disabled={errors.mobile}
                        onClick={() => {
                          customHandleChange({ target: { name: "mobile", value: values.mobile } })(
                            handleChange
                          );
                          setEditMobile(false);
                        }}
                        data-cy="button-user-mobile-save"
                      >
                        <span className="svg-icon svg-icon-md">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                        </span>
                      </button>
                      <button
                        type={"button"}
                        className={"btn btn-icon btn-light-danger flex-shrink-0"}
                        onClick={() => {
                          setEditMobile(false);
                          setFieldValue("mobile", user.mobile);
                        }}
                        data-cy="button-user-mobile-cancel"
                      >
                        <span className="svg-icon svg-icon-md">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
                        </span>
                      </button>
                    </>
                  )}
                </div>
                <div className="text-danger invalid-feedback d-block">{errors.mobile}</div>
              </div>
            </div>
            <div className="form-group form-row">
              <div className="col-sm-6">
                <Select
                  name="language"
                  data-cy="select-user-language"
                  label={intl.formatMessage({
                    id: "USER.LANGUAGE.CONTACT",
                  })}
                  onChange={(e) => customHandleChange(e)(handleChange)}
                  customFeedbackLabel={" "}
                  disabled={disabled}
                >
                  {AVAILABLE_LANGUAGES.map((language) => (
                    <option key={language.lang} value={language.lang}>
                      {language.name}
                    </option>
                  ))}
                </Select>
              </div>

              {!disabled && availableUserTypes?.length > 0 && user.id && (
                <div className="col-sm-6">
                  <label>
                    <FormattedMessage id={"USER.INFO.USER_TYPES"} />
                  </label>
                  <MultiSelect
                    data-cy="select-multi-user-types"
                    options={availableUserTypes}
                    value={activeUserTypes}
                    onChange={(values) => onChangeUserTypes(values, handleChange)}
                  />
                </div>
              )}
            </div>
            {user?.userTypes && user.userTypes.includes(CLIENT) && (
              <div className="form-group form-row">
                <div className="col-sm-6">
                  <Field
                    data-cy="input-client-referrer"
                    name="referrer"
                    component={Input}
                    onChange={(e) => customHandleChange(e)(handleChange)}
                    label={intl.formatMessage({
                      id: "REFERRER",
                    })}
                    disabled={
                      !(user.userTypes.includes(CLIENT) && canEdit(groups, session, "CLIENT"))
                    }
                  />
                </div>
              </div>
            )}
            {((user?.userTypes && user.userTypes.includes(SUBCONTRACTOR)) ||
              user.legalEntityType.includes(LEGAL)) && (
              <>
                <h3 className={"mt-14"}>
                  <FormattedMessage id="COMPANY.TITLE.INFORMATION" />
                </h3>
                <hr />

                <div className="form-group form-row">
                  <div className="col-sm-6">
                    <Field
                      name="company.name"
                      component={Input}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      label={intl.formatMessage({
                        id: "COMPANY.NAME",
                      })}
                      disabled={
                        !(
                          (user.userTypes.includes(SUBCONTRACTOR) &&
                            canEdit(groups, session, "SUBCONTRACTOR")) ||
                          user.legalEntityType.includes(LEGAL)
                        )
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="company.vatNumber"
                      component={Input}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      label={intl.formatMessage({
                        id: "COMPANY.VAT.NUMBER",
                      })}
                      disabled={
                        !(
                          (user.userTypes.includes(SUBCONTRACTOR) &&
                            canEdit(groups, session, "SUBCONTRACTOR")) ||
                          user.legalEntityType.includes(LEGAL)
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-group form-row">
                  <div className="col-sm-6">
                    <Field
                      name="company.registrationNumber"
                      component={Input}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      label={intl.formatMessage({
                        id: "COMPANY.REGISTRATION.NUMBER",
                      })}
                      disabled={
                        !(
                          (user.userTypes.includes(SUBCONTRACTOR) &&
                            canEdit(groups, session, "SUBCONTRACTOR")) ||
                          user.legalEntityType.includes(LEGAL)
                        )
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="company.nationalRegistrationNumber"
                      component={Input}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      label={intl.formatMessage({
                        id: "COMPANY.NATIONAL.REGISTRATION.NUMBER",
                      })}
                      disabled={
                        !(
                          (user.userTypes.includes(SUBCONTRACTOR) &&
                            canEdit(groups, session, "SUBCONTRACTOR")) ||
                          user.legalEntityType.includes(LEGAL)
                        )
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {user?.userTypes &&
              (user.userTypes.includes(CLIENT) || user.userTypes.includes(SUBCONTRACTOR)) && (
                <>
                  <h5 className={"mt-14"}>
                    <FormattedMessage id="ADDRESS.TITLE" />
                  </h5>
                  <hr />
                  <AddressEditForm
                    handleChange={handleChange}
                    customHandleChange={customHandleChange}
                    disabled={
                      !(
                        (user.userTypes.includes(CLIENT) && canEdit(groups, session, "CLIENT")) ||
                        (user.userTypes.includes(SUBCONTRACTOR) &&
                          canEdit(groups, session, "SUBCONTRACTOR"))
                      )
                    }
                  />
                </>
              )}
          </Form>
        </>
      )}
    </Formik>
  );
};
