import React, { useMemo } from "react";
import { useLeadFilesUIContext } from "./LeadFilesUIContext";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../../../../redux/hooks";
import { Filter } from "../../../../../_components/Filter";
import { isAdmin } from "../../../../../_utils/authUtils";

export function LeadFilesFilter() {
  const leadFilesUIContext = useLeadFilesUIContext();
  const leadFilesUIProps = useMemo(() => {
    return {
      setQueryParams: leadFilesUIContext.setQueryParams,
      openNewFileDialog: leadFilesUIContext.openNewFileDialog,
      openNewInvoiceDialog: leadFilesUIContext.openNewInvoiceDialog,
      queryParams: leadFilesUIContext.queryParams,
      readOnly: leadFilesUIContext.readOnly,
      setIsInvoice: leadFilesUIContext.setIsInvoice,
    };
  }, [leadFilesUIContext]);

  const { session, groups, lead } = useAppSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
    lead: state.leads.leadForEdit.saved,
  }));

  return (
    <>
      <div className="form-filtration d-sm-flex">
        <div className={"margin-bottom-10-mobile flex-grow-1"} style={{ maxWidth: "100%" }}>
          <Filter useEntityUIContext={useLeadFilesUIContext} />
        </div>
        <div className="text-right margin-bottom-10-mobile d-flex align-items-start">
          {!leadFilesUIProps.readOnly && (
            <div className="d-flex justify-content-end align-items-center flex-grow-1 flex-sm-grow-0 flex-shrink-0">
              {(isAdmin(groups, session) || session?.userTypes?.includes("USER")) && (
                <button
                  type="button"
                  className="btn btn-light ml-md-2 flex-grow-1"
                  onClick={() => {
                    leadFilesUIProps.setIsInvoice(true);
                    leadFilesUIProps.openNewFileDialog();
                  }}
                  disabled={!lead}
                >
                  <FormattedMessage id="INVOICE.ACTION.CREATE.NEW" />
                </button>
              )}
              <button
                type="button"
                className="btn btn-primary ml-2 flex-grow-1"
                onClick={() => leadFilesUIProps.openNewFileDialog()}
                disabled={!lead}
              >
                <FormattedMessage id="FILE.ACTIONS.UPLOAD.NEW" />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
