import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LeadsPage } from "./leads/LeadsPage";
import { LeadEdit } from "./leads/lead-edit/LeadEdit";
import { ContentRoute, LayoutSplashScreen } from "../../../../_metronic/layout";
import { ProductEdit } from "./products/product-edit/ProductEdit";
import { ProductsPage } from "./products/ProductsPage";
import { ProjectEdit } from "./projects/project-edit/ProjectEdit";
import { ProjectsPage } from "./projects/ProjectsPage";
import { WizardPage } from "./forms/WizardPage";
import { TasksPage } from "./tasks/TasksPage";
import { UserEdit } from "./users/users-edit/UserEdit";
import { BudgetEdit } from "../../Budget/BudgetEdit";
import { CLIENT, SUBCONTRACTOR, USER } from "../../../_utils/userTypes";
import { UsersPage } from "./users/UsersPage";

export default function PropertiesManagementPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute
          path="/leads/new/products"
          render={(props) => <ProductsPage {...props} status={`LEAD_CREATION`} />}
        />
        <ContentRoute
          path="/leads/new/clients"
          render={(props) => <UsersPage {...props} action={`LEAD_CREATION`} userType={CLIENT} />}
        />
        <ContentRoute path="/leads/new" component={LeadEdit} />
        <ContentRoute path="/leads/:id" component={LeadEdit} />
        <ContentRoute path="/leads" component={LeadsPage} />

        <ContentRoute
          path="/clients/new"
          render={(props) => <UserEdit {...props} userType={CLIENT} />}
        />
        <ContentRoute
          path="/clients/:id"
          render={(props) => <UserEdit {...props} userType={CLIENT} />}
        />
        <ContentRoute
          path="/clients"
          render={(props) => <UsersPage {...props} userType={CLIENT} />}
        />

        <ContentRoute
          path="/users/new"
          render={(props) => <UserEdit {...props} userType={USER} />}
        />
        <ContentRoute
          path="/users/:id"
          exact
          render={(props) => <UserEdit {...props} userType={USER} />}
        />
        <ContentRoute path="/users" render={(props) => <UsersPage {...props} userType={USER} />} />

        <ContentRoute
          path="/subcontractors/new"
          render={(props) => <UserEdit {...props} userType={SUBCONTRACTOR} />}
        />
        <ContentRoute
          path="/subcontractors/:id/action/contact/clients"
          render={(props) => (
            <>
              {/* this code is needed to avoid issue when returning into the subcontractors page */}
              <UsersPage {...props} action={`MULTI_TASK_CREATION`} userType={CLIENT} />
            </>
          )}
        />
        <ContentRoute
          path="/subcontractors/:id"
          exact
          render={(props) => <UserEdit {...props} userType={SUBCONTRACTOR} />}
        />
        <ContentRoute
          path="/subcontractors"
          render={(props) => <UsersPage {...props} userType={SUBCONTRACTOR} />}
        />

        <ContentRoute path="/products/new" component={ProductEdit} />
        <ContentRoute path="/products/:id" component={ProductEdit} />
        <ContentRoute path="/products" component={ProductsPage} />

        <ContentRoute path="/projects/new" component={ProjectEdit} />
        <ContentRoute path="/projects/:id" component={ProjectEdit} />
        <ContentRoute path="/projects" component={ProjectsPage} />

        <ContentRoute path="/forms/:id" component={WizardPage} />

        <ContentRoute path="/tasks" component={TasksPage} />

        <ContentRoute path="/budgets/new" component={BudgetEdit} />
        <ContentRoute path="/budgets/:id" component={BudgetEdit} />
        <Redirect to={"/error/404"} />
      </Switch>
    </Suspense>
  );
}
