import React, { useEffect, useMemo, useState } from "react";
import { useWizard, Wizard } from "react-use-wizard";
import { shallowEqual, useDispatch } from "react-redux";
import { FormEdit } from "../form-edit/FormEdit";
import { AuthRequestKycAmlFilloutAcceptTerms } from "./AuthRequestKycAmlFilloutAcceptTerms";
import { useFormsUIContext } from "../FormsUIProvider";
import { useAppSelector } from "../../../../../../redux/hooks";
import { translateBreadcrumbs } from "../../../../../_utils/routeUtils";
import { useSubheader } from "../../../../../../_metronic/layout";
import { useIntl } from "react-intl";
import { CardLoaderSpinner } from "../../../../../_components/CardLoaderSpinner";
import { PdfViewerPageSignature } from "../../../../Sign/pages/PdfViewerPageSignature";
import { fetchSettings } from "../../../../Settings/_redux/settingsActions";
import { downloadPdfFile } from "../../../../UsersManagement/_redux/usersCrud";

export function KycAmlFilloutWizard() {
  const dispatch = useDispatch();

  const [fileBase64, setFileBase64] = useState(undefined);

  const { action, session, companyName, companyType } = useAppSelector(
    (state) => ({
      action: state.forms.action?.current,
      session: state.auth.session,
      user: state.users.entityForEdit?.current,
      userFile: state.users.entityForEdit.current?.files?.find((f) => f.id === action?.userFileId),
      companyName: state.settings.settingsForEdit?.saved?.companyName,
      companyType: state.settings.settingsForEdit?.saved?.companyType,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!fileBase64 && (action?.status === "DOCUMENT_GENERATED" || action?.status === "DONE")) {
      downloadPdfFile(session?.id, action?.userFileId).then((res) => {
        setFileBase64(res);
      });
    }
  }, [action]);

  const kycAmlUiContext = useFormsUIContext();
  const formsUiProps = useMemo(() => {
    return {
      setSignature: kycAmlUiContext?.setSignature,
      file: kycAmlUiContext?.file,
      setFile: kycAmlUiContext?.setFile,
      companyName: kycAmlUiContext?.companyName,
      setCompanyName: kycAmlUiContext?.setCompanyName,
    };
  }, [kycAmlUiContext]);

  const { setCompanyName } = formsUiProps;

  useEffect(() => {
    fetchSettings(dispatch);
  }, []);

  useEffect(() => {
    if (companyName) {
      setCompanyName(`${[companyName, companyType]?.join(" ")}`);
    }
  }, [companyName]);

  const StepFilloutForm = () => {
    const { nextStep } = useWizard();
    return <FormEdit nextStep={nextStep} />;
  };

  const StepAcceptTerms = ({ breadcrumbs }) => {
    const { previousStep, nextStep } = useWizard();
    return (
      <AuthRequestKycAmlFilloutAcceptTerms
        previousStep={previousStep}
        nextStep={nextStep}
        actionsLoading={undefined}
        breadcrumbs={breadcrumbs}
      />
    );
  };
  const StepSignDocument = ({ breadcrumbs }) => {
    const intl = useIntl();
    const subheader = useSubheader();
    useEffect(() => {
      subheader.setBreadcrumbs(translateBreadcrumbs(intl, breadcrumbs));
    }, [action]);

    return fileBase64 ? (
      <PdfViewerPageSignature
        fileUrl={fileBase64}
        flow={action?.content?.flow}
        signature={
          action?.content?.signatureRequests?.find((s) => s.userId === session.id)?.signatureId
            ? {
                id: action?.content?.signatureRequests?.find((s) => s.userId === session.id)
                  ?.signatureId,
              }
            : {
                signedDate: action?.content?.metadata?.createdAt,
                signedBy: action?.content?.firstName + " " + action?.content?.lastName,
              }
        }
        setFileBase64={setFileBase64}
      />
    ) : (
      <CardLoaderSpinner />
    );
  };

  return (
    <Wizard
      startIndex={action?.status === "DOCUMENT_GENERATED" || action?.status === "DONE" ? 2 : 0}
    >
      <StepFilloutForm />
      <StepAcceptTerms breadcrumbs={["KYC.TITLE", "KYC.TERMS.TITLE"]} />
      <StepSignDocument breadcrumbs={["KYC.TITLE", "KYC.TERMS.TITLE", "SIGN.TITLE"]} />
    </Wizard>
  );
}
