import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../../../UsersManagement/_redux/usersActions";
import { UserFileEditDialogHeader } from "./UserFileEditDialogHeader";
import { UserFileEditForm } from "./UserFileEditForm";
import { useUserFilesUIContext } from "../UserFilesUIContext";
import { useAppSelector } from "../../../../../../../redux/hooks";

export function UserFileEditDialog() {
  const userFilesUIContext = useUserFilesUIContext();
  const userFilesUIProps = useMemo(() => {
    return {
      id: userFilesUIContext.selectedId,
      setIds: userFilesUIContext.setIds,
      userId: userFilesUIContext.userId,
      queryParams: userFilesUIContext.queryParams,
      showEditFileDialog: userFilesUIContext.showEditFileDialog,
      closeEditFileDialog: userFilesUIContext.closeEditFileDialog,
      isUploadingFile: userFilesUIContext.isUploadingFile,
      initFile: userFilesUIContext.initFile,
      readOnly: userFilesUIContext.readOnly,
      userTypes: userFilesUIContext.userTypes,
    };
  }, [userFilesUIContext]);

  const dispatch = useDispatch();
  const { actionsLoading, userFileForEdit } = useAppSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      userFileForEdit: state.users.entityForEdit?.current?.files?.find(
        (file) => file.id === userFilesUIProps.id
      ),
    }),
    shallowEqual
  );

  const saveFile = async (userFile) => {
    // if id of file does NOT EXIST,
    //  - in that case:
    //          1. check if we want to upload a new file-item (including the file itself) -> isUploading=true
    //          2. if isUploading=false, only the file-item (db entry) is created, the file itself is not available yet
    //             -> this describes the "request file" functionality, where a user requires a document from a third party
    //             (e.g. a user that buys a house and needs to provide documents like, for example, a copy of an identity card)
    //
    if (!userFilesUIProps.id) {
      if (userFilesUIProps.isUploadingFile) {
        // create new file-item (including binary upload to cloud-storage)
        dispatch(actions.uploadUserFile(userFile)).then(() => {
          userFilesUIProps.setIds([]);
          userFilesUIProps.closeEditFileDialog();
        });
      } else {
        // create a file-request-item -> a new file-item gets created in db, but the binary is not uploaded to cloud-storage (e.g. binary not available yet)
        dispatch(actions.createRequestUserFile(userFile)).then(() => {
          userFilesUIProps.setIds([]);
          userFilesUIProps.closeEditFileDialog();
        });
      }
    } else {
      // update file-item properties (e.g. name / document-type/category like ID or PAYSLIP)
      // note: changes only on file-item in db, not on the binary-file itself
      if (!userFilesUIProps.isUploadingFile) {
        dispatch(actions.updateUserFile(userFile, userFilesUIProps.userTypes[0])).then(() => {
          userFilesUIProps.setIds([]);
          userFilesUIProps.closeEditFileDialog();
        });
      } else {
        //case where user uploads a binary-file (pdf, jpg/png,...) to an existing file-request-item
        dispatch(actions.uploadRequestedUserFile(userFile)).then(() => {
          userFilesUIProps.setIds([]);
          userFilesUIProps.closeEditFileDialog();
        });
      }
    }
  };

  const saveMultipleFiles = async (...userFiles) => {
    if (userFiles.length > 0) {
      for (const userFile of userFiles) {
        await saveFile(userFile);
      }
    }
  };

  return (
    <Modal
      show={userFilesUIProps.showEditFileDialog}
      onHide={userFilesUIProps.closeEditFileDialog}
      aria-labelledby="example-modal-sizes-title-lg"
      centered
      scrollable
      backdrop="static"
      size={"xl"}
    >
      <UserFileEditDialogHeader
        userFileForEdit={userFileForEdit}
        isUploadingFile={userFilesUIProps.isUploadingFile}
        showEditFileDialog={userFilesUIProps.showEditFileDialog}
      />
      <UserFileEditForm
        saveFile={saveFile}
        saveMultipleFiles={saveMultipleFiles}
        actionsLoading={actionsLoading}
        file={userFileForEdit || userFilesUIProps.id || userFilesUIProps.initFile}
        onHide={userFilesUIProps.closeEditFileDialog}
        isUploadingFile={userFilesUIProps.isUploadingFile}
        readOnly={userFilesUIProps.readOnly && userFilesUIProps.id}
        userTypes={userFilesUIProps.userTypes}
      />
    </Modal>
  );
}
