import * as React from "react";
import cn from "clsx";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";

export enum MessageType {
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger",
  QUESTION = "question",
  DONE = "done",
}

interface FormattedMessageProps {
  id: string;
  params?: any;
}

interface InformationalMessageProps {
  message: FormattedMessageProps;
  messageType: MessageType;
}

export const InformationalMessage: React.FunctionComponent<InformationalMessageProps> = ({
  message,
  messageType,
}) => {
  const config: { [key: string]: { filename: string; color: string } } = {
    info: { filename: "Info-circle.svg", color: "svg-icon-info" },
    warning: { filename: "Warning-2.svg", color: "svg-icon-warning" },
    danger: { filename: "Error-circle.svg", color: "svg-icon-danger" },
    question: { filename: "Question-circle.svg", color: "svg-icon-primary" },
    done: { filename: "Done-circle.svg", color: "svg-icon-success" },
  };

  return (
    <>
      <span className={cn("svg-icon svg-icon-xl mr-2", config[messageType].color)}>
        <SVG src={toAbsoluteUrl(`/media/svg/icons/Code/${config[messageType].filename}`)} />
      </span>
      <span>
        <FormattedMessage id={message.id} values={{ ...message?.params }} />
      </span>
    </>
  );
};
