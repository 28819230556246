import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FormikHandlers,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { Input } from "../../../../_metronic/_partials/controls";
import { ReactSortable } from "react-sortablejs";
import { NumberFormatValues } from "react-number-format";
import { CustomCard, CustomCardLine } from "app/modules/Common/CustomCard";
import { NumberInput } from "app/_utils/formUtils";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "app/_utils/suffixUtils";
import { accurateFloatOperation } from "../../../_utils/mathUtils";
import { v4 as uuid } from "uuid";
import { IBudget, TBudgetInstalments } from "../../../../data/schemas";
import { cloneDeep } from "lodash-es";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

interface BudgetInstalmentTableProps {
  shareOfLandPrice: number;
  architectEngineeringFeesPrice: number;
  expectedConstructionPrice: number;
  disabled: boolean;
  saveFields: (key: string | string[], value: any) => void;
  totalInstalments: number;
}

export const BudgetInstalmentTable: React.FC<BudgetInstalmentTableProps> = ({
  architectEngineeringFeesPrice,
  shareOfLandPrice,
  expectedConstructionPrice,
  disabled,
  saveFields,
  totalInstalments,
}) => {
  const { handleChange, values, setFieldValue } = useFormikContext<IBudget>();

  const addInstalment = (
    e: React.MouseEvent<HTMLButtonElement>,
    budgetInstalmentsHelpers: FieldArrayRenderProps
  ) => {
    e.preventDefault();
    budgetInstalmentsHelpers.push({
      id: uuid(),
      label: "",
      instalment: 0,
    });
  };

  const moveInstalment = (
    setFieldValue: FormikHelpers<IBudget>["setFieldValue"],
    sortedInstalments: TBudgetInstalments
  ) => {
    if (sortedInstalments.length) {
      setFieldValue("budgetInstalments", sortedInstalments);
      const res = sortedInstalments.map(({ chosen, selected, ...keepAttrs }) => keepAttrs);
      saveFields("budgetInstalments", res);
    }
  };

  const removeInstalment = (
    e: React.MouseEvent<HTMLButtonElement>,
    budgetInstalmentsHelpers: FieldArrayRenderProps,
    instalmentIndex: number
  ) => {
    e.preventDefault();
    budgetInstalmentsHelpers.remove(instalmentIndex);
    const res = cloneDeep(values.budgetInstalments);
    res.splice(instalmentIndex, 1);
    saveFields("budgetInstalments", res);
  };

  const handleInstalmentNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    instalmentIndex: number,
    handleChange: FormikHandlers["handleChange"]
  ) => {
    if (!!saveFields) {
      const res = cloneDeep(values.budgetInstalments);
      res[instalmentIndex].label = e.target.value;
      saveFields("budgetInstalments", res);
      handleChange(e);
    }
  };

  const handleInstalmentValueChange = (value: NumberFormatValues, instalmentIndex: number) => {
    if (!!saveFields) {
      const res = cloneDeep(values.budgetInstalments);
      res[instalmentIndex].instalment =
        accurateFloatOperation((value.floatValue ?? 0) / 100, 4) || 0;
      saveFields("budgetInstalments", res);
      setFieldValue("budgetInstalments", res);
    }
  };

  return (
    <CustomCard
      header={
        <div className="form-row flex-grow-1 mr-12">
          <div className="col-8 pl-4" />
          <div className="col-2 text-right">
            <NumberInput
              displayType="text"
              value={accurateFloatOperation(totalInstalments * 100, 2)}
              suffix={SUFFIX_PERCENTAGE}
              className={
                "font-weight-bold " +
                (totalInstalments < 1
                  ? "text-warning"
                  : totalInstalments > 1
                  ? "text-danger"
                  : "text-success")
              }
            />

            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
                    <FormattedMessage id="COMMON.PERCENTAGE.CONSTRUCT.PRICE" />
                  </Tooltip>
                }
              >
                <span className="svg-icon svg-icon-md svg-icon-info ml-2">
                  <SVG
                    className={"pb-1"}
                    src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                  />
                </span>
              </OverlayTrigger>
            </>
          </div>
          <div className="col-2 text-right">
            <NumberInput
              displayType="text"
              value={
                accurateFloatOperation(expectedConstructionPrice * totalInstalments, 2) +
                architectEngineeringFeesPrice +
                shareOfLandPrice
              }
              suffix={SUFFIX_EURO_CURRENCY}
              className="font-weight-bold"
            />
          </div>
        </div>
      }
    >
      <div className="d-flex">
        <div className="w-15px" />
        <div
          className="form-row flex-grow-1 px-4 pt-2 font-size-xs font-weight-bold line-height-sm"
          style={{ color: "#6a6a6a" }}
        >
          <div className="col-8">
            <FormattedMessage id="COMMON.DESCRIPTION" />
          </div>
          <div className="col-2">
            <FormattedMessage id="BUDGET.INSTALMENT.SINGLE" />
          </div>
          <div className="col-2">
            <FormattedMessage id="BUDGET.AMOUNT_PREVIEW" />
          </div>
        </div>
        <div className="w-30px" />
      </div>
      <FieldArray
        name="budgetInstalments"
        validateOnChange={false}
        render={(budgetInstalmentsHelpers) => (
          <>
            {[
              {
                description: "COMMON.EXPECTED.TOTAL.SHARE_OF_LAND",
                value: accurateFloatOperation(shareOfLandPrice, 2),
              },
              {
                description: "COMMON.EXPECTED.TOTAL.ARCHITECT_ENGINEERING",
                value: accurateFloatOperation(architectEngineeringFeesPrice, 2),
              },
            ].map((data, index) => (
              <CustomCardLine key={index}>
                <div className="form-row flex-grow-1">
                  <div className="col-8 d-flex align-items-center">
                    <div className="flex-grow-1">
                      <FormattedMessage id={data.description} />
                    </div>
                  </div>
                  <div className="col-2 d-flex" />
                  <div className="col-2 d-flex align-items-center justify-content-end">
                    <NumberInput
                      displayType="text"
                      value={data.value}
                      suffix={SUFFIX_EURO_CURRENCY}
                      className="text-right"
                    />
                  </div>
                </div>
              </CustomCardLine>
            ))}
            <ReactSortable
              list={values.budgetInstalments || []}
              setList={(sortedInstalments) => moveInstalment(setFieldValue, sortedInstalments)}
              swapThreshold={0.65}
              animation={150}
              handle=".line-handle"
              scroll={true}
              bubbleScroll={true}
            >
              {values.budgetInstalments?.map((instalment, instalmentIndex) => (
                <CustomCardLine
                  key={instalment.id}
                  draggable={!disabled}
                  remove={
                    !disabled
                      ? (e: React.MouseEvent<HTMLButtonElement>) =>
                          removeInstalment(e, budgetInstalmentsHelpers, instalmentIndex)
                      : undefined
                  }
                  removable={values.budgetInstalments.length !== 1 && !disabled}
                >
                  <div className="form-row flex-grow-1">
                    <div className="col-8 d-flex align-items-center">
                      <div className="flex-grow-1">
                        <Field name={`budgetInstalments.${instalmentIndex}.id`} type="hidden" />

                        <Field
                          name={`budgetInstalments.${instalmentIndex}.label`}
                          className="form-control-sm"
                          component={Input}
                          withFeedbackLabel={false}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInstalmentNameChange(e, instalmentIndex, handleChange)
                          }
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="col-2 d-flex">
                      <NumberInput
                        className="form-control form-control-sm text-right"
                        suffix={SUFFIX_PERCENTAGE}
                        value={accurateFloatOperation(instalment.instalment * 100, 2)}
                        onValueChange={(e: NumberFormatValues) =>
                          handleInstalmentValueChange(e, instalmentIndex)
                        }
                        disabled={disabled}
                        decimalScale={2}
                      />
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-end">
                      <NumberInput
                        displayType="text"
                        value={accurateFloatOperation(
                          expectedConstructionPrice * instalment.instalment,
                          2
                        )}
                        suffix={SUFFIX_EURO_CURRENCY}
                        className="text-right"
                      />
                    </div>
                  </div>
                </CustomCardLine>
              ))}
            </ReactSortable>
            {!disabled && (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center justify-content-center"
                  onClick={(e) => addInstalment(e, budgetInstalmentsHelpers)}
                >
                  <i className="ki ki-plus icon-nm" />
                  <FormattedMessage id="BUDGET.INSTALMENT.ADD" />
                </button>
              </div>
            )}
          </>
        )}
      />
    </CustomCard>
  );
};
