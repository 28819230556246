// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import * as React from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual } from "react-redux";
import * as actions from "./_redux/budgetsActions";
import * as projectActions from "../PropertiesManagement/_redux/projects/projectsActions";
import * as uiHelpers from "./BudgetsUIHelpers";
import { NoRecordsFoundMessage, sortCaret } from "../../../_metronic/_helpers";
import {
  ActionsColumnFormatter,
  SelectedColumnFormatter,
  TotalColumnFormatter,
} from "../../_components/column-formatters";
import { Pagination } from "../../../_metronic/_partials/controls";
import { useBudgetsUIContext } from "./BudgetsUIContext";
import { canCreate, canDelete, canEdit, canSeeHiddenLine, isAdmin } from "../../_utils/authUtils";
import { useIntl } from "react-intl";
import { cloneDeep, isEmpty } from "lodash-es";
import { v4 as uuid } from "uuid";
import { useEntityDeleteDialogContext } from "../PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";
import { searchUtils, selectField } from "../../_utils/searchUtils";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { IBudget, SubcontractorFinanceType } from "../../../data/schemas";

export function BudgetsTable() {
  const intl = useIntl();
  const budgetsUIContext = useBudgetsUIContext();

  const budgetsUIProps = React.useMemo(() => {
    return {
      ids: budgetsUIContext.ids,
      setIds: budgetsUIContext.setIds,
      queryParams: budgetsUIContext.queryParams,
      filters: budgetsUIContext.filters,
      setFilters: budgetsUIContext.setFilters,
      openBudgetPage: budgetsUIContext.openBudgetPage,
      readOnly: budgetsUIContext.readOnly,
      filterList: budgetsUIContext.filterList,
    };
  }, [budgetsUIContext]);

  const { entities, listLoading, groups, session, projectForEdit } = useAppSelector(
    (state) => ({
      entities: budgetsUIProps.queryParams?.projectId
        ? state.projects?.projectForEdit?.current?.budgets
        : state.budgets.entities,
      listLoading: state.budgets.listLoading,
      groups: state.auth.groups,
      session: state.auth.session,
      projectForEdit: state.projects?.projectForEdit?.current,
    }),
    shallowEqual
  );
  const dispatch = useAppDispatch();

  const setSelectedBudget = (id: string) => {
    dispatch(projectActions.updateProjectFieldLocally("selectedBudget", id));
  };

  const copyBudget = (id: string) => {
    dispatch(actions.fetchBudget(id)).then((budget: IBudget) => {
      const { id, createdAt, updatedAt, ...newBudget } = cloneDeep(budget);
      newBudget.name = `${newBudget.name} - ${intl.formatMessage({
        id: "COMMON.COPY",
      })}`;
      newBudget.budgetInstalments = newBudget.budgetInstalments.map(
        ({ budgetId, createdAt, updatedAt, ...rest }) => ({ ...rest, id: uuid() })
      );
      newBudget.sortedLines = newBudget.sortedLines.map(
        ({ budgetId, createdAt, updatedAt, lines, ...rest }) => ({
          ...rest,
          id: uuid(),
          lines: lines.map(({ budgetId, categoryId, createdAt, updatedAt, ...rest }) => ({
            ...rest,
            id: uuid(),
          })),
        })
      );
      dispatch(actions.createBudget(newBudget)).then((budget) => {
        dispatch(projectActions.addBudget(budget));
      });
    });
  };

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const openDeleteBudgetDialog = (id: string) => {
    setDeleteEntityDialog({
      action: { fn: actions.deleteBudget, props: { queryParams: budgetsUIProps.queryParams, id } },
      entityType: "BUDGET",
    });
  };

  // Table columns
  const columns: ColumnDescription<IBudget>[] = [
    {
      dataField: "updatedAt",
      sort: true,
      hidden: true,
      text: "",
    },
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap w-50",
      classes: "text-left",
      sortCaret: sortCaret,
    },
    {
      dataField: "plannedTotal",
      text: intl.formatMessage({
        id: "COMMON.TOTAL",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortCaret: sortCaret,
      formatter: TotalColumnFormatter,
    },
    {
      sort: true,
      sortCaret: sortCaret,
      dataField: "status",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.STATUS",
      }),
      formatter: SelectedColumnFormatter,
      formatExtraData: {
        intl: intl,
        selectedBudget: projectForEdit?.selectedBudget,
      },
      sortValue: (cell, row) => projectForEdit?.selectedBudget === row.id,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          if ((a && b) || (!a && !b)) {
            return rowA.name > rowB.name ? 1 : -1;
          }
          return b - a;
        }
        if ((a && b) || (!a && !b)) {
          return rowB.name > rowA.name ? 1 : -1;
        }
        return a - b;
      },
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "COMMON.ACTIONS",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openDeleteDialog: openDeleteBudgetDialog,
        openEditDialog: setSelectedBudget,
        editDialog: projectForEdit?.selectedBudget,
        copy: copyBudget,
        isAdmin: isAdmin(groups, session),
        actionList: ["STAR", "DUPLICATE", "TRASH"],
        canSelectBudget: () =>
          isAdmin(groups, session) &&
          Object.values(SubcontractorFinanceType).every((sffType) =>
            isEmpty(projectForEdit?.subcontractorsFinanceFiles?.[sffType]?.data)
          ),
        canDelete: (row: IBudget) => {
          if (row.hiddenLineExist) {
            return canDelete(groups, session, "BUDGET") && canSeeHiddenLine(groups, session);
          }
          return canDelete(groups, session, "BUDGET");
        },
        canDuplicate: () => {
          return canCreate(groups, session, "BUDGET") && canEdit(groups, session, "BUDGET");
        },
        entityType: "BUDGET",
      },
      classes: "text-right",
      headerClasses: "text-right",
      hidden: budgetsUIProps.readOnly,
    },
  ];

  const [entitiesFiltered, setEntitiesFiltered] = React.useState<IBudget[]>([]);
  React.useEffect(() => {
    const entitiesClone = cloneDeep(entities);
    entitiesClone?.forEach((entity) => {
      entity.budgetStatus = projectForEdit?.selectedBudget;
    });
    setEntitiesFiltered(
      searchUtils(
        budgetsUIProps?.filters?.freeText,
        entitiesClone,
        selectField(budgetsUIProps),
        intl
      )
    );
  }, [
    entities,
    budgetsUIProps?.filters?.freeText,
    projectForEdit?.selectedBudget,
    budgetsUIProps?.filters?.filterSelected,
  ]);

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: entitiesFiltered.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: budgetsUIProps.filters.pageSize,
    page: budgetsUIProps.filters.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                {...paginationTableProps}
                id="budgets-table"
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                bordered={false}
                keyField="id"
                data={entitiesFiltered || []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                noDataIndication={() => <NoRecordsFoundMessage entities={entitiesFiltered} />}
                hover
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    budgetsUIProps?.openBudgetPage?.(row);
                  },
                }}
                rowStyle={{ cursor: "pointer" }}
                rowClasses={"budget-row"}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
