/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { canSeeSettings } from "../../../../app/_utils/authUtils";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { FormattedMessage } from "react-intl";

export function StickyToolbar() {
  const { groups, session } = useSelector((state) => state.auth);
  const {saving} = useSelector((state) => state.global)

  return (
    <>
      <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
        {canSeeSettings(groups, session) && (
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="layout-tooltip"><FormattedMessage id="SETTING.TITLE" /></Tooltip>}
          >
            <li className="nav-item mb-2" data-placement="left" data-cy="link-settings">
              <Link
                to="/settings"
                className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
              >
                <i className="flaticon2-gear" />
              </Link>
            </li>
          </OverlayTrigger>
        )}

        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="layout-tooltip">{saving ? <FormattedMessage id="SAVE.SAVING" /> : <FormattedMessage id="SAVE.SAVED" />}</Tooltip>}
        >

          <li className="nav-item mb-2 w-20px h-20px" data-cy="confirmation-save" data-placement="left">
            {saving ? (
              <div className="spinner spinner-track spinner-info spinner-md h-20px"/>
            ) : (
              <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}/>
              </span>
            )}
          </li>
        </OverlayTrigger>
      </ul>
    </>
  );
}
