import * as React from "react";
import { CustomCard, CustomCardLine } from "../../../../../../Common/CustomCard";
import { FormattedMessage, useIntl } from "react-intl";
import { NumberInput } from "../../../../../../../_utils/formUtils";
import { cloneDeep, sumBy } from "lodash-es";
import { SUFFIX_EURO_CURRENCY, SUFFIX_PERCENTAGE } from "../../../../../../../_utils/suffixUtils";
import { Field, FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { ReactSortable } from "react-sortablejs";
import { Input } from "../../../../../../../../_metronic/_partials/controls";
import CreatableSelect from "react-select/creatable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { v4 as uuid } from "uuid";
import { accurateFloatOperation } from "../../../../../../../_utils/mathUtils";
import {
  IBudget,
  IBudgetInstalment,
  ILead,
  IProject,
  ISettings,
} from "../../../../../../../../data/schemas";
import { isBudgetServiceAvailable } from "../../../../../../../_utils/configUtils";
import { NumberFormatValues } from "react-number-format";
import { OnChangeValue } from "react-select";
import { PartiallyRequired } from "../../../../../../../_utils/typesUtils";
import { ILeadFileInvoiceForm, ILeadInvoiceLineForm } from "./LeadFileInvoiceForm";

type TCustomBudgetInstalment = PartiallyRequired<IBudgetInstalment, "id" | "label">;

interface LeadInvoiceLinesProps {
  lead: ILead;
  settings: ISettings;
  budget?: IBudget;
  project?: IProject;
  currentConstructionPriceWithReducedVat?: number;
  setCurrentConstructionPriceWithReducedVat: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  reducedVatAvailable: boolean;
}

export const LeadInvoiceLines: React.FC<LeadInvoiceLinesProps> = ({
  lead,
  budget,
  project,
  settings,
  currentConstructionPriceWithReducedVat,
  setCurrentConstructionPriceWithReducedVat,
  reducedVatAvailable,
}) => {
  const intl = useIntl();

  const { values, setFieldValue } = useFormikContext<ILeadFileInvoiceForm>();

  const isBudgetAvailable = React.useMemo(() => isBudgetServiceAvailable(), []);
  const [budgetInstalments, setBudgetInstalments] = React.useState<TCustomBudgetInstalment[]>();

  React.useEffect(() => {
    if (isBudgetAvailable && budget && budget.projectId === project?.id) {
      setBudgetInstalments([
        { id: "SHARE_OF_LAND", label: intl.formatMessage({ id: "PRODUCT.INFO.SHARE_OF_LAND" }) },
        {
          id: "ARCHITECT_ENGINEERING_FEES",
          label: intl.formatMessage({ id: "PRODUCT.INFO.ARCHITECT_ENGINEERING_FEES" }),
        },
        ...budget.budgetInstalments.map((instalment) => ({
          ...instalment,
          label: `${instalment.label} - ${accurateFloatOperation(
            instalment.instalment * 100,
            2
          )} %`,
        })),
      ]);
    }
  }, [budget]);

  const changeLine = (
    selected: OnChangeValue<TCustomBudgetInstalment, false>,
    lineIndex: number
  ) => {
    const res = cloneDeep(values.content);
    res[lineIndex].budgetInstalmentId = selected?.id ?? "";
    res[lineIndex].label = selected?.label ?? "";
    res[lineIndex].vat = (settings.defaultVat ?? 0) * 100;
    if (selected?.id === "SHARE_OF_LAND") {
      res[lineIndex].amount = lead.shareOfLandSellingPrice;
      res[lineIndex].vat = 0;
    } else if (selected?.id === "ARCHITECT_ENGINEERING_FEES") {
      res[lineIndex].amount = lead.architectEngineeringFees;
    } else {
      if (shouldUseReducedVat(values.content, lineIndex, res[lineIndex])) {
        res[lineIndex].vat = (settings.reducedVat ?? 0) * 100;
      }
      res[lineIndex].amount = accurateFloatOperation(
        (selected?.instalment ?? 0) *
          (lead.sellingPrice - (lead.shareOfLandSellingPrice + lead.architectEngineeringFees)),
        2
      );
    }
    setFieldValue("content", res);
  };

  const moveLine = (sortedLines: ILeadInvoiceLineForm[]) => {
    if (sortedLines.length) {
      setFieldValue("content", sortedLines);
      handleLineChange(sortedLines);
    }
  };

  const removeLine = (linesArrayHelpers: FieldArrayRenderProps, lineIndex: number) => {
    linesArrayHelpers.remove(lineIndex);
    values.content.splice(lineIndex, 1);
    handleLineChange(values.content);
  };

  const handleLineValueChange = <T extends keyof ILeadInvoiceLineForm>(
    field: T,
    value: ILeadInvoiceLineForm[T],
    lineIndex: number
  ) => {
    const content = cloneDeep(values.content);
    content[lineIndex][field] = value;
    setFieldValue("content", content);
    handleLineChange(content);
  };

  const handleLineChange = (lines: ILeadInvoiceLineForm[] = []) => {
    if (
      lead.constructionPriceWithReducedVat &&
      lead.usedConstructionPriceWithReducedVat! < lead.constructionPriceWithReducedVat &&
      reducedVatAvailable
    ) {
      let price = 0;
      const res = cloneDeep(lines);
      const reducedVat = settings.reducedVat ?? 0;
      const defaultVat = settings.defaultVat ?? 0;
      for (const [index, line] of res.entries()) {
        delete line.hasMixVat;
        if (
          lineIsInstalment(line) &&
          (line.vat === reducedVat * 100 || line.vat === defaultVat * 100)
        ) {
          line.vat = reducedVat * 100;
          price += line.amount;
          if (
            price + lead.usedConstructionPriceWithReducedVat! >
            lead.constructionPriceWithReducedVat
          ) {
            line.hasMixVat = true;
            for (let i = index + 1; i < res.length; i++) {
              const nextLine = res[i];
              if (lineIsInstalment(nextLine) && nextLine.vat === reducedVat * 100) {
                delete nextLine.hasMixVat;
                nextLine.vat = defaultVat * 100;
              }
            }
            setFieldValue("content", res);
            break;
          }
          setFieldValue("content", res);
        }
      }
      setCurrentConstructionPriceWithReducedVat(price + lead.usedConstructionPriceWithReducedVat!);
    }
  };

  const lineIsInstalment = (line: ILeadInvoiceLineForm) =>
    line.budgetInstalmentId &&
    !["SHARE_OF_LAND", "ARCHITECT_ENGINEERING_FEES"].includes(line.budgetInstalmentId);

  const shouldUseReducedVat = (
    lines: ILeadInvoiceLineForm[],
    lineIndex: number,
    line: ILeadInvoiceLineForm
  ) =>
    lineIsInstalment(line) &&
    reducedVatAvailable &&
    (currentConstructionPriceWithReducedVat ?? 0) < lead.constructionPriceWithReducedVat!;

  return (
    <CustomCard
      header={
        <div className="form-row flex-grow-1 mr-12">
          <div className="col-8 d-flex">
            <h4>
              <FormattedMessage id="INVOICE.TITLE.LINES" />
            </h4>
          </div>
          <div className="col-1" />
          <div className="col-3 text-right d-flex align-items-center justify-content-end">
            <FormattedMessage id="INVOICE.TOTAL" />:
            <NumberInput
              value={sumBy(values.content, "amount")}
              suffix={SUFFIX_EURO_CURRENCY}
              decimalScale={2}
              displayType="text"
              className={"font-weight-bold ml-2"}
            />
          </div>
        </div>
      }
    >
      <div className="d-flex">
        <div className="w-15px" />
        <div
          className="form-row flex-grow-1 px-4 pt-2 font-size-xs font-weight-bold line-height-sm"
          style={{ color: "#6a6a6a" }}
        >
          <div className="col-5 col-sm-8">
            <FormattedMessage id="COMMON.DESCRIPTION" />
          </div>
          <div className="col-2 col-sm-1">
            <FormattedMessage id="COMMON.VAT" />
          </div>
          <div className="col-5 col-sm-3">
            <FormattedMessage id="COMMON.AMOUNT" />
          </div>
        </div>
        <div className="w-30px ml-4" />
      </div>
      <FieldArray
        name="content"
        render={(linesArrayHelpers) => (
          <>
            <ReactSortable
              list={values?.content ?? []}
              setList={(sortedLines) => moveLine(sortedLines)}
              swapThreshold={0.65}
              animation={150}
              fallbackOnBody={true}
              handle=".line-handle"
              scroll={true}
              bubbleScroll={true}
            >
              {values.content?.map((line, lineIndex) => (
                <CustomCardLine
                  removable={values.content.length !== 1}
                  draggable={true}
                  remove={() => removeLine(linesArrayHelpers, lineIndex)}
                  key={lineIndex}
                >
                  <div className="form-row flex-grow-1">
                    <div className="col-5 col-sm-8 d-flex align-items-center">
                      <div className="flex-grow-1">
                        {!isBudgetAvailable ? (
                          <Field
                            className="form-control form-control-sm"
                            name="line.label"
                            component={Input}
                            value={line.label}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleLineValueChange("label", e.target.value, lineIndex)
                            }
                          />
                        ) : (
                          <CreatableSelect
                            name={`content.${lineIndex}.budgetInstalmentId`}
                            isSearchable
                            isClearable
                            onChange={(selected) => changeLine(selected, lineIndex)}
                            options={budgetInstalments}
                            value={
                              line.label
                                ? {
                                    id: line.budgetInstalmentId,
                                    label: line.label,
                                  }
                                : null
                            }
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.label}
                            placeholder={intl.formatMessage({
                              id: "INVOICE.SELECT.LINE",
                            })}
                            classNamePrefix="creatable-select-sm"
                            menuPosition="fixed"
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                width: "100%",
                              }),
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-2 col-sm-1 d-flex">
                      <NumberInput
                        name="vat"
                        className="form-control form-control-sm text-right"
                        suffix={SUFFIX_PERCENTAGE}
                        decimalScale={0}
                        value={line.vat}
                        onValueChange={(e: NumberFormatValues) =>
                          handleLineValueChange("vat", e.floatValue ?? 0, lineIndex)
                        }
                      />
                    </div>
                    <div className="col-5 col-sm-3 d-flex align-items-center">
                      <NumberInput
                        suffix={SUFFIX_EURO_CURRENCY}
                        className="form-control form-control-sm text-right"
                        value={line.amount}
                        onValueChange={(e: NumberFormatValues) =>
                          handleLineValueChange("amount", e.floatValue ?? 0, lineIndex)
                        }
                      />
                      {line.hasMixVat && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
                              <FormattedMessage id={"LEAD.LINE_SPLIT_DIFFERENT_VAT"} />
                            </Tooltip>
                          }
                        >
                          <span className="svg-icon svg-icon-lg svg-icon-warning ml-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
                          </span>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </CustomCardLine>
              ))}
            </ReactSortable>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center  justify-content-center"
                onClick={(e) =>
                  linesArrayHelpers.push({
                    id: uuid(),
                    budgetInstalmentId: undefined,
                    label: "",
                    amount: 0,
                    vat: (settings.defaultVat ?? 0) * 100,
                  })
                }
              >
                <i className="ki ki-plus icon-nm" />
                <FormattedMessage id="LEAD.ACTION.ADD.LINE" />
              </button>
            </div>
          </>
        )}
      />
    </CustomCard>
  );
};
