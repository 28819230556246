import * as React from "react";
import { useFormikContext } from "formik";
import { ILeadFileInvoiceForm } from "./LeadFileInvoiceForm";

interface LeadInvoiceFormObserverProps {
  selectedFile: any;
  newFile: boolean;
  actionsLoading: boolean;
  setIsSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}
export const LeadInvoiceFormObserver: React.FC<LeadInvoiceFormObserverProps> = ({
  selectedFile,
  newFile,
  actionsLoading,
  setIsSubmitDisabled,
}) => {
  const { values } = useFormikContext<ILeadFileInvoiceForm>();

  React.useEffect(() => {
    let disabledSubmit = false;
    if (newFile) {
      disabledSubmit = !selectedFile;
      if (values.fillInvoiceInformation) {
        disabledSubmit = !selectedFile && !values.content?.length;
      }
    }
    setIsSubmitDisabled(actionsLoading || disabledSubmit);
  }, [selectedFile, newFile, actionsLoading, values.fillInvoiceInformation, values.content]);

  return null;
};
