/* eslint-disable no-unused-vars */
import { createContext, useContext, useEffect, useState } from "react";
import { initialFilter } from "./LeadFilesUIHelper";
import { useFilesContext } from "../../../../File/FilesProvider";
import { useSelector } from "react-redux";
import { canReadAllDocument } from "../../../../../_utils/authUtils";

const LeadFilesUIContext = createContext();

export function useLeadFilesUIContext() {
  return useContext(LeadFilesUIContext);
}

export function LeadFilesUIProvider({ currentLeadId, children, readOnly }) {
  const filesContext = useFilesContext();

  const [leadId, setLeadId] = useState(currentLeadId);
  const [filters, setFilters] = useState(initialFilter);
  const [isInvoice, setIsInvoice] = useState(false);

  const { groups, session } = useSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
  }));

  const initFile = {
    id: undefined,
    friendlyName: "",
    fileType: "-",
    leadId,
    isConfidential: canReadAllDocument(groups, session, "LEAD"),
  };
  useEffect(() => {
    initFile.leadId = currentLeadId;
    setLeadId(currentLeadId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLeadId]);

  const value = {
    leadId,
    setLeadId,
    filters,
    setFilters,
    initFile,
    readOnly,
    isInvoice,
    setIsInvoice,
    ...filesContext,
  };

  return <LeadFilesUIContext.Provider value={value}>{children}</LeadFilesUIContext.Provider>;
}
