import * as React from "react";
import { NumberInput } from "../_utils/formUtils";
import { sumBy } from "lodash-es";
import { accurateFloatOperation } from "../_utils/mathUtils";
import { FormattedMessage } from "react-intl";
import { IFinancialDetails, TBudgetInstalments } from "../../data/schemas";
import { SUFFIX_EURO_CURRENCY } from "../_utils/suffixUtils";
import { LeadInstalmentTable } from "../modules/Lead/LeadInstalmentTable";
import { BudgetInstalmentTable } from "../modules/Budget/components/BudgetInstalmentTable";

export interface EntityEditInstalmentsProps {
  saveBudgetFields?: (key: string | string[], value: any) => void;
  entities: { financialDetails: IFinancialDetails }[];
  disabled: boolean;
  componentTable: typeof LeadInstalmentTable | typeof BudgetInstalmentTable;
  budgetInstalments: TBudgetInstalments;
}
export const EntityEditInstalments: React.FC<EntityEditInstalmentsProps> = ({
  saveBudgetFields,
  entities,
  disabled,
  componentTable,
  budgetInstalments,
}) => {
  const [totalInstalments, setTotalInstalments] = React.useState(0);
  const [expectedConstructionPrice, setExpectedSellingPrice] = React.useState(0);
  const [shareOfLandPrice, setShareOfLandPrice] = React.useState(0);
  const [architectEngineeringFeesPrice, setArchitectEngineeringFeesPrice] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(0);

  React.useEffect(() => {
    if (entities) {
      setExpectedSellingPrice(
        sumBy(
          entities,
          (entity) =>
            (entity.financialDetails.price ?? 0) -
            (entity.financialDetails.shareOfLand ?? 0) -
            (entity.financialDetails.architectEngineeringFees ?? 0)
        )
      );
      setTotalPrice(sumBy(entities, (entity) => entity.financialDetails.price ?? 0));
      setShareOfLandPrice(sumBy(entities, (entity) => entity.financialDetails.shareOfLand ?? 0));
      setArchitectEngineeringFeesPrice(
        sumBy(entities, (entity) => entity.financialDetails.architectEngineeringFees ?? 0)
      );
    }
  }, [entities]);

  React.useEffect(() => {
    setTotalInstalments(accurateFloatOperation(sumBy(budgetInstalments, "instalment"), 4));
  }, [budgetInstalments]);

  return (
    <>
      {!entities ? (
        <div className="d-flex align-items-center justify-content-center">
          <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
        </div>
      ) : (
        <div>
          <div className="row">
            {[
              { label: "COMMON.TOTAL.CONSTRUCTION", value: totalPrice },
              { label: "COMMON.EXPECTED.TOTAL.CONSTRUCTION", value: expectedConstructionPrice },
              { label: "COMMON.EXPECTED.TOTAL.SHARE_OF_LAND", value: shareOfLandPrice },
              {
                label: "COMMON.EXPECTED.TOTAL.ARCHITECT_ENGINEERING",
                value: architectEngineeringFeesPrice,
              },
            ].map((data, index) => (
              <div key={index} className={"col-md-3 card px-0 bg-transparent card-custom"}>
                <div className="card-body d-flex flex-column bg-light m-3">
                  <div className={`text-inverse-light font-weight-bolder font-size-h2`}>
                    <NumberInput
                      value={data.value}
                      displayType="text"
                      suffix={SUFFIX_EURO_CURRENCY}
                      decimalScale={2}
                      className="ml-auto font-weight-bold"
                    />
                  </div>
                  <div className={`text-inverse-light font-weight-bold font-size-lg mt-1`}>
                    <FormattedMessage id={data.label} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ overflowX: "auto" }}>
            <div className={"min-w-650px"}>
              {componentTable({
                architectEngineeringFeesPrice,
                shareOfLandPrice,
                expectedConstructionPrice,
                totalInstalments,
                disabled,
                saveFields: saveBudgetFields ?? (() => {}),
                budgetInstalments,
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
