import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EMAIL, NotificationTypes, SMS } from "../_utils/notificationTypes";
import { IOption } from "../_utils/interfacesUtils";
import { Switch } from "@material-ui/core";

interface NotificationProps {
  notifyMethods: IOption[];
  setNotifyMethods: (data: IOption[]) => void;
}

export const Notification: React.FunctionComponent<NotificationProps> = ({
  setNotifyMethods,
  notifyMethods,
}) => {
  React.useEffect(() => {
    setNotifyMethods([options.EMAIL]);
  }, []);

  React.useEffect(() => {}, [notifyMethods]);

  const intl = useIntl();

  const options = {
    EMAIL: { label: intl.formatMessage({ id: "COMMON.EMAIL" }), value: EMAIL },
    SMS: { label: intl.formatMessage({ id: "COMMON.SMS" }), value: SMS },
  };

  const updateNotifyMethods = (isChecked: any, type: NotificationTypes) => {
    const updatedMethods: IOption[] = [...notifyMethods];
    if (isChecked) {
      updatedMethods.push(options[type]);
    } else {
      updatedMethods.splice(updatedMethods.indexOf(options[type]));
    }
    setNotifyMethods(updatedMethods);
  };

  return (
    <div className="d-flex flex-wrap">
      <div className="mr-auto p-2 text-nowrap">
        <FormattedMessage id="COMMON.ACTION.NOTIFY_USER_BY" />
      </div>
      <div className="d-flex flex-wrap">
        <div className="text-nowrap">
          <Switch
            defaultChecked={true}
            className="text-primary"
            onChange={(e: any) => updateNotifyMethods(e.target.checked, NotificationTypes.EMAIL)}
            data-cy="switch-send-email"
          />
          <FormattedMessage id="COMMON.EMAIL" />
        </div>

        <div className="text-nowrap">
          <Switch
            onChange={(e: any) => updateNotifyMethods(e.target.checked, NotificationTypes.SMS)}
            data-cy="switch-send-sms"
          />
          <FormattedMessage id="COMMON.SMS" />
        </div>
      </div>
    </div>
  );
};
